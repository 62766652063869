
import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Writemessage3 from '../../../Component/Writemessage3';

import Casestudyback from '../../../Component/Casestudyback'
import salesforcedash1 from '../../../asset/images/insight/case_studies/salseforce-dash1.jpg';
import salesforcedash2 from '../../../asset/images/insight/case_studies/salseforce-dash2.png';


export default function SalesForce() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>

      <Helmet>

        <meta charset="UTF-8" />
        <title>Tailored Automation Solutions for Quality Data in Salesforce CRM</title>
        <meta name="description" content="Explore how DPA provided quality data for Salesforce CRM through process automation. Read the comprehensive case study now." />
        <meta name="keywords" content="

artificial intelligence solutions company,
business intelligence analytics,
Corporate Data Providers,
market research company in US,
market research solutions,
" />
        <meta name="author" content="Decimal Point Analytics" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/tailoring-process-automation-to-provide-quality-data-in-salesforce-crm" />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <Box id="case-studies" className='section sec_bg' component='section'>

        <Container maxWidth='lg' className='bdr'>

          <Grid container spacing={3} alignItems="top" justifyContent='center'>
            <Grid item lg={10} md={10} sm={12} xs={12}>
              <Box>
                <Typography variant='h3' className='subhead skblue al_center'>
                  Success Stories
                </Typography>
                <Typography variant='h1' className='mainhead blue al_center mb0'>
                  Tailoring Process Automation Solutions to Provide Modernised Quality Data in Salesforce CRM
                </Typography>

                <Typography className=' mainsmhead black al_center '>
                  A US-based firm with AUM of $41 billion used Decimal Point’s CRM services to get a dashboard view on their fund-raising sources and investors, and empower staff to have meaningful portfolio discussions with clients.
                </Typography>
              </Box>
            </Grid>
          </Grid>


          <Writemessage3 pageVisited="Tailoring Process Automation Solutions to Provide Modernised Quality Data in Salesforce CRM" />



          <Box className='whitebx'>
            <Box>
              {/* <Typography variant='h4' className='blue' >Summary </Typography> */}
              <Typography variant='h6' className='black jst'>
                Many operations have been automated to a level of sophistication using CRM (customer relationship management) tools that significantly improve the efficiency and productivity of an organization. Companies can now leverage the vast data such tools have collected to achieve a high standard of client satisfaction in terms of services and interactions. Especially for data-intense industries such as financial services and asset management, access to granular information is what enables client confidence and empowers decision-making.  </Typography>
              <Typography variant='h6' className='black jst'>
                While Salesforce remains the powerhouse of CRM for its limitless possibilities for process automation and efficiency, it’s the quality of data in Salesforce CRM that provides a solid base for the organization to build an interactive and holistic experience.

              </Typography>
            </Box>
          </Box>

          <Box className='whitebx' mt={5}>
            <Box>
              <Grid container spacing={3} alignItems="top">
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Box className='al_center'>
                    <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + salesforcedash1 + ')', }}>

                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={8} md={8} sm={8} xs={12}>
                  <Typography variant='h6' className='black jst'>
                    A US-based Alternative Asset Management Firm with assets under management of $41 billion, wanted to streamline its client database by creating a tailor-made, process-driven approach using Decimal Point’s cluster of CRM services. Our technical experts identified the key operational and strategic challenges in their existing CRM database to evolve from their basic data management system to create a tailor-made process automation solution. Using our data management expertise to implement a solution, the customer now has an on-hand dashboard view of their fund-raising sources and investors, including region-wise and fund-wise. They also get granular insights on each client’s portfolio performance, and the data empowers them & the staff to have meaningful and confidence-building discussions with clients on their portfolios.  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box className='whitebx' mt={1}  >
            <Grid container spacing={3} alignItems="center">
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Box className='al_center'>
                  <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + salesforcedash2 + ')', }}></Box>
                </Box>
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={12}>
                <Box>

                  {/* <Box mb={2}>
                    <Link to='/analystpulse'>
                      <Typography variant='h4' className='blue' >Know More About Our Product on Analytics – AnalystPulse </Typography>
                    </Link>
                  </Box> */}

                  <Typography variant='h4' className='blue'>
                    Restructuring data and the Salesforce CRM for optimal business impact
                  </Typography>
                  <Typography variant='h6' className='black jst'>
                    By reviewing the existing CRM database and processes, we understood the data lakes, data consolidation, and the current data streams. Among the challenges to be addressed were the following:
                    <ul>
                      <li>Restricting and outdated CRM infrastructure - Using a very basic data management system forced the firm to rely on in-house and outsourced data management due to the lack of in-house data analytics capabilities. The lack of an entirely in-house data management solution naturally raised privacy and data security concerns among clientele.</li>
                      <li>Low data quality - The lack of consistent quality across data fields with different inputs from different employees led to a data collation challenge, making their data unreliable and unusable. Inconsistencies in data, such as incomplete and out-of-date information, misspelled names and duplicate information, resulted in issues with team management and their responsiveness to clientele.</li>

                    </ul>
                  </Typography>

                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box className='whitebx' mt={1}>
            <Box>
              <Typography variant='h4' className='blue' >
                Streamlining the client database</Typography>

              <Typography variant='h6' className='black jst'>
                Using CRM data cleaning, standardization, and data management, we were able to implement a two-step process to guarantee superior data quality and governance to streamline the client database:
                <ol>
                  <li>One-time standardization of the master database – Firstly, our data management experts corrected the existing manual input issues by addressing de-duplication, fixing errors, and updating missing records in the current database. Using CRM solutions, we verified the reliability of data and standardized it accordingly.</li>
                  <li>Continuous maintenance to ensure consistently authentic data quality – Testing code in smaller pieces allowed our experts to improve program codes continually. Besides codes, CRM solutions implemented the latest release functionalities by extensively using automation technology to boost efficiency. A workflow was implemented to automate the entire process, guaranteeing a quality database. </li>

                </ol>
              </Typography>


            </Box>
          </Box>

          <Box className='whitebx' mt={1}>
            <Box>

              <Typography variant='h4' className='blue' > Customizing interactive dashboards to achieve end-customer satisfaction  </Typography>

              <Typography variant='h6' className='black jst'>
                A basic data management system, although adequate in its purpose, lacked the necessary insights to give the asset management firm a deeper understanding of fundraising data - something which is fundamental to making important business decisions in the organization.
              </Typography>

              <Typography variant='h6' className='black jst'>
                Leveraging standardized Salesforce CRM data, we built a customized interactive dashboard to analyze fundraising data. These dashboards give a dynamic view, providing granular insights into investors, regions, quantum, and chronology of fund sources.
              </Typography>
              <Typography variant='h6' className='black jst'>
                The new dashboards allow the firm to filter fund-raising information based on their requirements, such as:
                <ul>
                  <li>Investors dashboard – Closed commitments by lead firm/ strategy/ region.</li>
                  <li>Deal pipeline dashboard – Top targets by lead firm/ fund/ dislocation fund pipeline</li>
                  <li>Team tracking dashboard – Accounts met by region/ team/ RM </li>

                </ul>
              </Typography>
            </Box>
          </Box>





          <Box className='whitebx' mt={1}>
            <Box>
              <Typography variant='h4' className='blue' >
                Facilitating confidence-building conversations with investors by automating information flows</Typography>
              <Typography variant='h6' className='black jst'>When conversing with investors and fund managers, it’s critical to gather the whole picture from various parts of the organization so that they are fully confident in the competency of the asset management firm. Unfortunately, varying inputs from different employees regarding client interactions disturbed information flow in the organization with inconsistencies in data collation; this made it impossible to manage information reliably.</Typography>
              <Typography variant='h6' className='black jst'>
                By integrating apps and third-party tools with add-on services, the Decimal Point team was able to maintain an uninterrupted flow of information throughout the organization:
                <ol>
                  <li>We introduced a standardized data input form for client emails with interaction fields to eliminate discrepancies in data from client employees. </li>
                  <li>These forms are mailed to a specially created email ID that remotely connects to the client's system.</li>
                  <li>To complete the information cycle, we created an additional process to loop these interaction data inputs back into CRM via Excel interface or manual logs.</li>
                </ol>
              </Typography>
            </Box>
          </Box>

          <Box className='whitebx' mt={1}>
            <Box>
              <Typography variant='h4' className='blue' >Empowering staff with the full context, making every meeting meaningful</Typography>
              <Typography variant='h6' className='black jst'>
                Handling multiple investors simultaneously is already a handful for employees. Dealing with meeting schedules and preparing material for different meetings is time-consuming and often overwhelms even the most competent employees.
              </Typography>


              <Typography variant='h6' className='black jst'>
                After analyzing and deliberating over the nature, purpose, and schedule of various employees' meetings, our experts carefully crafted a process that eliminates most of the hassle concerning upcoming meetings:

                <ol>
                  <li>Integrating Salesforce with Outlook gives it access to the employee’s schedule and every client meeting in the calendar.</li>
                  <li>This creates a new interaction in Salesforce, with an update of the next meeting.</li>
                  <li>Relevant data points for the meeting, such as email conversations on the topic from concerned employees, will be tagged automatically via Outlook invite.</li>
                  <li>The app generates automated alerts as a reminder before the meeting and post-meeting.</li>
                  <li>After each meeting, the app automatically updates previous meeting notes and interactions data on Salesforce via Outlook calendar invite.</li>
                  <li>A ready reckoner with information regarding previous interactions is collated and delivered to each concerned employee's inbox to help them prepare for new meetings.</li>
                  <li>Employees get automated meeting alerts and receive the relevant material needed in advance to prepare for the meeting.</li>
                </ol>
              </Typography>

            </Box>
          </Box>

          <Box className='whitebx' mt={1}>
            <Box>
              <Typography variant='h4' className='blue' >Turning reams of data into invaluable business-critical assets</Typography>
              <Typography variant='h6' className='black jst'>
                The broad cluster of Decimal Point’s CRM services combined with the technical expertise of our team enables us to identify deep-rooted data issues in an outdated system. Using our experience from successful transition management and data management cases in the past, we leveraged the client's in-built functionalities to develop customized value-add services and solutions that  streamlined their client database and client interactions.
              </Typography>

              <Typography variant='h6' className='black jst'>Decimal Point’s team of Salesforce Certified Professionals has first-hand experience in handling the integration of external databases with CRM data. With the deployment of Salesforce, your company has harnessed the best automation platform for a cohesive customer experience. Decimal Point Analytics can help you extract the most from Salesforce data and your other applications.
                If you want to know how to make your data work for you, contact us to book your 30-minute complimentary.
              </Typography>

              <Link to='/contact-us' className='link'>
                <Typography variant='h6' className='blue jst'>
                  Consultation Now !
                </Typography>
              </Link>


            </Box>
          </Box>




          {/* <Box className='whitebx' mt={1}>
            <Box>
              <Typography variant='h4' className='blue' >Enable better decisions with Decimal Point Analytics</Typography>
              <Typography variant='h6' className='black jst'>
                In the age of digital transformation, data automation and machine learning are the keys to tomorrow's business. The advent of technology has altered how businesses gather, store, and analyze information. Organizations all across the world are now acknowledging the significance of gaining insights from unstructured data and making data-driven choices. This means that if you want to win over your competition and keep abreast of your business, getting meaningful insights from data is no longer an option, it is a necessity.
              </Typography>
              <Typography variant='h6' className='black jst'>
                We streamlined and automated all the sources for our clients so that they could get the required inputs with just a few clicks. Thus, helping them expand the market and implement cost-efficient routes to scale up processing and analyze crucial data.
              </Typography>
              <Typography variant='h6' className='black jst'>
                If you would also want your complex data in a simplified structure that utilizes an enhanced data-driven decision-making procedure, then we would love to have a word with you.
              </Typography>



            </Box>
          </Box> */}



          <Box className='graybbx' mt={1} >
            <Grid container spacing={1} alignItems="top" >
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Box className='al_left'>
                  <Typography variant='h4' className='black'>
                    Explore More:
                  </Typography>

                  <Link to='/case-studies/delivering-comprehensive-data-solutions-for-a-leading-financial-data-aggregator-and-provider' className='link'>
                    <Typography variant='h6' className='black jst' style={{ marginBottom: '0px' }}>
                      Comprehensive Data Solutions For A Leading Financial Data Aggregator And Provider

                    </Typography>
                  </Link>

                  <Link to='/articles/big-data-and-analytics-the-way-forward-for-asset-managers' className='link'>
                    <Typography variant='h6' className='black jst'>
                      Insights: Way forward for Asset Managers!
                    </Typography>
                  </Link>

                </Box>
              </Grid>
            </Grid>

            {/* <Box mt={5}>
                        <Subscribe />
                    </Box> */}


          </Box>


          <Casestudyback />




        </Container>
      </Box>


    </>
  );
}

