import { IconButton, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Carousel from "react-elastic-carousel";


import Eventback from '../../../Component/Eventback'
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';

import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

import C1 from "../../../asset/images/media/event/5oct_c1.jpg"
import { Helmet } from 'react-helmet';



const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);



export default function Event_05_Oct() {



    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <title>Decimal Point Analytics A Sponsorer In “Techniche 2022”</title>
                <meta name="description" content="Discover Decimal Point Analytics' sponsorship at Techniche 2022, exploring ethical aspects of modern technology and its impact on society." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/techniche-2022" />
            </Helmet>

            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center mb0'>
                                    Decimal point analytics a sponsorer in “Techniche 2022”
                                </Typography>
                                <Typography className=' mainsmhead black al_center mb0'>
                                    Oct, 2022
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>





                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                            DPA was an exclusive Title sponsorer for “Beyond Analysis – Data Analytics” at the event <strong> “Techniche 2022”  </strong> a three-day fest organized by the student community of IIT Guwahati.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            <strong>  Techniche  </strong> is a techno-management fest that majorly focuses on motivating our nation's youth to think outside the box and expand their horizons in the techno-management sphere. The event entertained the participants with panel discussions, workshops, competitions and so on arranged with the industrial leaders.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            As a sponsorship partner, DPA participated in a panel discussion on the topic “Ethical aspects of modern technology: Can we or should we?”.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Technology influences us strong enough to make our lives man less and machine more. The debate was mainly to understand if we need to draw a line on this dependency.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Our senior data scientist Arbind Kumar was one of the panellists with many industrial leaders. He shared his insights on how the current buzzing world of technology impacts us.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Additionally, topics like AI, telecom, office culture, etc came into the discussion which assisted in deriving some useful judgments on where we stand with these advancements.</Typography>



                        <Box className='al_center'>
                            <IconButton className='blue' onClick={handleClickOpen}>
                                <CameraAltOutlinedIcon />
                            </IconButton>
                        </Box>

                        <Box className='al_center'>
                            <Typography className='blink_text'>Click Here...</Typography>
                        </Box>



                        {/* <Subscribe /> */}

                    </Box>




                    <Eventback />




                </Container>
            </Box>




            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="b_modal teammodal">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose}>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Grid container spacing={2}>
                            <Carousel itemsToShow={1} animateOut="slideOutDown"
                                animateIn="flipInX">
                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + C1 + ')', }}></Box>
                                </Box>
                            </Carousel>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>


        </>
    );
}
