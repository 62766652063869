import { Divider, Container, Grid, Typography, Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import terms from '../asset/images/new_banner/terms_banner.jpg'
import { Helmet } from 'react-helmet';


export default function Terms() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);





    return (
        <>

            <Helmet>
                <title>Terms of Service | Decimal Point Analytics</title>
                <meta name="description" content="Learn about Decimal Point Analytics' terms and conditions governing website usage. Access and use the site indicate acceptance of these terms." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/terms" />
            </Helmet>

            {/* <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Terms
                                </Typography>


                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12}>
                            <Box className='al_right'>
                                <img src={indexbanner} className='topbanner' alt='Index Banner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}

            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + terms + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={4} md={5} sm={6} xs={8}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Terms</Typography>
                                    {/* <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">&ensp;</Typography>
                                    </Box> */}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    {/* <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Terms of Use
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid> */}


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box >
                                <Typography variant='h6' className='black jst'>
                                    Please read the following terms and conditions carefully.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    They govern your access and use of this web site <a href='http://decimalpointanalytics.com/' target="_blank" rel="noopener noreferrer"> (www.decimalpointanalytics.com) </a> of <strong>Decimal Point Analytics Private Limited (Decimal Point)</strong> . By accessing or using the Decimal Point web site, you indicate your full acceptance and acknowledgement of these terms and conditions. Decimal Point may modify these terms and conditions from time-to-time as posted on this web site.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    To read Decimal Point's policies on data collection, storage and use, please refer to our <Link to='/policy' target="_blank" rel="noopener noreferrer" > Privacy Policy.</Link>
                                </Typography>

                                <Divider />

                                <Typography variant='h4' className='blue jst'>
                                    Usage of the Site
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    The Decimal Point Site provides general information and details on our services. However, this does not constitute an offer for our services or a contractual obligation to perform a service for a client unless agreed upon mutually in a written contract.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    If you have any queries on the information presented on this web site, please email us at <a href="mailto:info@decimalpointanalytics.com" target="_blank" rel="noopener noreferrer">info@decimalpointanalytics.com</a>
                                </Typography>

                                <Divider />

                                <Typography variant='h4' className='blue jst'>
                                    Restrictions on Use
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    All the information, materials, images, logos, graphics, icons and other elements on the Decimal Point website are owned, licensed and/or copyrighted by Decimal Point unless specified otherwise.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Permission is granted to download materials from this web site for your informational and personal (i.e., non-commercial) use only, and is limited to one copy. Any other reproduction or editing by any means, mechanical or electronic, without the express written permission of Decimal Point is strictly prohibited. You may not refer to the URL of this web site nor link to this web site without the prior written approval of Decimal Point.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    You agree that Decimal Point may, in its sole discretion, at any time terminate your access to the web site and any account(s) you may have in connection with the web site. Decimal Point may also monitor access to the web site.
                                </Typography>

                                <Divider />

                                <Typography variant='h4' className='blue jst'>
                                    Restriction of Liability
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Despite Decimal Point’s efforts to provide accurate information, it is not possible to completely ensure that all information posted on this web site is constantly correct. Decimal Point assumes no responsibility for errors or omissions in the materials on the server. THIS WEB SITE AND ALL INFORMATION, AND MATERIAL CONTAINED HEREIN IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. The materials are subject to change without notice and do not represent a commitment of Decimal Point.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Decimal Point AND ITS OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS WILL NOT BE LIABLE TO YOU OR ANY OTHER THIRD PARTY FOR ANY AND ALL CLAIMS, LIABILITIES, DAMAGES, LOSSES OR EXPENSES, INCLUDING ATTORNEYS’ FEES AND COSTS ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR ACCESS TO OR USE OF THE Decimal Point SITE.
                                </Typography>

                                <Divider />

                                <Typography variant='h4' className='blue jst'>
                                    Linked Sites
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    This web site may contain hyperlinks to other web sites controlled by parties other than Decimal Point. Decimal Point is not responsible for and does not necessarily endorse the accuracy, opinions or contents of these third party web sites.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    The privacy policy and terms of use applicable on these other web sites may be different from those on the Decimal Point Site. If you choose to access a site linked to from the Decimal Point Site, you shall do so at your own risk and Decimal Point has no liability for any loss or damage arising due to your use of these sites.
                                </Typography>

                                <Divider />

                                <Typography variant='h4' className='blue jst'>
                                    Submissions
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Personal Information that may be collected by Decimal Point is shared by you voluntarily via the web site and is governed by the terms detailed in our <Link to='/policy' target="_blank" rel="noopener noreferrer" > Privacy Policy.</Link>
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    You acknowledge that you are solely responsible for the material you submit, and you have full responsibility for the message and its legality.
                                </Typography>

                                <Divider />

                                <Typography variant='h4' className='blue jst'>
                                    Jurisdiction
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    These Terms will be governed and interpreted as per the laws of India. This ‘Terms of Use’ section constitutes the entire agreement between the parties relating to the use of the Decimal Point Site.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Decimal Point is a leading provider of customized investment research, business research, and valuation services to global clients. With a unique blend of deep market knowledge, strong research capabilities and an ability to provide actionable insights that facilitate decision making, we provide cost-effective yet world-class research support.
                                </Typography>



                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>





        </>
    );
}
