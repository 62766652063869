import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from '../../Component/Writemessage3'
import { Helmet } from 'react-helmet';
import analyst1 from "../../asset/images/product/analyst1.svg";
import analyst2 from "../../asset/images/product/analyst2.svg";
import analyst3 from "../../asset/images/product/analyst3.svg";
import analyst4 from "../../asset/images/product/analyst4.svg";
import analyst5 from "../../asset/images/product/analyst5.svg";
import analyst6 from "../../asset/images/product/analyst6.svg";
import analyst7 from "../../asset/images/product/analyst7.svg";
import analyst8 from "../../asset/images/product/analyst8.svg";
import banenr2 from "../../asset/images/animation/analystpulsesolution.svg";

import AnalystPulse from "../../asset/images/new_banner/analyst_pulse_banner.jpg"

export default function Analystpulse() {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Analyst Pulse - Early Signals of Market Expectations</h1>
                <title>Early Signals of Analyst, Investor, and Shareholder Expectations</title>
                <meta name="description" content="Discover early signals of analyst, investor, and shareholder expectations with Analyst Pulse's market intelligence reports - Decimal Point Analytics" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/analystpulse" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                <script type="application/ld+json">
                    {`
       {
        "@context": "https://schema.org/", 
        "@type": "Product", 
        "name": "Analyst Pulse",
        "image": "https://www.decimalpointanalytics.com/static/media/analyspulsbanner.6be6ece24d8dafe12f8b.gif",
        "description": "Market Intelligence reports that discover early signals of analyst, investor and shareholder expectations.",
        "brand": {
          "@type": "Brand",
          "name": "Decimal Point Analytics"
        }
      }        
          `}
                </script>
            </Helmet>


            {/* <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Analyst Pulse
                                </Typography>

                                <Typography variant='h4' className='black'>
                                    Research Solution For Investor Relations Team
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12}>
                            <Box className='al_right'>
                                <img src={indexbanner} className='topbanner' alt='Index Banner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}

            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + AnalystPulse + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={2} md={3} sm={4} xs={8}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Analyst Pulse</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">Research Solution For Investor Relations Team</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>


            <Box className='section2 sec_bg ourstrength' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                {/* <Typography className='black' variant='h2'>
                                    Analyst Pulse
                                </Typography> */}

                                <Box className="al_center synopsic_bx">
                                    <Typography variant='h6' className='black mb0'>Market Intelligence reports that discover early signals of analyst, investor and shareholder expectations.</Typography>
                                </Box>

                            </Box>
                        </Grid>
                    </Grid>

                    <Box className='mob_card' mt={3}>
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='str_bg mnhg3'>
                                    <Box mb={2}>
                                        <img src={analyst1} className='bxicon' alt='Analyst1' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>Addressing Stakeholder Demands</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant='h6' className='black al_left'>
                                                <ul>
                                                    <li>Understand market expectations</li>
                                                    <li>Better Prepared for likely queries</li>
                                                    <li>Address shareholder activism</li>
                                                </ul>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='str_bg mnhg3'>
                                    <Box mb={2}>
                                        <img src={analyst2} className='bxicon' alt='Analyst2' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>Input to Management</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant='h6' className='black al_left'>
                                                <ul>
                                                    <li>Clear concise picture to leadership</li>
                                                    <li>Positions to better deal with regulatory scrutiny</li>
                                                </ul>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='str_bg mnhg3'>
                                    <Box mb={2}>
                                        <img src={analyst3} className='bxicon' alt='Analyst3' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>Unlocking True Value</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant='h6' className='black al_left'>
                                                <ul>
                                                    <li>Maintain smooth relationship with investment community</li>
                                                    <li>Help build a stellar reputation as an investor friendly corporation</li>
                                                </ul>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>

            <Writemessage3 pageVisited="Research Solution For Investor Relations Team" />


            <Box className='section' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography className='black' variant='h2'>
                                    Analyst Pulse Solutions
                                    <Typography variant='h2' className='black h2v2'>
                                        Bridging the Value Gap
                                    </Typography>
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>

                    <Box mt={5}>
                        <Grid container spacing={0} alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <img src={banenr2} className='topbanner2' alt='Banner2' />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>


            <Box className='section sec_bg ourstrength' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography className='black' variant='h2'>
                                    Analyst Pulse Solutions
                                    <Typography variant='h2' className='black h2v2'>
                                        Bringing a Financial Analyst to your Team
                                    </Typography>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className='mob_card' mt={3}>
                        <Grid container spacing={1} alignItems="top">

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='str_bg mnhg3'>
                                    <Box mb={2}>
                                        <img src={analyst4} className='bxicon' alt='Analyst4' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>News Tracker</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant='h6' className='black al_center'>
                                                Weekly news summary regarding the Company and its five closest peers along with News Sentiment Analysis
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='str_bg mnhg3'>
                                    <Box mb={2}>
                                        <img src={analyst5} className='bxicon' alt='Analyst5' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>Consensus Valuation Model</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant='h6' className='black al_center'>
                                                Quarterly update of consensus valuation model and assist the IR team prepare for potential questions from investors on an ongoing basis
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='str_bg mnhg3'>
                                    <Box mb={2}>
                                        <img src={analyst6} className='bxicon' alt='Analyst6' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>Peer Comparisons</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant='h6' className='black al_center'>
                                                Monthly analysis of Companies in the sector on Financial Performance, Credit Profile, Consensus Estimates, Valuation and Shareholders Returns
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Box className='str_bg mnhg2'>
                                    <Box mb={2}>
                                        <img src={analyst7} className='bxicon' alt='Analyst7' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>Street View Monitor</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant='h6' className='black al_center'>
                                                Tracking trends in the broker recommendations on the company and its comparable peers on a fortnightly basis. Analyst outlook summaries
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Box className='str_bg mnhg2'>
                                    <Box mb={2}>
                                        <img src={analyst8} className='bxicon' alt='Analyst8' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>Investor Conference Calendar</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant='h6' className='black al_center'>
                                                Monthly compilation of investor conferences scheduled globally during the coming months
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>


                </Container>
            </Box>




        </>
    );
}
