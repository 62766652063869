
import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Writemessage3 from '../../../../../../Component/Writemessage3';
import EmployeeProfile from '../../../../../../Component/EmployeeProfile';
import AJ from '../../../../../../asset/images/team/ajay_jindal.png'
import ESGBlogsBack from '../../../../../../Component/BackButtons/ESGBlogsBack';
import { Helmet } from 'react-helmet';



export default function Indian_regulators() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>
      <Helmet>
        <title>Regulating ESG Ratings - SEBI's Bold Move Sparks Debate </title>
        <meta name="description" content="Discover insights into SEBI's proposals to regulate ESG ratings in India. Learn more at Decimal Point Analytics." />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/esg-insights/esg-blogs/indian-regulator-wants-to-regulate-esg-ratings" />
      </Helmet>


      <Box className='section sec_bg' component='section'>

        <Container maxWidth='lg' className='bdr'>

          <Grid container spacing={3} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className='al_center' mb={2}>
                <Typography variant='h3' className='subhead skblue al_center'>
                  ESG Insights
                </Typography>
                <Typography variant='h1' className='mainhead blue al_center'>
                  Indian regulator wants to regulate ESG ratings
                </Typography>
              </Box>
            </Grid>
          </Grid>


          <Writemessage3 pageVisited="Indian regulator wants to regulate ESG ratings" />



          <Box className='whitebx' mt={1}  >

            <Box>

              <Typography variant='h4' className='blue'>
                Introduction
              </Typography>

              <Typography variant='h6' className='black jst'>
                The Indian securities regulator’s SEBI wants an ‘enforceable’ regime for ESG Rating Providers or ERPs. If this comes through, SEBI may become the first regulator in the world to impose such detailed guidelines. While some the proposals are positive, regulatory overkill is evident in some other proposals.
              </Typography>

            </Box>


            <Box pt={1} pb={2}>

              <Typography variant='h6' className='black jst'>
                The Indian security market regulator Securities Exchange Board of India (SEBI) is increasing its regulatory focus on Environmental, Social and Governance (ESG) related issues. It released two discussion papers in last month nearly simultaneously: a Consultation Paper on Regulatory Framework for ESG Rating Providers (Paper-1) and another Consultation Paper on ESG Disclosures, Ratings and Investing (Paper-2).
              </Typography>



              <Typography variant='h6' className='black jst'>
                Lets look at some of the key proposals in the Paper-1.
              </Typography>



            </Box>



            {/*-------------------------------------------------------*/}

            <Box pt={1} pb={2}>
              <Typography variant='h6' className='black jst'>
                <strong> Voluntary versus Enforceable:  </strong> While regulators in certain jurisdictions have opted for a voluntary code of conduct for ERPs, SEBI proposes an enforceable regulatory and supervisory framework for ERPs.
              </Typography>


              <Typography variant='h6' className='skblue jst'> <span className='blue'> <strong> Our Comment : </strong> </span>   It is not clear what enforceable means here. One part is clearly the registration; if you are not registered, you cannot dish out ESG ratings once this regulation comes into place. This is fine, but does SEBI plan to dictate further? It appears so looking at some of the other proposals (some mentioned below), which is where one may start having concerns.
              </Typography>
            </Box>




            {/*-------------------------------------------------------*/}


            <Box pt={1} pb={2}>
              <Typography variant='h6' className='black jst'>
                <strong> Do investors need protection?  </strong> SEBI’s motive, it says is, protection of interest of investors in the securities market.
              </Typography>

              <Typography variant='h6' className='skblue jst'> <span className='blue'> <strong> Our Comment : </strong> </span>   ESG ratings are used by institutional investors, they are not really a retail product. Do institutional investors need protection? Unless SEBI means retail investors who invest via institutional investors. Many institutional investors, including in India, have their evaluation frameworks for ESG. They do not rely on third party ratings. Now what protection becomes applicable in this case?
              </Typography>

            </Box>
            {/*-------------------------------------------------------*/}


            <Box pt={1} pb={2}>
              <Typography variant='h6' className='black jst'>
                <strong>  Core ESG Rating versus Overall Rating:  </strong> The Paper-2, which also covers the Business Responsibility and Sustainability Report (BRSR) reporting framework the top 1000 listed Indian companies are supposed to follow, has proposed ‘BRSR Core’ format comprising of select essential indicators across all principles.
              </Typography>

              <Typography variant='h6' className='black jst'> The backstory here is this: whoever designed BRSR earlier, went overboard in the list of indicators they want the companies to report. It seems there is now a realisation at SEBI that companies will not be able to report all the 400 or so indicators the BRSR asks them to. Now taking this issue ahead, Paper-1 says: ‘In context of the new BRSR Core framework, it also proposed that ERPs may also provide a Core ESG Rating, based on assured indicators’. </Typography>


              <Typography variant='h6' className='skblue jst'>  <span className='blue'> <strong> Our Comment : </strong> </span>    We think this is a case of tying oneself in knots. We are looking at two levels of reporting by companies, and two levels of ESG Ratings. We agree there is a need to specify BRSR Core to get to realistic reporting, because the full list is just not going to happen. But whether an ERP wants to have two levels of ESG Rating for the same company, can be left to the ERP. There is no need for the regulator to get involved.
              </Typography>

            </Box>
            {/*-------------------------------------------------------*/}

            <Box pt={1} pb={2}>
              <Typography variant='h6' className='black jst'>
                <strong> And Two more Ratings:  </strong> Why only two ratings per company?, someone at SEBI seems to have asked. In true participative mode, SEBI has advised ERPs to provide 2 more ratings:

                <ul>
                  <li> ESG Transition Rating: measuring the velocity of and investments in making the transition to Net Zero Goals/improving ESG risk management. </li>
                  <li> Combined Score: Combining ESG rating and transition rating, i.e. measuring both the status and the ability to transition  </li>
                </ul>
              </Typography>


              <Typography variant='h6' className='skblue jst'>  <span className='blue'> <strong> Our Comment : </strong> </span>    This is SEBI getting into overkill mode. It seems to be recommending that each ERP should have 4 ratings for one company: Core, Overall, Transition, Combined. Now, we wonder if Combined can be of two types: Core+Transition and Overall+Transition, making it 5 ratings per company. Let the ERPs and their customers decide, SEBI.
              </Typography>
            </Box>
            {/*-------------------------------------------------------*/}

            <Box pt={1} pb={2}>
              <Typography variant='h6' className='black jst'>
                <strong> Detailed Methodology Disclosure:  </strong> In order to provide for greater transparency in the ESG rating process, SEBI is proposing a long list of disclosures, including: Pillar wise E, S and G scores and Weights of E, S and G scores in the assigned ESG rating
              </Typography>


              <Typography variant='h6' className='skblue jst'>  <span className='blue'> <strong> Our Comment : </strong> </span>   We totally welcome this, though we think ERPs may protest having to reveal weights of E, S and G scores.
              </Typography>
            </Box>
            {/*-------------------------------------------------------*/}


            <Box pt={1} pb={2}>
              <Typography variant='h6' className='black jst'>
                <strong> Submission of Business Plan:  </strong> The Paper-2 lists the information it wants an aspiring ERP to submit before it decides whether to grant the ERP a license. This includes some interesting items like:

                <ul>
                  <li>  The applicant has submitted its business plan </li>
                  <li> A target breakeven date, and  </li>
                  <li>   Target revenue and number of clients, within two years of receiving nod to operate </li>
                  <li>  Cumulative cash losses that the applicant projects to incur until the target breakeven date, along with the activities or areas wherein such losses shall be incurre </li>
                </ul>
              </Typography>


              <Typography variant='h6' className='skblue jst'>  <span className='blue'> <strong> Our Comment : </strong> </span>  The logic of this escapes us. Why would the regulator want such detail? We expect ERPs to protest.
              </Typography>


            </Box>




            {/*-------------------------------------------------------*/}
            <Box mt={1}>
              <EmployeeProfile imgSrc={AJ} name={" Mr. Ajay Jindal"} designation={"EVP,"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/ajay-jindal-cfa-33aa961/"} />
            </Box>
            {/*-------------------------------------------------------*/}





          </Box>
          {/*-------------------------------------------------------*/}



          <Box className='graybbx' mt={1} >
            <Grid container spacing={1} alignItems="top" >
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Box className='al_left'>

                  <Typography variant='h6' className='black jst'>
                    <b>Category:</b>  ESG Ratings
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    <b>Tags:</b> Securities Exchange Board of India, SEBI, ESG, ESG Rating Providers, ERPs, Consultation Paper on Regulatory Framework for ESG Rating Providers, Consultation Paper on ESG Disclosures, Ratings and Investing, BRSR Core, Core ESG Rating, Business Responsibility and Sustainability Report, BRSR
                  </Typography>


                </Box>
              </Grid>
            </Grid>
          </Box>



          <ESGBlogsBack />




        </Container>
      </Box>


    </>
  );
}

