import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Writemessage3 from '../../../Component/Writemessage3';

import Casestudyback from '../../../Component/Casestudyback'

import Solu2 from "../../../asset/images/insight/case_studies/offering_solutions2.jpg";
import Solu3 from "../../../asset/images/insight/case_studies/offering_solutions3.jpg";

export default function Offering_solutions() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Solutions for Asset Managers - Streamlining Complex Data</title>
                <meta name="description" content="Discover how DPA transformed complex data for asset managers into predictive analytics. Read the detailed case study now." />
                <meta name="keywords" content="Data Automation company,
Data Cleaning Support,
Data Management company,
Data operations outsourcing,
Data processing company" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/offering-solutions-for-every-asset-managers" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            </Helmet>

            <Box id="case-studies" className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center mb0'>
                                    Offering solutions for every Asset Managers
                                </Typography>

                                <Typography className=' mainsmhead black al_center jst'>
                                    How we streamlined complex data through the automated solution for a US-based fund
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Writemessage3 pageVisited="Offering solutions for every Asset Managers" />


                    <Box className='whitebx'>
                        <Typography variant='h4' className='blue' >Summary </Typography>
                        <Typography variant='h6' className='black jst'>
                            This case study examines the key benefits of transforming complex data from multiple resources to Predictive Analytics for Fund/Asset Managers. It also addresses the potential impact of Financial Data Validation by applying customized solutions specific to the need and pain areas of any Fund Manager
                        </Typography>
                    </Box>



                    <Box className='whitebx' mt={1}>
                        <Grid container spacing={3} alignItems="top">

                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <Box className='al_center'>
                                    <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + Solu2 + ')', }}></Box>
                                </Box>
                            </Grid>

                            <Grid item lg={8} md={8} sm={8} xs={12}>
                                <Box>
                                    <Typography variant='h4' className='blue' >Challenges</Typography>
                                    <Typography variant='h6' className='black jst'>
                                        Over the years Decimal Point Analytics has established itself as one of the leading financial research and data analysis consulting firms by providing comprehensive tools, especially for Asset/Fund Managers. One such opportunity presented itself when a US-based private investment management firm with Asset Management of ~400 billion, approached us with an investment manager’s classic problem related to the apportioning of their due income and expenses from the firm’s finance pool. The client’s system to validate fund managers’ and finance team’s data was a completely excel-based manual process. Moreover, the client’s existing process was not streamlined & clear, and it resulted in the piling up of the last few quarter’s unvalidated data. The firm manages equities, fixed income, private equity, and hedge fund folios for global institutional investors and high net-worth individuals (HNIs), and that had intensified their challenge by multifold due to the pile of unfiltered complex data influx from multiple sources.
                                    </Typography>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>



                    <Box className='whitebx' mt={1}>
                        <Grid container spacing={3} alignItems="top">

                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <Box className='al_center'>
                                    <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + Solu3 + ')', }}></Box>
                                </Box>
                            </Grid>

                            <Grid item lg={8} md={8} sm={8} xs={12}>
                                {/* <Box mb={2}>
                                    <Link to='/analystpulse'>
                                        <Typography variant='h4' className='blue' >Know More About Our Product on Analytics – AnalystPulse </Typography>
                                    </Link>
                                </Box> */}

                                <Box>
                                    <Typography variant='h4' className='blue' >Approach  </Typography>
                                    <Typography variant='h6' className='black jst'>
                                        The team at DPA was certainly excited by the challenge and they approached it with the deep dive strategy in order to understand the key problem areas at the root level first. Our team, which is a strategic and unique mix of professionals with a sound understanding of finance and technology domains, understood the existing processes which were mostly manual, and knew that they had a mammoth task in hand but were also confident about the next steps they needed to take in order to provide the most comprehensive solutions. DPA experts had identified the key deliverables which were based on thorough market research for industry standards combined with advanced and innovative technological solutions. The team was focused on reducing the manual intervention by introducing SOPs that help the client to define and understand the process, for each step in order to reduce the turnaround time for each transaction that took place in an Asset Management environment, manual or otherwise. Fortunately, the client was on the same page of understanding towards the importance of financial data validation. Fortunate because most often than not we underestimate the pivotal role predictive data can play in making informed business decisions which can eventually result in inflated operating costs but can also make you lose the most significant amount of time.
                                    </Typography>
                                </Box>

                            </Grid>
                        </Grid>
                    </Box>



                    <Box className='whitebx' mt={1}>
                        <Box>
                            <Typography variant='h4' className='blue' > The solution </Typography>

                            <Typography variant='h6' className='black jst'>Each project lead at DPA is well equipped and trained for significant man hours in handling not just technical challenges but also the intangible components that may be affecting the entire process hence the approach we take for each project is laser-focused on delivering quality output with measures like maker-checker approach and creating customized SOPs. We at DPA do not believe in one size fits all perspective and every stakeholder involved unanimously ensures that deliverables for every project reflect our commitment to approaching every unique problem with a unique solution. The client was fortunately fully aware of the lagging systems they had in place before the DPA team stepped in, hence the road ahead presented much lesser obstacles in streamlining the existing process and introducing the necessary changes.  </Typography>

                        </Box>
                    </Box>


                    <Box className='whitebx' mt={1}>
                        <Box>
                            <Typography variant='h4' className='blue' >The Testimony </Typography>


                            <Typography variant='h6' className='black jst'> So if you are an Asset/Fund Manager and dealing with an overwhelming and excessive pile of data pouring in from multiple sources then we are here to help you offload that massive burden of data churning. Decimal Point can offer you a one-point solution for all your ubiquitous and unexplored challenges which can then help you focus on the other crucial activities for running your business with higher efficiency. DPA’s unique ability to challenge existing processes & competency is backed by utmost importance to data governance and quality, with prior experience in handling similar projects. Our diverse team of technical and financial experts makes us stand apart from most consulting firms across regions hence we take care of our clients just as much as we are committed to building a great in-house team.
                            </Typography>

                        </Box>
                    </Box>



                    <Box className='graybbx' mt={1} >
                        <Grid container spacing={1} alignItems="top" >
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <Box className='al_left'>
                                    <Typography variant='h4' className='black'>
                                        Explore More:
                                    </Typography>

                                    <Link to='/case-studies/nav-calculations-for-portfolio-management' className='link'>
                                        <Typography variant='h6' className='black jst' style={{ marginBottom: '0px' }}>
                                            <b>Next Case Study: </b>NAV Calculations for Portfolio Management.

                                        </Typography>
                                    </Link>

                                    <Link to='/articles/adopting-changes-in-investment-research-methodologies-roadmap-to-institutional-transformation' className='link'>
                                        <Typography variant='h6' className='black jst'>
                                            <b>Insights: </b>Adopting changes in Investment Research Methodologies - Roadmap to Institutional.
                                            Transformation
                                        </Typography>
                                    </Link>

                                </Box>
                            </Grid>
                        </Grid>

                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}


                    </Box>


                    <Casestudyback />




                </Container>
            </Box>


        </>
    );
}
