import { IconButton, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Carousel from "react-elastic-carousel";


import Eventback from '../../../Component/Eventback'
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';

import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

import C1 from "../../../asset/images/media/event/18nov_c1.jpg"
import C2 from "../../../asset/images/media/event/18nov_c2.jpg"
import { Helmet } from 'react-helmet';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);



export default function Event_18_Nov() {



    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <title>Data Analytics in Finance - Trends and Insights </title>
                <meta name="description" content="Explore the latest trends in data analytics at Decimal Point Analytics' event. Learn about challenges, applications, and key takeaways in finance." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/data-analytics-making-it-work" />
            </Helmet>

            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center mb0'>
                                    Data Analytics: Making it work in the real world
                                </Typography>
                                <Typography className=' mainsmhead black al_center mb0'>
                                    Nov, 2022
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>





                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                            It is now well recognized that traditional active and hedge funds are increasingly valuing the combination of discretionary investment approach with data-based analytics.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Surveys have shown close to, or more than half of, funds/ managers are using data analytics to gauge portfolio risk, investment opportunities, model performance, manager performance, performance analysis, and so on. At the same time, data analytics inputs remain a supporting tool in an industry driven by accomplished managers’ experience and networks.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            At our recent event in New York on Data Science and Analytics, specifically focused on challenges and applications in Finance, Shailesh Dhuri, Chief Executive officer DPA, and Gaurav Gupta, Managing Director, DPA spoke about the key trends in data science and their impact.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Some of the key takeaways from the session were as follows:
                            <ul>
                                <li> Technology and improved connectivity have resulted in a manifold increase in sources of alternate data </li>
                                <li>Advanced analytics and data science are the keys to examine and understand alternative data sets</li>
                                <li> Finance companies are increasingly turning towards technology, especially AI and ML, to leverage this data </li>
                                <li>In the future, quantum computing can potentially transform financial services.</li>
                            </ul>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            With increasing competition, the new-age data science team requires collaboration with technology, people processes, and the right analytics partner. At Decimal Point Analytics, we serve data science teams through a confluence of various capabilities such as data ingestion, machine learning and algorithms, data infrastructure, and visualization/ business intelligence.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The speakers shared some interesting use cases, such as the extraction and ingestion of large volumes of unstructured data for an SEC-registered institutional asset manager and an ML model to analyse offerings from multiple US banks. The speakers also deep-dived into a use case where Decimal Point Analytics created an automated model for processing over 66,000 rent roll receipts, which helped reduce the scanning time for a rent roll by 75%.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The participants also had an opportunity to understand the working of SmartPipe, a 100% automated, machine learning-driven cloud-based platform developed by Decimal Point Analytics. SmartPipe serves as a one-stop shop for data ingestion, data cleaning, and data deduping. The no-code nature of SmartPipe, which enables its self-service usage without the need for technical know-how for implementation, proved to be a real discussion point among the participants.
                        </Typography>


                        <Typography variant='h6' className='black jst'>
                            <a href="https://www.decimalpointanalytics.com/media/uploads/brown-bag-event_nov-3-2022.pdf" target="_blank" rel='noreferrer'> Click here </a> to download the PPT from the event to learn more
                        </Typography>

                        <Box className='al_center'>
                            <IconButton className='blue' onClick={handleClickOpen}>
                                <CameraAltOutlinedIcon />
                            </IconButton>
                        </Box>

                        <Box className='al_center'>
                            <Typography className='blink_text'>Click Here...</Typography>
                        </Box>

                        {/* <Subscribe /> */}

                    </Box>




                    <Eventback />




                </Container>
            </Box>




            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="b_modal teammodal">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose}>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Grid container spacing={2}>
                            <Carousel itemsToShow={1} animateOut="slideOutDown"
                                animateIn="flipInX">
                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + C1 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + C2 + ')', }}></Box>
                                </Box>
                            </Carousel>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>


        </>
    );
}
