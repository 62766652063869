
import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from '../../../../../../Component/Writemessage3';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ESGBlogsBack from '../../../../../../Component/BackButtons/ESGBlogsBack';
import EmployeeProfile from '../../../../../../Component/EmployeeProfile';
import AJ from '../../../../../../asset/images/team/ajay_jindal.png'


export default function ESG_and_its_impact() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>

      <Helmet>

        <meta charset="UTF-8" />
        <title>What is ESG & Its Impact on Investing?</title>
        <meta name="description" content="Learn about ESG investing and its impact on investment decisions. Check out Decimal Point Analytics' blog on ESG for more insights." />
        <meta name="keywords" content="index investing,
esg meaning,
esg meaning in business,
environmental social and governance
" />
        <meta name="author" content="Decimal Point Analytics" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/esg-insights/esg-blogs/what-is-esg-and-its-impact-on-investing" />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>


      <Box className='section sec_bg' component='section'>

        <Container maxWidth='lg' className='bdr'>

          <Grid container spacing={3} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className='al_center' mb={2}>
                <Typography variant='h3' className='subhead skblue al_center'>
                  ESG Insights
                </Typography>
                <Typography variant='h1' className='mainhead blue al_center'>
                  What is ESG & Its Impact on Investing?
                </Typography>
              </Box>
            </Grid>
          </Grid>


          <Writemessage3 pageVisited="What is ESG & Its Impact on Investing?" />



          <Box className='whitebx' mt={1}  >

            <Box>


              <Typography variant='h6' className='black jst'>
                In recent years, investors have been interested in investing their money in alignment with their values. In response, brokerage and mutual fund companies now offer financial products, such as ETFs, that follow ESG investing strategies.
              </Typography>


              <Typography variant='h6' className='black jst'>
                But what exactly is <Link to="/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses"> ESG,</Link> and what impact does it have on investing?
              </Typography>


              <Typography variant='h6' className='black jst'>
                The Financial Times Lexicon defines ESG as "a generic term used in capital markets and used by investors to evaluate corporate behavior and to determine the future financial performance of companies."
              </Typography>

              <Typography variant='h6' className='black jst'>
                Investors use this subset of non-financial performance indicators, such as a company's carbon footprint and reliable accountability systems, for risk assessment and management.
              </Typography>


            </Box>



            {/*-------------------------------------------------------*/}

            <Box pt={1} pb={2}>
              <Typography variant='h4' className='blue jst'>
                What's The Buzz About ESG Investing?
              </Typography>

              <Typography variant='h6' className='black jst'>
                After the Covid-19 pandemic, it is no secret that environmental, social, and economic issues are interrelated. The boom in ESG investing directly results from supply chains becoming more complex, broader awareness of social, labor, and human rights issues, and growing concern for climate change.
              </Typography>

              <Typography variant='h6' className='black jst'>
                In fact, Morningstar, a US financial services company, <a href='https://www.ft.com/content/733ee6ff-446e-4f8b-86b2-19ef42da3824' target="_blank" rel="noopener noreferrer"> reports </a>that over a 10-year period, 80% of blend equity funds prioritizing sustainable investing outperform traditional funds.
              </Typography>

            </Box>




            {/*-------------------------------------------------------*/}


            <Box pt={1} pb={2}>
              <Typography variant='h4' className='blue jst'>
                The Three Pillars of ESG
              </Typography>

              <Typography variant='h6' className='black jst'> Since ESG is incorporated into risk mitigation, compliance, and investment strategies, the robustness of a company's ESG policies has a material impact on the long-term risk and return of investments.
              </Typography>

              <Typography variant='h6' className='black jst'> Responsible investors evaluate companies using ESG criteria as a framework to screen investments or assess risks in investment decision-making.
              </Typography>


              <Typography variant='h6' className='black jst'> Environmental factors assess how well a company manages its impact on the natural world, including waste and pollution, resource depletion, greenhouse gas emissions, deforestation, and climate change.
              </Typography>

              <Typography variant='h6' className='black jst'>
                Social factors determine how a company treats people, including employee relations and diversity, working conditions, the impact on local communities, health and safety, and conflict.
              </Typography>

              <Typography variant='h6' className='black jst'>
                Governance factors evaluate corporate policies and how a company is governed. This includes tax strategy, executive remuneration, donations and political lobbying, corruption, bribery, and board diversity and structure.
              </Typography>



            </Box>
            {/*-------------------------------------------------------*/}


            <Box pt={1} pb={2}>
              <Typography variant='h4' className='blue jst'>
                Subjectivity
              </Typography>

              <Typography variant='h6' className='black jst'> SEC Commissioner Hester Peirce has been on record stating what “good” means in the context of ESG is very subjective, and that is a key reason why ESG ratings firms are so inconsistent in their results. </Typography>


              <Typography variant='h6' className='black jst'>The Stanford authors observe: this huge number of variables creates issues by itself, requiring the ratings firms to make a variety of judgments, including materiality assessments and related “weighting” of factors, potential absence of relevant data and weighting of “both the variables in their importance to E, S, and G, and also the overall pillars of E, S, and G in relation to one another. (source:www.coolpubco.com).</Typography>



            </Box>
            {/*-------------------------------------------------------*/}



            <Box pt={1} pb={2}>
              <Typography variant='h4' className='blue jst'>
                What are some Key Performance Indicators for ESG?
              </Typography>


              <Typography variant='h6' className='black jst'>
                To gauge the effectiveness of ESG investments, Key Performance Indicators (KPIs) are utilized. In financial analysis of corporate performance, several typical factors are used to report ESG issues:
                <ul>
                  <li> Energy efficiency </li>
                  <li> GHG emissions  </li>
                  <li> Staff turnover  </li>
                  <li> Training and qualification  </li>
                  <li> Workforce Maturity  </li>
                  <li> Absenteeism Rate  </li>
                  <li> Litigation risks  </li>
                  <li> Boardroom Diversity  </li>
                  <li> Corruption   </li>
                </ul>
              </Typography>

              <Typography variant='h6' className='black jst'>
                You can use these KPIs to monitor progress and make well-informed investment choices.
              </Typography>
            </Box>
            {/*-------------------------------------------------------*/}

            <Box pt={1} pb={2}>
              <Typography variant='h4' className='blue jst'>
                The Benefits of ESG Investment
              </Typography>

              <Typography variant='h6' className='black jst'> Many believe that ESG criteria have social value and help investors steer clear of potential disasters caused by companies engaging in risky or unethical practices that eventually lead to accountability. For example, the emissions scandal greatly affected the financial standing of Volkswagen, crashing their stock prices with billions of dollars in losses.
              </Typography>

              <Typography variant='h6' className='black jst'> As ESG-minded business practices gain traction, investment firms closely track their performance. For example, JPMorgan Chase (JPM) says, "our Sustainable Development Target to finance and facilitate more than $2.5 trillion over 10 years to help address climate change and contribute to sustainable development, which we set in 2021, is one example of our sustainability strategy in practice."
              </Typography>


              <Typography variant='h6' className='black jst'> The effectiveness of ESG investing lies in its ability to motivate companies to make meaningful improvements for the greater good rather than simply going through the motions and producing reports. This depends on whether the investment practices align with realistic, measurable, and actionable ESG principles.
              </Typography>


            </Box>
            {/*-------------------------------------------------------*/}



            {/*-------------------------------------------------------*/}

            <Box pt={1} pb={2}>
              <Typography variant='h4' className='blue jst'>
                Becoming Future-Ready with ESG Investment Insights
              </Typography>

              <Typography variant='h6' className='black jst'> Whether you're a newcomer to ESG Funds or seeking to realign your existing investments, gaining robust ESG insights is the first step to constructing a secure investment portfolio in today's volatile market. Analyzing a portfolio's ESG alignment can create long-term value for you and your clients.
              </Typography>

              <Typography variant='h6' className='black jst'> <Link to="/"> Decimal Point Analytics</Link> provides top-notch managed services for Portfolio Alignment tracking, TCFD reporting, and SFDR reporting with expertly crafted solutions. Our cutting-edge ML-powered ESG news tracking solution empowers you to monitor all ESG news related to your portfolio companies closely. We also offer ESG scoring frameworks and create insightful company-level reports for our clients. Our data on ESG parameters are sourced directly from company filings, guaranteeing the utmost precision and unwavering reliability.
              </Typography>


              <Typography variant='h6' className='black jst'> Decimal Point Analytics provides exceptional consulting services that ensure a successful ESG journey for new funds. We are the preferred option for private equity firms seeking to implement ESG data collection processes in their portfolios, as we offer detailed due diligence reports on the ESG readiness of their investee companies. Our extensive expertise in ESG makes navigating the complex world of ESG a breeze for our clients.
              </Typography>


            </Box>
            {/*-------------------------------------------------------*/}


            <Box pt={1} pb={2}>
              <Typography variant='h6' className='black jst'> <Link to="/contact-us"> Contact us</Link> today to learn more about how we can help you achieve your ESG goals.  </Typography>
            </Box>



            {/*-------------------------------------------------------*/}
            <Box mt={1}>
              <EmployeeProfile imgSrc={AJ} name={" Mr. Ajay Jindal"} designation={"EVP,"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/ajay-jindal-cfa-33aa961/"} />
            </Box>
            {/*-------------------------------------------------------*/}





          </Box>
          {/*-------------------------------------------------------*/}



          <Box className='graybbx' mt={1} >
            <Grid container spacing={1} alignItems="top" >
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Box className='al_left'>

                  <Typography variant='h6' className='black jst'>
                    <b>Category:</b>  ESG Ratings
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    <b>Tags:</b> Brian Tayan, David Larcker, Edward Watts, Lukasz Pomorski, Rock Center for Corporate Governance at Stanford University,
                  </Typography>

                </Box>
              </Grid>
            </Grid>
          </Box>



          <ESGBlogsBack />




        </Container>
      </Box>


    </>
  );
}

