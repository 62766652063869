import { AppBar, Grid, Toolbar, Typography, IconButton, MenuItem, Menu, Button, Box, Drawer, withStyles, } from '@material-ui/core';
import React from 'react';
import Scroll from './Scroll'
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import dpalogo from "../asset/images/dpa_main_logo.svg";
import smdpalogo from "../asset/images/dpalogo.svg";
import oraclelogo from "../asset/images/oracle_logo.svg";

import CloseIcon from '@mui/icons-material/Close';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import LetsConnectCTA from './forms/LetsConnectCTA';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';


const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);


const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);


const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);





export default function Header() {

  const [anchorE2, setAnchorE2] = React.useState(null);

  const handleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorE2(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';


  const [state, setState] = React.useState({
  });

  const toggleDrawer = (anchor, open) => (event) => {

    if (event.type === 'right' && (event.key === 'right')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  // Insight Dropdown started
  const [anchorEins, setAnchorEins] = React.useState(null);

  const handleClickins = (event) => {
    setAnchorEins(event.currentTarget);
  };

  const handleCloseins = () => {
    setAnchorEins(null);
  };


  // ESG Dropdown started
  const [anchorEESG, setAnchorEESG] = React.useState(null);

  const handleClickESG = (event) => {
    setAnchorEESG(event.currentTarget);
  };

  const handleCloseESG = () => {
    setAnchorEESG(null);
  };

  // Oracle Dropdown started
  const [anchorEOra, setAnchorEOra] = React.useState(null);

  const handleClickOra = (event) => {
    setAnchorEOra(event.currentTarget);
  };

  const handleCloseOra = () => {
    setAnchorEOra(null);
  };


  const [expanded, setExpanded] = React.useState('false');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);

  };

  const list = (anchor) => (

    <Box className='mobilemenuview' sx={{ width: 300 }} role="presentation">


      <Box mb={1} mt={1} mr={2} className='al_right'>
        <IconButton className='Close_btn' variant="contained" onClick={toggleDrawer(anchor, false)}>
          <CloseIcon fontSize='small' color='primary' />
        </IconButton>
      </Box>

      <Box >

        <Accordion expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
          <AccordionSummary component={Link} to='/' aria-controls="panel0d-content" id="panel0d-header" onClick={toggleDrawer(anchor, false)}>
            <Button disableRipple className={`menutt ${isActive(['/']) ? 'active_menu' : ''}`}>Home</Button>
          </AccordionSummary>
        </Accordion>


        <Accordion expanded={expanded === 'panelESG'} onChange={handleChange('panelESG')}>
          <AccordionSummary aria-controls="panelESGd-content" id="panelESGd-header"  >
            {/* <Button endIcon={<ArrowDropDownIcon />} disableRipple className="menutt"> ESG </Button> */}
            <Button endIcon={<ArrowDropDownIcon />} disableRipple className={`menutt ${isActive(['/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses', '/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses#esg-executable-roundtable', '/esg-insights/esg-whitepaper', '/empowering-esg-investment-with-real-time-analytics', '/elevating-private-equity-performance-with-strategic-esg-solutions', '/align-your-investments-with-global-sustainability-goals']) ? 'active_menu' : ''}`}>ESG</Button>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>

                <Box className='bdd'>
                  <Box component={Link} to='/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses' className={`linkitem ${isActive(['/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)} ><span>  ESG </span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box className={`linkitem ${isActive(['/empowering-esg-investment-with-real-time-analytics', '/elevating-private-equity-performance-with-strategic-esg-solutions', '/align-your-investments-with-global-sustainability-goals']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem><span> ESG Products</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd' ml={1}>
                  <Box component={Link} to='/empowering-esg-investment-with-real-time-analytics' className={`linkitem ${isActive(['/empowering-esg-investment-with-real-time-analytics']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)} ><span className='skblue align_c'><ArrowRightIcon fontSize='small' /> ESG News Senti-Meter </span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd' ml={1}>
                  <Box component={Link} to='/elevating-private-equity-performance-with-strategic-esg-solutions' className={`linkitem ${isActive(['/elevating-private-equity-performance-with-strategic-esg-solutions']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}  ><span className='skblue align_c'><ArrowRightIcon fontSize='small' /> ESG for Private Equity </span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd' ml={1}>
                  <Box component={Link} to='/align-your-investments-with-global-sustainability-goals' className={`linkitem ${isActive(['/align-your-investments-with-global-sustainability-goals']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}  ><span className='skblue align_c'><ArrowRightIcon fontSize='small' /> ESG GreenInvest Pulse </span>
                    </MenuItem>
                  </Box>
                </Box>


                <Box className='bdd'>
                  <Box component={Link} to='/esg-insights/esg-whitepaper' className={`linkitem ${isActive(['/esg-insights/esg-whitepaper']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)} ><span> ESG Insights</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses#esg-executable-roundtable' className={`linkitem ${isActive(['/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses#esg-executable-roundtable']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)} ><span> ESG Roundtable</span>
                    </MenuItem>
                  </Box>
                </Box>




              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>


        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Button endIcon={<ArrowDropDownIcon />} disableRipple className={`menutt ${isActive(['/data-solution-in-us', '/business-intelligence-solution', '/artificial-intelligence-solutions', '/leading-research-solution-company', '/risk-management-company-in-us', '/small-business-administration', '/catastrophe-modeling-services', '/quantum-computing', '/ecopulse', '/coffeepulse', '/creditpulse', '/analystpulse', '/fidelitypulse', '/robopulse']) ? 'active_menu' : ''}`}>Solutions</Button>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>

                <Typography className="menuheading">Services</Typography>

                <Box className='bdd'>
                  <Box component={Link} to='/data-solution-in-us' className={`linkitem ${isActive(['/data-solution-in-us']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Data Solution</span>
                    </MenuItem>
                    <Typography className='menudscrp'>Let us solve 80% of your problems.</Typography>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/business-intelligence-solution' className={`linkitem ${isActive(['/business-intelligence-solution']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Business Intelligence</span>
                    </MenuItem>
                    <Typography className='menudscrp'>Make Informed Business Decision, Overcome The Analytical Bottleneck</Typography>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/artificial-intelligence-solutions' className={`linkitem ${isActive(['/artificial-intelligence-solutions']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)} ><span>Artificial Intelligence</span>
                    </MenuItem>
                    <Typography className='menudscrp'>Empower your business with AI to stay ahead.</Typography>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/leading-research-solution-company' className={`linkitem ${isActive(['/leading-research-solution-company']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)} ><span>Research Solution</span>
                    </MenuItem>
                    <Typography className='menudscrp'>The analysis that augments your quality of decisions</Typography>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/risk-management-company-in-us' className={`linkitem ${isActive(['/risk-management-company-in-us']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)} ><span>Risk Management</span>
                    </MenuItem>
                    <Typography className='menudscrp'>The analysis that augments your quality of decisions</Typography>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/small-business-administration' className={`linkitem ${isActive(['/small-business-administration']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)} ><span>Small Business Administration</span>
                    </MenuItem>
                    <Typography className='menudscrp'>Optimize the Advantages of SBA-Guaranteed Loans by Utilizing DPA's Efficient Loan Processing Capabilities.</Typography>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/catastrophe-modeling-services' className={`linkitem ${isActive(['/catastrophe-modeling-services']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)} ><span>Catastrophe Modeling Services</span>
                    </MenuItem>
                    <Typography className='menudscrp'>Decimal Point Analytics offers expert Catastrophe Modeling for sharp risk management</Typography>
                  </Box>
                </Box>


                <Typography className="menuheading">Upcoming</Typography>

                <Box className='bdd'>
                  <Box component={Link} to='/quantum-computing' className={`linkitem ${isActive(['/quantum-computing']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)} ><span>Quantum Computing</span>
                    </MenuItem>
                    <Typography className='menudscrp'>Emerging technology that harnesses the laws of quantum mechanics for solving complex problems</Typography>
                  </Box>
                </Box>

              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography className="menuheading">Product</Typography>

                <Box className='bdd'>
                  <Box component={Link} to='/ecopulse' className={`linkitem ${isActive(['/ecopulse']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)} ><span>Eco Pulse</span>
                    </MenuItem>
                    <Typography className='menudscrp'>Delivering economic indicators</Typography>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/coffeepulse' className={`linkitem ${isActive(['/coffeepulse']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)} ><span>Coffee Pulse</span>
                    </MenuItem>
                    <Typography className='menudscrp'>ML-based portal to provide real-time Coffee news that matters</Typography>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/creditpulse' className={`linkitem ${isActive(['/creditpulse']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)} ><span>Credit Pulse</span>
                    </MenuItem>
                    <Typography className='menudscrp'>ML-based Bankruptcy Risk Identifier using Textual Information.</Typography>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/analystpulse' className={`linkitem ${isActive(['/analystpulse']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)} ><span>Analyst Pulse</span>
                    </MenuItem>
                    <Typography className='menudscrp'>Research Solution For Investor Relations Team</Typography>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/fidelitypulse' className={`linkitem ${isActive(['/fidelitypulse']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)} ><span>Fidelity Pulse</span>
                    </MenuItem>
                    <Typography className='menudscrp'>Fraud Detection Tool</Typography>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/robopulse' className={`linkitem ${isActive(['/robopulse']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)} ><span>Robo Pulse</span>
                    </MenuItem>
                    <Typography className='menudscrp'>Algo-based fintech solution for portfolio optimization</Typography>
                  </Box>
                </Box>

              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>


        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header"  >
            <Button endIcon={<ArrowDropDownIcon />} disableRipple className={`menutt ${isActive(['/case-studies', '/articles', '/blog', '/newsletter', '/podcast', '/infographics', '/whitepaper']) ? 'active_menu' : ''}`}>Insights</Button>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>

                <Box className='bdd'>
                  <Box component={Link} to='/case-studies' className={`linkitem ${isActive(['/case-studies']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span> Success Stories </span>
                    </MenuItem>
                  </Box>
                </Box>


                <Box className='bdd'>
                  <Box component={Link} to='/articles' className={`linkitem ${isActive(['/articles']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span> Articles </span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/blog' className={`linkitem ${isActive(['/blog']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span> Blogs </span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/newsletter' className={`linkitem ${isActive(['/newsletter']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span> Newsletter </span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/podcast' className={`linkitem ${isActive(['/podcast']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span> Podcast </span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/infographics' className={`linkitem ${isActive(['/infographics']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span> Infographic </span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/whitepaper' className={`linkitem ${isActive(['/whitepaper']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span> Whitepapers </span>
                    </MenuItem>
                  </Box>
                </Box>

              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>


        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary component={Link} to='/careers' aria-controls="panel3d-content" id="panel3d-header" onClick={toggleDrawer(anchor, false)}>
            <Button disableRipple className={`menutt ${isActive(['/careers']) ? 'active_menu' : ''}`}>Careers</Button>
          </AccordionSummary>
        </Accordion>


        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" >
            <Button endIcon={<ArrowDropDownIcon />} disableRipple className={`menutt ${isActive(['/who-we-are', '/decimal-sutras', '/awards', '/technologyedge', '/valueproposition', '/management', '/structure-innovation', '/csr', '/energy-efficiency', '/event', '/pressreleases']) ? 'active_menu' : ''}`}>About Us</Button>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>

                <Box className='bdd'>
                  <Box component={Link} to='/who-we-are' className={`linkitem ${isActive(['/who-we-are']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Our Story & Offerings</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/decimal-sutras' className={`linkitem ${isActive(['/decimal-sutras']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Decimal Sutras</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/awards' className={`linkitem ${isActive(['/awards']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Awards and Accolades</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/technologyedge' className={`linkitem ${isActive(['/technologyedge']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>The Technology Edge</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/valueproposition' className={`linkitem ${isActive(['/valueproposition']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Value Proposition</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/management' className={`linkitem ${isActive(['/management']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Management Team</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/structure-innovation' className={`linkitem ${isActive(['/structure-innovation']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Structure of Innovation</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/csr' className={`linkitem ${isActive(['/csr']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>CSR</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/energy-efficiency' className={`linkitem ${isActive(['/energy-efficiency']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Energy Efficiency</span>
                    </MenuItem>
                  </Box>
                </Box>
                <Box className='bdd'>
                  <Box component={Link} to='/event' className={`linkitem ${isActive(['/event']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Events</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/pressreleases' className={`linkitem ${isActive(['/pressreleases']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>News Room</span>
                    </MenuItem>
                  </Box>
                </Box>

              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>


        <Accordion expanded={expanded === 'panelPartner'} onChange={handleChange('panelPartner')}>
          <AccordionSummary aria-controls="panelPartnerd-content" id="panelPartnerd-header"  >
            <Button endIcon={<ArrowDropDownIcon />} disableRipple className={`menutt ${isActive(['/oracle']) ? 'active_menu' : ''}`}>Partners</Button>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>

                <Box className='bdd'>
                  <Box component={Link} to='/oracle' className={`linkitem ${isActive(['/oracle']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Oracle</span>
                    </MenuItem>
                  </Box>
                </Box>

              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>


        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary aria-controls="panel6d-content" id="panel6d-header" >
            <LetsConnectCTA className="contact_sales_btn" btn_name="Contact Sales" />
          </AccordionSummary>
        </Accordion>



      </Box>
    </Box >
  );



  const location = useLocation();

  const isActive = (paths) => {
    return paths.some(path => location.pathname === (path));
  };


  return (
    <div className="grow">
      <Scroll showBelow={250} />
      <AppBar position="fixed" className="appnavbar" alignItems="center" data-aos="fade-down">
        <Toolbar>

          <Box className='loggo0 flx_center align_c'>



            {window.location.pathname === "/oracle" ? (
              <>
                <a href='/' onClick={() => { window.location.href = "/" }}><img src={dpalogo} className='dpalogo withora' alt='Decimal Point Analytics' /></a>
                <a href='/' onClick={() => { window.location.href = "/" }}><img src={smdpalogo} className='smdpalogo' alt='Decimal Point Analytics' /></a>
                <a href='/oracle' onClick={() => { window.location.href = "/oracle" }}><img src={oraclelogo} className='oraclelogo' alt='Oracle Partner' /></a>
              </>
            ) : (
              <>
                <a href='/' onClick={() => { window.location.href = "/" }}><img src={dpalogo} className='dpalogo' alt='Decimal Point Analytics' /></a>
              </>
            )}

          </Box>

          <div className="grow" />
          <div className="sectionDesktop desktopviewmenu">

            <Link to='/' className="logintitle" >
              <Button disableRipple className={`menutt ${isActive(['/']) ? 'active_menu' : ''}`}>Home</Button>
            </Link>


            <Box variant="h6" className="logintitle" aria-controls="menuId" aria-haspopup="true" onClick={handleClickESG}>
              <Button endIcon={<ArrowDropDownIcon />} disableRipple className={`menutt ${isActive(['/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses', '/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses#esg-executable-roundtable', '/esg-insights/esg-whitepaper', '/empowering-esg-investment-with-real-time-analytics', '/elevating-private-equity-performance-with-strategic-esg-solutions', '/align-your-investments-with-global-sustainability-goals']) ? 'active_menu' : ''}`}>ESG</Button>
            </Box>

            <Menu
              id="menuId"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              anchorEl={anchorEESG}
              keepMounted
              open={Boolean(anchorEESG)}
              onClose={handleCloseESG} className="insight_menu esg_menu"
            >

              <Box className='bdd'>
                <Box component={Link} to='/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses' className={`linkitem ${isActive(['/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseESG} ><span>  ESG </span>
                  </MenuItem>
                </Box>
              </Box>


              <Box className='bdd no_br'>
                <Box className={`linkitem ${isActive(['/empowering-esg-investment-with-real-time-analytics', '/elevating-private-equity-performance-with-strategic-esg-solutions', '/align-your-investments-with-global-sustainability-goals']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem>
                    <span> ESG Products</span>
                  </MenuItem>
                </Box>
              </Box>

              <Box className='bdd' ml={1}>
                <Box component={Link} to='/empowering-esg-investment-with-real-time-analytics' className={`linkitem ${isActive(['/empowering-esg-investment-with-real-time-analytics']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseESG} className='nest_list'><span className='skblue nested align_c'><ArrowRightIcon fontSize='small' />  ESG News Senti-Meter </span>
                  </MenuItem>
                </Box>
              </Box>

              <Box className='bdd' ml={1}>
                <Box component={Link} to='/elevating-private-equity-performance-with-strategic-esg-solutions' className={`linkitem ${isActive(['/elevating-private-equity-performance-with-strategic-esg-solutions']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseESG} className='nest_list'><span className='skblue nested align_c'><ArrowRightIcon fontSize='small' />  ESG for Private Equity </span>
                  </MenuItem>
                </Box>
              </Box>

              <Box className='bdd' ml={1}>
                <Box component={Link} to='/align-your-investments-with-global-sustainability-goals' className={`linkitem ${isActive(['/align-your-investments-with-global-sustainability-goals']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseESG} className='nest_list'><span className='skblue nested align_c'><ArrowRightIcon fontSize='small' />  ESG GreenInvest Pulse </span>
                  </MenuItem>
                </Box>
              </Box>

              <Box className='bdd'>
                <Box component={Link} to='/esg-insights/esg-whitepaper' className={`linkitem ${isActive(['/esg-insights/esg-whitepaper']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseESG} ><span> ESG Insights</span>
                  </MenuItem>
                </Box>
              </Box>

              <Box className='bdd'>
                <Box component={Link} to='/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses#esg-executable-roundtable' className={`linkitem ${isActive(['/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses#esg-executable-roundtable']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseESG} ><span> ESG Roundtable</span>
                  </MenuItem>
                </Box>
              </Box>

            </Menu>


            <Box variant="h6" className="logintitle" aria-controls="menuId" aria-haspopup="true" onClick={handleProfileMenuOpen}>
              <Button endIcon={<ArrowDropDownIcon />} disableRipple className={`menutt ${isActive(['/data-solution-in-us', '/business-intelligence-solution', '/artificial-intelligence-solutions', '/leading-research-solution-company', '/risk-management-company-in-us', '/small-business-administration', '/catastrophe-modeling-services', '/quantum-computing', '/ecopulse', '/coffeepulse', '/creditpulse', '/analystpulse', '/fidelitypulse', '/robopulse']) ? 'active_menu' : ''}`}>Solutions</Button>
            </Box>

            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              id={menuId}
              keepMounted
              transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={isMenuOpen}
              onClose={handleMenuClose} className="menuitem solutions"
              PaperProps={{
                style: {
                  width: '20ch',
                },
              }}
            >


              <Grid container spacing={2} alignItems="top">
                <Grid item xs={12} sm={12} >
                  <Typography className="menuheading">Services</Typography>

                  <Grid container spacing={2} alignItems="top">

                    <Grid item xs={3} sm={3} >
                      <Box className='bdd'>
                        <Box component={Link} to='/data-solution-in-us' className={`linkitem ${isActive(['/data-solution-in-us']) ? 'active_drop_menu' : ''}`}>
                          <MenuItem onClick={handleMenuClose} ><span>Data Solution</span>
                          </MenuItem>
                          <Typography className='menudscrp'>Let us solve 80% of your problems</Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={3} >
                      <Box className='bdd'>
                        <Box component={Link} to='/business-intelligence-solution' className={`linkitem ${isActive(['/business-intelligence-solution']) ? 'active_drop_menu' : ''}`}>
                          <MenuItem onClick={handleMenuClose} ><span>Business Intelligence</span>
                          </MenuItem>
                          <Typography className='menudscrp'>Drive strategic decisions with essential data insights</Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={3} >
                      <Box className='bdd'>
                        <Box component={Link} to='/artificial-intelligence-solutions' className={`linkitem ${isActive(['/artificial-intelligence-solutions']) ? 'active_drop_menu' : ''}`}>
                          <MenuItem onClick={handleMenuClose} ><span>Artificial Intelligence</span>
                          </MenuItem>
                          <Typography className='menudscrp'>Empower your business with AI to stay ahead</Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={3} >
                      <Box className='bdd'>
                        <Box component={Link} to='/leading-research-solution-company' className={`linkitem ${isActive(['/leading-research-solution-company']) ? 'active_drop_menu' : ''}`}>
                          <MenuItem onClick={handleMenuClose} ><span>Research Solution</span>
                          </MenuItem>
                          <Typography className='menudscrp'>The analysis that augments your quality of decisions</Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={3} >
                      <Box className='bdd'>
                        <Box component={Link} to='/risk-management-company-in-us' className={`linkitem ${isActive(['/risk-management-company-in-us']) ? 'active_drop_menu' : ''}`}>
                          <MenuItem onClick={handleMenuClose} ><span>Risk Management</span>
                          </MenuItem>
                          <Typography className='menudscrp'>The analysis that augments your quality of decisions</Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={3} >
                      <Box className='bdd'>
                        <Box component={Link} to='/small-business-administration' className={`linkitem ${isActive(['/small-business-administration']) ? 'active_drop_menu' : ''}`}>
                          <MenuItem onClick={handleMenuClose} ><span>Small Business Administration</span>
                          </MenuItem>
                          <Typography className='menudscrp'>Streamline SBA lending with precision and efficiency</Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={3} >
                      <Box className='bdd'>
                        <Box component={Link} to='/catastrophe-modeling-services' className={`linkitem ${isActive(['/catastrophe-modeling-services']) ? 'active_drop_menu' : ''}`}>
                          <MenuItem onClick={handleMenuClose} ><span>Catastrophe Modeling Services</span>
                          </MenuItem>
                          <Typography className='menudscrp'>Optimize risk strategies with CAT modeling</Typography>
                        </Box>
                      </Box>
                    </Grid>

                  </Grid>

                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography className="menuheading">Products</Typography>


                  <Grid container spacing={2} alignItems="top">

                    <Grid item xs={3} sm={3} >
                      <Box className='bdd'>
                        <Box component={Link} to='/ecopulse' className={`linkitem ${isActive(['/ecopulse']) ? 'active_drop_menu' : ''}`}>
                          <MenuItem onClick={handleMenuClose} ><span>Eco Pulse</span>
                          </MenuItem>
                          <Typography className='menudscrp'>Delivering economic indicators</Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={3} >
                      <Box className='bdd'>
                        <Box component={Link} to='/coffeepulse' className={`linkitem ${isActive(['/coffeepulse']) ? 'active_drop_menu' : ''}`}>
                          <MenuItem onClick={handleMenuClose} ><span>Coffee Pulse</span>
                          </MenuItem>
                          <Typography className='menudscrp'>ML-based portal to provide real-time Coffee news that matters</Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={3} >
                      <Box className='bdd'>
                        <Box component={Link} to='/creditpulse' className={`linkitem ${isActive(['/creditpulse']) ? 'active_drop_menu' : ''}`}>
                          <MenuItem onClick={handleMenuClose} ><span>Credit Pulse</span>
                          </MenuItem>
                          <Typography className='menudscrp'>Enhance credit analysis with predictive text insights</Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={3} >
                      <Box className='bdd'>
                        <Box component={Link} to='/analystpulse' className={`linkitem ${isActive(['/analystpulse']) ? 'active_drop_menu' : ''}`}>
                          <MenuItem onClick={handleMenuClose} ><span>Analyst Pulse</span>
                          </MenuItem>
                          <Typography className='menudscrp'>Research Solution For Investor Relations Team</Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={3} >
                      <Box className='bdd'>
                        <Box component={Link} to='/fidelitypulse' className={`linkitem ${isActive(['/fidelitypulse']) ? 'active_drop_menu' : ''}`}>
                          <MenuItem onClick={handleMenuClose} ><span>Fidelity Pulse</span>
                          </MenuItem>
                          <Typography className='menudscrp'>Fraud Detection Tool</Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={3} >
                      <Box className='bdd'>
                        <Box component={Link} to='/robopulse' className={`linkitem ${isActive(['/robopulse']) ? 'active_drop_menu' : ''}`}>
                          <MenuItem onClick={handleMenuClose} ><span>Robo Pulse</span>
                          </MenuItem>
                          <Typography className='menudscrp'>Algo-based fintech solution for portfolio optimization</Typography>
                        </Box>
                      </Box>
                    </Grid>

                  </Grid>

                  <Grid container spacing={2} alignItems="top">
                    <Grid item xs={3} sm={3} >
                      <Typography className="menuheading">Upcoming</Typography>

                      <Box className='bdd'>
                        <Box component={Link} to='/quantum-computing' className={`linkitem ${isActive(['/quantum-computing']) ? 'active_drop_menu' : ''}`}>
                          <MenuItem onClick={handleMenuClose} ><span>Quantum Computing</span>
                          </MenuItem>
                          <Typography className='menudscrp'>Leverage quantum speed for smarter asset management</Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Menu>


            <Box variant="h6" className="logintitle" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickins}>
              <Button endIcon={<ArrowDropDownIcon />} disableRipple className={`menutt ${isActive(['/case-studies', '/articles', '/blog', '/newsletter', '/podcast', '/infographics', '/whitepaper']) ? 'active_menu' : ''}`}>Insights</Button>
            </Box>

            <Menu
              id="menuId"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              anchorEl={anchorEins}
              keepMounted
              open={Boolean(anchorEins)}
              onClose={handleCloseins} className="insight_menu"

            >

              <Box className='bdd'>
                <Box component={Link} to='/case-studies' className={`linkitem ${isActive(['/case-studies']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseins} ><span> Success Stories </span>
                  </MenuItem>
                </Box>
              </Box>


              <Box className='bdd'>
                <Box component={Link} to='/articles' className={`linkitem ${isActive(['/articles']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseins} ><span> Articles </span>
                  </MenuItem>
                </Box>
              </Box>

              <Box className='bdd'>
                <Box component={Link} to='/blog' className={`linkitem ${isActive(['/blog']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseins} ><span> Blogs </span>
                  </MenuItem>
                </Box>
              </Box>

              <Box className='bdd'>
                <Box component={Link} to='/newsletter' className={`linkitem ${isActive(['/newsletter']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseins} ><span> Newsletter </span>
                  </MenuItem>
                </Box>
              </Box>

              <Box className='bdd'>
                <Box component={Link} to='/podcast' className={`linkitem ${isActive(['/podcast']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseins} ><span> Podcast </span>
                  </MenuItem>
                </Box>
              </Box>

              <Box className='bdd'>
                <Box component={Link} to='/infographics' className={`linkitem ${isActive(['/infographics']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseins} ><span> Infographic </span>
                  </MenuItem>
                </Box>
              </Box>

              <Box className='bdd'>
                <Box component={Link} to='/whitepaper' className={`linkitem ${isActive(['/whitepaper']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseins} ><span> Whitepapers </span>
                  </MenuItem>
                </Box>
              </Box>

            </Menu>


            {/* <Link to='/careers' variant="h6" className="logintitle" > */}
            <Link to='/careers' className="logintitle">
              <Button disableRipple className={`menutt ${isActive(['/careers']) ? 'active_menu' : ''}`}>Careers</Button>
            </Link>


            <Box variant="h6" className="logintitle" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              <Button endIcon={<ArrowDropDownIcon />} disableRipple className={`menutt ${isActive(['/who-we-are', '/decimal-sutras', '/awards', '/technologyedge', '/valueproposition', '/management', '/structure-innovation', '/csr', '/energy-efficiency', '/event', '/pressreleases']) ? 'active_menu' : ''}`}>About Us</Button>
            </Box>

            <Menu
              id="menuId"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              anchorEl={anchorE2}
              keepMounted
              open={Boolean(anchorE2)}
              onClose={handleClose} className="menuitem"
            >


              <Grid container spacing={4} alignItems="top">
                <Grid item xs={12} sm={6}>

                  <Box className='bdd'>
                    <Box component={Link} to='/who-we-are' className={`linkitem ${isActive(['/who-we-are']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>Our Story & Offerings</span>
                      </MenuItem>
                    </Box>
                  </Box>

                  <Box className='bdd'>
                    <Box component={Link} to='/decimal-sutras' className={`linkitem ${isActive(['/decimal-sutras']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>Decimal Sutras</span>
                      </MenuItem>
                    </Box>
                  </Box>

                  <Box className='bdd'>
                    <Box component={Link} to='/awards' className={`linkitem ${isActive(['/awards']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>Awards and Accolades</span>
                      </MenuItem>
                    </Box>
                  </Box>

                  <Box className='bdd'>
                    <Box component={Link} to='/technologyedge' className={`linkitem ${isActive(['/technologyedge']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>The Technology Edge</span>
                      </MenuItem>
                    </Box>
                  </Box>

                  <Box className='bdd'>
                    <Box component={Link} to='/valueproposition' className={`linkitem ${isActive(['/valueproposition']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>Value Proposition</span>
                      </MenuItem>
                    </Box>
                  </Box>

                  <Box className='bdd'>
                    <Box component={Link} to='/management' className={`linkitem ${isActive(['/management']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>Management Team</span>
                      </MenuItem>
                    </Box>
                  </Box>

                </Grid>


                <Grid item xs={12} sm={6}>


                  {/* <Box className='bdd'>
                    <Box className='linkitem' component={Link} to='/testimonials'>
                      <MenuItem onClick={handleClose} ><span>Client Testimonials</span>
                      </MenuItem>
                    </Box>
                  </Box> */}

                  <Box className='bdd'>
                    <Box component={Link} to='/structure-innovation' className={`linkitem ${isActive(['/structure-innovation']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>Structure of Innovation</span>
                      </MenuItem>
                    </Box>
                  </Box>

                  <Box className='bdd'>
                    <Box component={Link} to='/csr' className={`linkitem ${isActive(['/csr']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>CSR</span>
                      </MenuItem>
                    </Box>
                  </Box>

                  <Box className='bdd'>
                    <Box component={Link} to='/energy-efficiency' className={`linkitem ${isActive(['/energy-efficiency']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>Energy Efficiency</span>
                      </MenuItem>
                    </Box>
                  </Box>
                  <Box className='bdd'>
                    <Box component={Link} to='/event' className={`linkitem ${isActive(['/event']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>Events</span>
                      </MenuItem>
                    </Box>
                  </Box>

                  <Box className='bdd'>
                    <Box component={Link} to='/pressreleases' className={`linkitem ${isActive(['/pressreleases']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>News Room</span>
                      </MenuItem>
                    </Box>
                  </Box>


                </Grid>
              </Grid>

            </Menu>

            <Box variant="h6" className="logintitle" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickOra}>
              <Button endIcon={<ArrowDropDownIcon />} disableRipple className={`menutt ${isActive(['/oracle']) ? 'active_menu' : ''}`}>Partners</Button>
            </Box>

            <Menu
              id="menuId"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              anchorEl={anchorEOra}
              keepMounted
              open={Boolean(anchorEOra)}
              onClose={handleCloseOra} className="insight_menu"
            >


              <Box className=''>
                <Box component={Link} to='/oracle' className={`linkitem ${isActive(['/oracle']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseOra} ><span>Oracle</span>
                  </MenuItem>
                </Box>
              </Box>

            </Menu>


            {/* <Link to='/oracle' className="logintitle">
              <Button disableRipple className={`menutt ${isActive(['/oracle']) ? 'active_menu' : ''}`}>Partners</Button>
            </Link> */}

            {/* <Link to='/contact-us' variant="h6" className="logintitle" > */}
            {/* <Button className="contact_sales_btn" disableRipple>Contact Sales</Button> */}
            <LetsConnectCTA className="contact_sales_btn" btn_name="Contact Sales" />
            {/* </Link> */}


          </div>


          <Box className='mobileviewmenu'>
            {['right'].map((anchor) => (
              <React.Fragment>

                {/* <Link to='/contact-us'>
                  <IconButton>
                    <LocalPhoneIcon fontSize="small" className='contact_sales_icon' />
                  </IconButton>
                </Link> */}

                {/* <LetsConnectCTA className="contact_sales_btn" btn_name="Contact Sales" /> */}

                <IconButton aria-label="delete" onClick={toggleDrawer(anchor, true)}>
                  <MenuIcon fontSize="small" color='primary' />
                </IconButton>
                <Drawer
                  className='drawer'
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </Box>

        </Toolbar>
      </AppBar>

    </div >



  );
}
