import { Container, Grid, Typography, Box, IconButton } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import Writemessage3 from '../../Component/Writemessage3'
import { Helmet } from 'react-helmet';
import rb1 from "../../asset/images/product/robopulse/rb1.svg";
import rb2 from "../../asset/images/product/robopulse/rb2.svg";
import rb3 from "../../asset/images/product/robopulse/rb3.svg";
import rb4 from "../../asset/images/product/robopulse/rb4.svg";
import rb5 from "../../asset/images/product/robopulse/rb5.svg";
import rb6 from "../../asset/images/product/robopulse/rb6.svg";
import rb7 from "../../asset/images/product/robopulse/rb7.svg";
import rb8 from "../../asset/images/product/robopulse/rb8.svg";


import WhyRobo from "../../asset/images/product/why_creditpulse.png";
import ProductCTAA from '../../Component/ProductCTAA';
import GetInTouch from '../../Component/Get_in_touch';
import RobopulseFaq from './Robopulse_components/RobopulseFaq';

import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

import RoboPulse from "../../asset/images/new_banner/robo_pulse_banner.jpg"

export default function Robopulse() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <>


      <Helmet>
        <meta charset="UTF-8" />
        <title>RoboPulse: Empowering Intelligent Investments and Financial Planning</title>
        <meta name="description" content="Discover intelligent investing with RoboPulse. Get tailored investment portfolios, unbiased recommendations, and proactive performance monitoring." />
        <meta name="author" content="Decimal Point Analytics" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/robopulse" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />


        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name": "Robo Pulse",
            "image": "https://www.decimalpointanalytics.com/static/media/roboplusebanner.7f6bd229691910b16f6d.gif",
            "description": "RoboPulse is a digital platform which provides automated, algorithm-driven financial planning services with no human supervision. It is a dynamic tool, recommending Individual and Institutional investors with sophisticated and customized investment portfolio to achieve set target amount. This algorithm renders precise, quick and inexpensive investment recommendations across the asset classes, risk profiles, countries, and regions. It also reviews the progress periodically and suggests changes just like a human advisor.",
            "brand": {
              "@type": "Brand",
              "name": "Decimal Point Analytics"
            }
          }
        
          `}
        </script>
      </Helmet>


      <Box id="product-page">

        {/* <Box className="mainsection" component="section">
        <Container maxWidth="lg">
          <Grid container spacing={1} alignItems="center">
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box>
                <Typography variant="h1" className="blue">
                  Robo Pulse
                </Typography>

                <Typography variant="h4" className="black">
                  Your Premier Robo Advisory Platform
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box className="landing_side_vdo" style={{ backgroundImage: 'url(' + indexbanner + ')', }}>
                <IconButton className="pulse-button pulse_button_2" onClick={openModal} >
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box> */}

        <Box className="banner_bx">
          <Box style={{ backgroundImage: "url(" + RoboPulse + ")" }} className="banner_img">
            <Box className="img_over bl_overlay">
              <Grid container spacing={0} alignItems="center" justifyContent='center'>
                <Grid item lg={3} md={4} sm={5} xs={8}>
                  <Box className="al_center">
                    <Typography className="white" variant="h1">Robo Pulse</Typography>
                    <Box mt={'10px'}>
                      <Typography className="white" variant="h4">Your Premier Robo Advisory Platform</Typography>
                      <Box className='banner_btns'>
                        <Box className='banner_pulse'>
                          <IconButton className="header_pulse_button" onClick={openModal} >
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>


        <Box className='section2 sec_bg' component='section'>
          <Container maxWidth='lg' className='bdr'>

            {/* <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className='al_center'>
                <Typography variant='h2' className='black'>
                  What is RoboPulse?
                </Typography>
              </Box>
            </Grid>
          </Grid> */}


            <Grid container direction="row"
              justifyContent="center"
              alignItems="center">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_center synopsic_bx">
                  <Typography variant='h6' className='black jst'>
                    The RoboPulse Robo-advisory engine revolutionizes financial planning by offering institutional investors a seamless and efficient automated solution. Through its powerful APIs, it delivers algorithm-driven, personalized investment portfolios designed to meet specific objectives. Leveraging cutting-edge technology, RoboPulse swiftly generates accurate and cost-effective investment recommendations across different asset classes, risk profiles, and regions. This intelligent system diligently monitors portfolio performance, offering timely adjustments and insightful suggestions, mirroring the guidance of a human advisor.
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    Through RoboPulse, institutional investors can benefit from unbiased investment strategies personalized to their goals and risk appetite. The platform's algorithms analyze historical performance data, ensuring optimal investment decisions and mitigating biases. Additionally, RoboPulse actively manages market volatility by automatically rebalancing portfolios according to goals and risk tolerance. With periodic progress reviews and prompt notifications, RoboPulse keeps investors informed about portfolio performance, empowering them to make informed decisions and achieve financial objectives. RoboPulse sets the benchmark for future financial planning, seamlessly integrating technology and data-driven insights into the investment process.
                  </Typography>

                </Box>

                <Box mt={2}>
                  <ProductCTAA allignment="flx_fs" cta_content="Invest smarter, faster, and more efficiently with RoboPulse." cta_btn_name="Book Your Free Demo Now!" pageVisited="Robo Pulse" />
                </Box>

              </Grid>
            </Grid>
          </Container>
        </Box>



        <Box className="section mob_card" component="section">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_center">
                  <Typography className="black" variant="h2"> Experience Unmatched Benefits with RoboPulse:
                    <Typography variant="h2" className="black h2v2"> Elevating Your Business to New Height </Typography>
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Box mt={3}>
              <Grid container spacing={2} alignItems="top">
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={rb1} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two">
                          Optimal asset allocation:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Algorithms analyze asset classes for the ideal mix aligned with your goals and risk tolerance.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={rb2} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two">
                          Bias-free portfolio construction:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Data-driven insights ensure unbiased, optimized investment strategies.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={rb3} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two">
                          Performance-driven <br /> recommendations:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Leverage historical data for strong-performing investment schemes.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={rb4} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two" >
                          Customized asset allocation:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Tailor your portfolio to your unique financial aspirations.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={rb5} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two" >
                          Monitor progress and benchmarking:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Stay informed with performance reviews and benchmark comparisons.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={rb6} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two" >
                          Navigate market volatility:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Automatic portfolio rebalancing to manage market fluctuations.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>


                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={rb7} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two" >
                          Hassle-free  portfolio management:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Take control of your portfolio effortlessly through our intuitive platform.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>


                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={rb8} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two" >
                          Timely goal updates:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Stay informed with proactive notifications for your investment goals
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

              </Grid>
            </Box>
          </Container>
        </Box>


        <Box className="section sec_bg" component="section">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={0} alignItems="center">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_center" pb={'2%'}>
                  <Typography className="black" variant="h2">
                    Why Choose RoboPulse ?
                  </Typography>
                </Box>

                <Box className="al_center">
                  <img src={WhyRobo} className="topbanner2" alt="Banner2" />
                </Box>

                <Box>
                  <ProductCTAA allignment="al_center" cta_content="Take your business investments to the next level with RoboPulse. " cta_btn_name="Schedule Your Demo Today!" pageVisited="Robo Pulse" />
                </Box>


              </Grid>
            </Grid>
          </Container>
        </Box>




        <Box className='section' component='section'>
          <Container maxWidth='lg' className='bdr'>

            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_center">
                  <Typography className="black" variant="h2">  Empowering Intelligent Investments
                    <Typography variant="h2" className="black h2v2"> and Unprecedented Opportunities </Typography>
                  </Typography>
                </Box>
              </Grid>
            </Grid>


            <Grid container direction="row"
              justifyContent="center"
              alignItems="center">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box mt={4}>
                  <Typography variant='h6' className='black jst'>
                    Enhance your business's investment strategy and harness the advanced capabilities of RoboPulse. With our ultramodern algorithmic intelligence and meticulously crafted solutions, we empower companies to optimize their financial decisions and achieve exceptional outcomes. Experience streamlined portfolio management, proactive performance monitoring, and effective risk mitigation customized to your business objectives. RoboPulse offers unmatched benefits that drive success in a rapidly evolving market. Make the strategic choice for your business's financial future. Join RoboPulse today and unlock a realm of intelligent investing and unparalleled opportunities.
                  </Typography>

                </Box>

                <Box mt={2}>
                  <ProductCTAA allignment="flx_fs" cta_content="Experience the Power of RoboPulse." cta_btn_name="Book a free demo now!" pageVisited="Robo Pulse" />
                </Box>


              </Grid>
            </Grid>
          </Container>
        </Box>







        <Box className='section sec_bg' component='section'>
          <Container maxWidth='lg' className='bdr'>
            <RobopulseFaq />
          </Container>
        </Box>









        <Box className="al_center">
          <GetInTouch />
        </Box>



        <Writemessage3 pageVisited="Robo Pulse" />


      </Box>

      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="XkdJT2VYjqc"
        onClose={() => setIsOpen(false)}
      />


    </>
  );
}
