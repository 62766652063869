import React, { Component } from "react";
import { Container, Grid, Typography, Box, } from '@material-ui/core';
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { Link } from 'react-router-dom';

import { Helmet } from "react-helmet";

import Aos from "aos";
import "aos/dist/aos.css";
import whoweare from "../../asset/images/aboutus/whoweare_sideimg.png";


import tech from "../../asset/images/aboutus/tech4.svg";
import valu from "../../asset/images/aboutus/val1.svg";
import team from "../../asset/images/aboutus/team.svg";

import Whoweare from "../../asset/images/new_banner/who_we_are_banner.jpg"

export class VideoModal extends Component {


  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    Aos.init({ duration: 1000 });
  }

  render() {
    return (
      <>


        <Helmet>
          <meta charset="UTF-8" />
          <h1>Customized, Optimized And Enhanced Research Operations</h1>
          <title>Team of Experts in Financial Services & Computing </title>
          <meta name="description" content="Leading & Trusted Partner in financial market professionals. A full services consulting company for financial institutions around the world. Contact Us Now!" />
          <meta name="keywords" content="artificial intelligence solutions company
Private companies ESG data provider
Research company
Staff Augmentation
staff outsourcing solutions" />
          <meta name="author" content="Decimal Point Analytics" />
          <link rel="canonical" href="https://www.decimalpointanalytics.com/who-we-are" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>


        {/* <Box className='mainsection' component='section'>
          <Container maxWidth='lg'>
            <Grid container spacing={1} alignItems="center">
              <Grid item lg={5} md={6} sm={6} xs={12} >
                <Box>
                  <Typography variant='h1' className='blue'>
                    Who We Are
                  </Typography>

                  <Typography variant='h4' className='black'>
                    Customized, Optimized and Enhanced Research Operations
                  </Typography>

                </Box>
              </Grid>

              <Grid item lg={7} md={7} sm={6} xs={12} >
                <Box className='al_center'>
                  <img src={indexbanner} className='topbanner' alt="Index Banner" />
                </Box>
              </Grid>

            </Grid>
          </Container>
        </Box> */}

        <Box className="banner_bx">
          <Box style={{ backgroundImage: "url(" + Whoweare + ")" }} className="banner_img">
            <Box className="img_over bl_overlay">
              <Grid container spacing={0} alignItems="center" justifyContent='center'>
                <Grid item lg={3} md={4} sm={5} xs={8}>
                  <Box className="al_center">
                    <Typography className="white" variant="h1">Who We Are</Typography>
                    <Box mt={'10px'}>
                      <Typography className="white" variant="h4">Customized, Optimized and<br /> Enhanced Research Operations</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        <Box className='section2 sec_bg' component='section'>
          <Container maxWidth='lg' className='bdr'>
            <Grid container spacing={1} alignItems="center">
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Box className="al_center synopsic_bx">
                  {/* <Typography variant='h2' className='black al_center'>
                    Who We Are
                  </Typography> */}
                  <Typography variant='h6' className='black jst'>
                    Since its inception in March 2003, Decimal Point Analytics has been a trusted partner to an entire spectrum of financial market professionals. We are thrilled to be able to deliver innovation in research processes through a team of experts in financial services and computing. By utilizing and scaling the values we bring into the engagements, our customers have been able to delight their end-customers and garner competitive advantage in the process. Our team based in India shares a common vision and pedigree. It includes highly experienced professionals with advanced degrees in engineering, math, business and financial domain. The research wing is often complemented by a team of equally capable professionals at client locations with a common objective of delivering value to clients
                  </Typography>
                </Box>
              </Grid>

            </Grid>
          </Container>
        </Box>


        <Box className='section' component='section'>
          <Container maxWidth='lg' className='bdr'>

            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='al_center'>
                  <Typography variant='h2' className='black'>
                    What We Do
                  </Typography>
                </Box>
              </Grid>
            </Grid>


            <Grid container spacing={1} alignItems="center">
              <Grid item lg={7} md={6} sm={6} xs={12} >
                <Box>
                  <Typography variant='h6' className='black jst'>
                    Decimal Point Analytics Private Limited (DPA) is a leading management consultant in the space of financial solutions & a full service consulting company providing customized management consulting solutions to clients based around the world. The company has extensive management consultancy service capabilities in the key areas of financial research, analysis, modelling, risk management and related solutions. DPA’s clients are institutional asset managers, family offices, and data providers across the globe.
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    Our services involve consulting and market research services related to economies and financial markets. These services cover most economies and leading financial market analysis, including equities, fixed income, developed and emerging economies and economic modelling, funds and fund analytics, company and industry analysis and other financial market data analytics designed to secure information on the prospects and performance of the relevant market. The company’s domain knowledge in financial markets, coupled with seamless delivery capabilities of its dedicated analyst consultants, enables optimal utilization of senior resources by clients.
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    The company facilitates the management consulting of processes of its clients through solutions that combine its markets expertise, technology and access to a vast pool of qualified talent. DPA’s clients have benefited from innovative management consultancy services that have contributed significantly to the quality and timeliness of market research data, information or requirements.
                  </Typography>


                </Box>
              </Grid>
              <Grid item lg={5} md={6} sm={6} xs={12} >
                <Box className='al_right'>
                  <img src={whoweare} className='topbanner' alt='Who We Are' />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box className='section sec_bg ourstrength' component='section'>
          <Container maxWidth='lg' className='bdr'>

            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Box className='al_center'>

                  <Typography className='black' variant='h2'>
                    So, Why Decimal
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Box className="mob_card" mt={3}>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={4} md={4} sm={6} xs={12} >
                  <Link to='/technologyedge'>
                    <Box className='str_bg'>
                      <Box mb={1}>
                        <img src={tech} className='bxicon' alt='TEch' />
                      </Box>
                      <Typography className='black w100' variant='h4'>The Technology Edge</Typography>
                    </Box>
                  </Link>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12} >
                  <Link to='/valueproposition'>
                    <Box className='str_bg'>
                      <Box mb={1}>
                        <img src={valu} className='bxicon' alt='value' />
                      </Box>
                      <Typography className='black w100' variant='h4'>Value Proposition</Typography>
                    </Box>
                  </Link>
                </Grid>



                <Grid item lg={4} md={4} sm={6} xs={12} >
                  <Link to='/management'>
                    <Box className='str_bg'>
                      <Box mb={1}>
                        <img src={team} className='bxicon' alt="team" />
                      </Box>
                      <Typography className='black w100' variant='h4'>Management Team</Typography>
                    </Box>
                  </Link>
                </Grid>


              </Grid>
            </Box>


          </Container>
        </Box>



        <Box className='section' component='section'>
          <Container maxWidth='lg' className='bdr'>
            <Grid container spacing={3} alignItems="center">
              <Grid item lg={6} md={6} sm={6} xs={12} >
                <Box>

                  <Typography variant='h2' className='black'>
                    Watch Our Story
                  </Typography>
                  <Typography variant='h6' className='black jst'>
                    This video captures our unique Value Proposition and how and why we are transforming research operations and analytics needs of investment management and financial intermediation businesses.
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} >
                <Box className='al_center' style={{ position: 'relative', }}>
                  {/* <Box className='videobg_gr'></Box> */}
                  <Box className='videobg'>
                    <button onClick={this.openModal} class="pulse-button"></button>
                  </Box>
                </Box>

                <ModalVideo
                  channel="youtube"
                  isOpen={this.state.isOpen}
                  videoId="0mXcQHdxIYM"
                  onClose={() => this.setState({ isOpen: false })}
                />


              </Grid>
            </Grid>
          </Container>
        </Box>





      </>
    );
  }
}

export default VideoModal;
