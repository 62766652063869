import { Divider, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Carousel from "react-elastic-carousel";

import wlogo from "../../asset/images/aboutus/welfarelogo.png";
import slogo from "../../asset/images/aboutus/smilelogo.jpg";

import csr from "../../asset/images/new_banner/csr_banner.jpg"
import { Helmet } from 'react-helmet';

export default function Csr() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);





    return (
        <>

            <Helmet>
                <title>Corporate Social Responsibility | Decimal Point Analytics</title>
                <meta name="description" content="Decimal Point Analytics prioritizes Corporate Social Responsibility, collaborating with NGOs/Partners who are working for the betterment of underprivileged" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/csr" />
            </Helmet>

            <Box id="csr">


                {/* <Box className='mainsection' component='section'>
                    <Container maxWidth='lg'>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item lg={5} md={6} sm={6} xs={12}>
                                <Box>
                                    <Typography variant='h1' className='blue'>
                                        Corporate Social Responsibility
                                    </Typography>

                                    <Typography variant='h4' className='black'>
                                        Our growth is directly corelated to our societys' growth
                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item lg={7} md={6} sm={6} xs={12}>
                                <Box className='al_right'>
                                    <img src={indexbanner} className='topbanner' alt='Index Banner' />
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box> */}


                <Box className="banner_bx">
                    <Box style={{ backgroundImage: "url(" + csr + ")" }} className="banner_img">
                        <Box className="img_over bl_overlay">
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>
                                <Grid item lg={5} md={7} sm={8} xs={10}>
                                    <Box className="al_center">
                                        <Typography className="white" variant="h1">Corporate Social Responsibility</Typography>
                                        <Box mt={'10px'}>
                                            <Typography className="white" variant="h4">Our Growth is Directly Corelated to Our Society's Growth</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>


                <Box className='section2 sec_bg' component='section'>
                    <Container maxWidth='lg' className='bdr'>

                        {/* <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Corporate Social Responsibility
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid> */}


                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center synopsic_bx">
                                    <Typography variant='h6' className='black jst'>
                                        One of Decimal Point Analytics' core values is "Making a Difference" - where "We aim to create long-term success for our clients, stakeholders, and society."
                                    </Typography>

                                    <Typography variant='h6' className='black jst'>
                                        At Decimal Point Analytics we believe that we have the responsibility to play an active role in the development of society. We are always on the look out to collaborate with NGOs / Partners who are working for the betterment of the underprivileged. We undertake projects which support such NGOs / Partners by building competencies, that help them deliver better results. Our employees have shown tremendous enthusiasm to use their skills and time for such projects. In the past we have been associated with the below projects,
                                    </Typography>
                                </Box>

                            </Grid>
                        </Grid>
                    </Container>
                </Box>


                <Box className='section ourstrength ' component='section' style={{ padding: '0px' }}>
                    <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='csrinfoo' my={5}>
                                    <Grid container spacing={1} alignItems="top">
                                        <Grid item lg={12} md={12} sm={12} xs={12}>

                                            <Box mb={3}>
                                                <img src={slogo} style={{ width: '150px' }} alt='slogo' />
                                            </Box>

                                            <Box className='al_left'>
                                                <Typography variant='h2' className='black '>
                                                    Smile Foundation
                                                </Typography>
                                            </Box>



                                            <Box mt={3}>
                                                <Typography variant='h6' className='black jst'>
                                                    Smile Foundation, an NGO for poor child education, is an NGO in India directly benefitting over 15,00,000 children and their families every year, through more than 400 live welfare projects on education, healthcare, livelihood, and women empowerment, in over 2000 remote villages and slums across 25 states of India. They believe in empowering the individual to earn his/her livelihood through the means of education
                                                </Typography>

                                                <Typography variant='h6' className='black jst'>
                                                    Smile foundation wanted to help students who have completed 10th & 12th standard education. For this, we organized a training session with the trainers about the 'Emerging Opportunities in Digital Marketing' available for students. The session touched upon the industry skills which can be acquired by students to land a job in digital agencies like
                                                </Typography>

                                                <Typography variant='h6' className='blue jst'>
                                                    <strong> Digital Design &nbsp;|&nbsp; Affiliate Marketing &nbsp;|&nbsp; Search Engine Marketing &nbsp;|&nbsp; Google Adwords</strong>
                                                </Typography>

                                                <Typography variant='h6' className='black jst'>
                                                    Post the session we shared online resources to earn online certificates and help them get hired faster.
                                                </Typography>


                                            </Box>




                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} alignItems="top">

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='csrinfoo' my={5}>
                                    <Grid container spacing={1} alignItems="top">
                                        <Grid item lg={12} md={12} sm={12} xs={12}>

                                            <Box mb={3}>
                                                <img src={wlogo} style={{ width: '150px' }} alt='wlogo' />
                                            </Box>

                                            <Box className='al_left'>
                                                <Typography variant='h2' className='black '>
                                                    India Welfare Trust
                                                </Typography>
                                            </Box>



                                            <Box mt={3} mb={4}>
                                                <Typography variant='h6' className='black jst'>
                                                    India Welfare Trust is a private foundation that promotes citizenship, philanthropy & volunteering and also supports high-impact initiatives with grants and hands-on support.
                                                </Typography>

                                                <Typography variant='h6' className='black jst'>
                                                    IWT conducts Volunteering Survey to understand and engage with their volunteers better. We created a “Volunteering Survey Insights Dashboard” for IWT based on the data provided by them. The dashboard helps understand the survey respondents and derive actionable insights from the responses across categories such as
                                                </Typography>

                                                <Typography variant='h6' className='blue jst'>
                                                    <strong> Volunteering Interest &nbsp;|&nbsp; Experience &nbsp;|&nbsp; Enablers &nbsp;|&nbsp; Impediments</strong>
                                                </Typography>

                                                <Typography variant='h6' className='black jst'>
                                                    The dashboard has helped IWT to identify the nature and characteristics of the volunteering survey respondents and meet the main goal of engaging with volunteers deeply.
                                                </Typography>

                                            </Box>

                                            <Divider />
                                        </Grid>
                                    </Grid>

                                    <Box id="partners-testimonials">
                                        <Box className='al_center' mt={4}>
                                            <Typography variant='h2' className='black '>
                                                Our Partners Say
                                            </Typography>
                                        </Box>
                                        <Grid container spacing={1} alignItems="top">
                                            <Grid item lg={12} md={12} sm={12} xs={12}>

                                                <Carousel itemsToShow={1} animateOut="slideOutDown"
                                                    animateIn="flipInX">

                                                    <Box className="boxcontent">
                                                        <Box className='test_text' mt={4}>
                                                            <Typography variant='h6' className='black al_center'>
                                                                Decimal Point Analytics has volunteered with us and provided us with an analysis of our customer profiles, website analytics, and shared insights about digital marketing for LinkedIn. They put in the extra effort in collating key data points that helped us gain a better understanding of the customer personas.
                                                            </Typography>

                                                            <Typography variant='h6' className='black al_center'>
                                                                Decimal Point Analytics has been very receptive to any feedback we shared and promptly updated us with the progress. Coordination with the DPA team has been a breeze and they worked as a team in delivering the said assignment. Overall I had a very pleasant experience working with all the team members.
                                                            </Typography>


                                                        </Box>
                                                        <Box className='line' style={{ margin: '0 auto' }} mt={2} mb={2}></Box>
                                                        <Box mt={4}>
                                                            <Typography variant='h4' className='blue'>Sushanth Kodela</Typography>
                                                            <Typography variant='h6' className='blue'>Development Manager</Typography>
                                                        </Box>
                                                    </Box>

                                                    <Box className="boxcontent">
                                                        <Box className='test_text' mt={4}>
                                                            <Typography variant='h6' className='black al_center'>
                                                                It was a pleasure to work with the team at Decimal Point Analytics! They helped us with the data analysis for a large-scale volunteering survey and provided key insights from it that we found to be very useful.
                                                            </Typography>

                                                            <Typography variant='h6' className='black al_center'>
                                                                They were quite prompt in their turnaround time, very accessible for any kind of support throughout the course of the project, and diligent in their work. In addition to a high quality of work, we enjoyed a very cordial working relationship with the team which made it a great experience
                                                            </Typography>


                                                        </Box>
                                                        <Box className='line' style={{ margin: '0 auto' }} mt={2} mb={2}></Box>
                                                        <Box mt={4}>
                                                            <Typography variant='h4' className='blue'>Dharmaraj Solanki</Typography>
                                                            <Typography variant='h6' className='blue'>Relationship Manager</Typography>
                                                        </Box>
                                                    </Box>
                                                </Carousel>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                </Box>


                            </Grid>
                        </Grid>
                    </Container>

                </Box>
            </Box>
        </>
    );
}
