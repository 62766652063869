import { Container, Typography, Box, Grid,  } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet';
import Banner from '../../../asset/images/insight/whitepaper_pages/banner_tech_led.png'
import ViewFullWhitepaperNew from '../../../Component/forms/ViewFullWhitepaperNew';

import Whitepaperback from '../../../Component/Whitepaperback';

export default function AmplifiedNeedForTechLed() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    const link = "https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/Decimal%20Point%20-%20WP%20-%20The%20many%20pathways%20along%20which%20insurers%20can%20harness%20data%20better_v1_for%20review.pdf"

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>The Amplified Need for Tech-Led Intelligence in Insurance</title>
                <meta name="description" content="Discover how AI, ML, and advanced tech empower insurers to enhance decision-making, improve customer service, and address data quality, cybersecurity, and regulatory challenges." />
                <meta name="keywords" content="insurance technology, data analytics, AI in insurance, ML in insurance, InsurTech, data-driven insights, customer experience in insurance symbiosis, innovative use cases, project risk management" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/whitepapers/the-amplified-need-for-tech-led-intelligence-in-insurance" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id='article-page' className='sec_bg'>
                <Container maxWidth="lg">

                    <Grid container spacing={2} justifyContent='center'>


                        <Grid item lg={8} md={7} sm={7} xs={12} className='grid-custom-12'>

                            <Box className='article section'>


                                <Grid container spacing={1} alignItems="top" justifyContent='center' >
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box>
                                            <Typography variant='h3' className='subhead skblue'>
                                                Whitepaper
                                            </Typography>
                                            <Typography variant='h1' className='blue mainhead' style={{ textTransform: 'none' }}>
                                                The Amplified Need for Tech-Led Intelligence in Insurance
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Box className="" pt={2}>
                                    <img src={Banner} className='w100' alt='Banner' />
                                </Box>

                                <Box className='whitebx' mt={1}>


                                    <Box mb={1}>
                                        <Grid container spacing={4} alignItems="flex-start">
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Box>

                                                    <Typography variant='h6' className='black jst'>
                                                        Insurers face unprecedented data challenges, from fragmented data sources to cybersecurity threats, and legacy systems that hinder effective decision-making. This makes a strong case to harness advanced technologies like Artificial Intelligence (AI), Machine Learning (ML), and Big Data Analytics and overcome these hurdles. Insurers can go on to create personalized insurance plans, improve risk assessment, and enhance customer experiences.
                                                    </Typography>

                                                    <Typography variant='h6' className='black jst' gutterBottom>
                                                        Our whitepaper, “The Amplified Need for Tech-Led Intelligence in Insurance,” explores how:
                                                        <ul>
                                                            <li>
                                                                <Typography variant='h6' className='black jst'>
                                                                    Data analytics can transform risk profiling and fraud detection
                                                                </Typography>
                                                            </li>
                                                            <li>
                                                                <Typography variant='h6' className='black jst'>
                                                                    The integration of AI can streamline claims processing and customer service
                                                                </Typography>
                                                            </li>
                                                        </ul>
                                                    </Typography>

                                                    <Typography variant='h6' className='black jst'>
                                                        Discover the benefits of insurance products tailored to individual needs and the real-world applications of data analytics in various areas of insurance
                                                    </Typography>

                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>


                            </Box>

                        </Grid>

                        <Grid item lg={4} md={5} sm={5} xs={12} className='grid-custom-12'>
                            <Box className='bg_websection' p={4}>
                                <Box className="web_section whitebx" >
                                    <Box mt={2} className='al_center'>
                                        <Typography variant="h4" className="black">
                                            Download the whitepaper
                                        </Typography>
                                    </Box>


                                    <Box className='graybbx2222' mt={2}>
                                        <ViewFullWhitepaperNew reportby={"The Amplified Need for Tech-Led Intelligence in Insurance"} link={link} />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={5} sm={5} xs={12} className='grid-custom-12'>
                            <Box mb={4}>
                                <Whitepaperback />
                            </Box>
                        </Grid>


                    </Grid>
                </Container>
            </Box>

        </>
    );
}
