import { Container, Grid, Typography, Box, Divider } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ESGDownload from '../../../../../../Component/forms/ESG_download';
import { Helmet } from 'react-helmet';
import Banner from '../../../../../../asset/images/insight/esg/esg_usa_banner.png';
import ESGWhitepaperBack from '../../../../../../Component/BackButtons/ESGWhitepaperBack';


export default function ESG_USA_NavigatingSustainability() {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    const link = "https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/dpa-esg-insights-usa-esg-review-nov-2023.pdf"


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Navigating Sustainability - USA’s ESG Landscape In 2023</title>
                <meta name="description" content="Explore the comprehensive USA ESG Review 2023 Whitepaper. Gain insights into climate change initiatives, responsible investing, and ESG integration." />
                <meta name="keywords" content="ESG Landscape, Sustainable Investing, Climate Responsibility, USA ESG Initiatives, Responsible Asset Management, Global Sustainability Goals, Corporate Decarbonization, ESG Transparency, Investment Trends, Climate Change Policies" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/esg-insights/esg-whitepaper/navigating-sustainability-usa-esg-landscape-in-2023" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center' mb={2}>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    ESG Insights
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center mb0'>
                                    Navigating Sustainability: USA’s ESG Landscape in 2023
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            {/* <Box className='al_center' mb={2}>
                <img src={Banner} alt='ESG Whitepaper banner' className='banner-head' />
              </Box> */}
                            <Box className="">
                                <Box
                                    className="banner-head"
                                    style={{
                                        backgroundImage: 'url(' + Banner + ')',
                                    }}
                                >
                                </Box>
                            </Box>
                        </Grid>


                    </Grid>


                    {/* <Writemessage3 pageVisited="  ESG Canada Review 2023 Whitepaper in ESG Insights" /> */}

                    <Box className='whitebx' mt={1}>


                        <Box mb={1}>
                            <Grid container spacing={4} alignItems="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box>

                                        <Typography variant='h6' className='black jst'>
                                            We are delighted to share the 'USA ESG Review 2023 Whitepaper', offering a detailed overview of the United States' role in the global ESG landscape, with particular emphasis on climate change and responsible investing. This edition provides insights into the USA’s approach to global emissions and sustainable practices, spotlighting the advancements of US asset management firms in ESG integration, while also acknowledging the challenges in the corporate sector's approach to decarbonization and the need for greater ESG transparency. The whitepaper discusses the evolving policies, regulations, and guidelines that support ESG initiatives in the USA, and how these are shaping ESG outcomes. It also presents the trends in ESG adoption among US asset managers and owners, demonstrating their commitment to improving ESG practices. In its concluding section, the whitepaper looks at the ESG processes and tools being used at the USA’s biggest asset owners like CalPERS, CalSTRS, The New York State Common Retirement Fund, The Harvard University endowment; and investment managers like J.P. Morgan Asset Management, Blackrock, Goldman Sachs Asset Management, State Street Global Advisors Capital Group, The Vanguard Group, Fidelity Investments, T. Rowe Price, PIMCO, AllianceBernstein, Neuberger Berman Group, Dimensional Fund Advisors to name some. This whitepaper serves as an informative guide for those seeking to engage with and understand the ESG investing landscape in the United States, offering valuable insights for making informed decisions that support sustainable development and align with global sustainability goals.
                                        </Typography>

                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Divider />

                        <Box mt={2} className='al_center'>
                            <Typography variant="h4" className="black">
                                Get Your Full Copy Now – Simply Fill Out the Form
                            </Typography>
                        </Box>


                        <Box className='graybbx2222' mt={2}>
                            <ESGDownload reportby={"Navigating Sustainability: USAs ESG Landscape in 2023"} link={link} />
                        </Box>


                    </Box>

                    <ESGWhitepaperBack />

                </Container>
            </Box>




        </>
    );
}
