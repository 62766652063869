import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Writemessage3 from '../../../Component/Writemessage3';

import Casestudyback from '../../../Component/Casestudyback'


import Fraud1 from "../../../asset/images/insight/case_studies/fraud1.jpg";



export default function Fraud_detection() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Fraud Detection & Analytics for Leading German Reinsurer</title>
                <meta name="description" content="Discover how DPA helped a German reinsurer with fraud detection analytics solutions. Read the case study now." />
                <meta name="keywords" content="business intelligence consulting,
Company research provider,
enterprise business intelligence,
fraud detection analytics,
fraud detection and prevention,
Lending solution provider" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/fraud-detection-and-analytics-for-a-leading-german-reinsurer" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            </Helmet>


            <Box id="case-studies" className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center mb0'>
                                    Fraud Detection & Analytics for a Leading German Reinsurer
                                </Typography>

                                <Typography className=' mainsmhead black al_center '>
                                    DPA provided end-to-end fraud analytics solutions to a reinsurance company, <br /> from data extraction & cleansing to forensic analysis of dubious cases. <br />  The ML-based analysis helped the company identify suspicious accounts to <br />  predict and prevent future delinquencies.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Writemessage3 pageVisited="Fraud Detection And Analytics for a Leading German Reinsurer" />



                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' >Client Overview</Typography>
                            <Typography variant='h6' className='black jst'>
                                A Germany-based reinsurance company partnered with DPA to seek risk management assistance concerning customer data. The world-leader company has offices in over 50 countries and covers the value chain of reinsurance, primary insurance, and insurance-related risk solutions. It offers primary insurance products, comprising property-casualty, legal expense, life, direct, health, and travel insurance services.
                            </Typography>
                        </Box>
                    </Box>


                    <Box className='whitebx' mt={1}>
                        <Box>
                            <Typography variant='h4' className='blue' >Introduction To Fidelity Pulse</Typography>
                            <Typography variant='h6' className='black jst'>
                                The client wished to assess any possibilities or loopholes that may lead to fraud by customers, via financial data analysis conducted by DPA. Fraud analytics is a part of DPA’s risk management and mitigation services that enables companies to predict and minimize any fraud probabilities by using accurate data insights. The process involves an ML model, Fidelity Pulse, that uses a well-structured method to identify and highlight data inconsistencies.
                            </Typography>
                            <Typography variant='h6' className='black jst'>
                                Fidelity Pulse is DPA’s proprietary product, a fraud detection tool, that uses statistical techniques to identify and expose suspected financial fraud. It is a data-led solution that results in accuracy and rules out risk possibilities.
                            </Typography>
                        </Box>
                    </Box>


                    <Box className='whitebx' mt={1}>
                        <Box>
                            <Typography variant='h4' className='blue' >Challenge For DPA</Typography>
                            <Typography variant='h6' className='black jst'>
                                The company provided us with the financial information of 244 customers, accumulated over several years. A different set of documents for each one was gathered in a disheveled form. These financial documents included ITR forms of some customers, other forms of income statements, cash flow statements, balance sheets, etc. in PDF format. The haphazard collection of documents was a severe challenge for the team to begin with.
                            </Typography>
                        </Box>
                    </Box>


                    <Box className='whitebx' mt={1}  >
                        <Grid container spacing={3} alignItems="top">
                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <Box className='al_center'>
                                    <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + Fraud1 + ')', }}></Box>
                                </Box>
                            </Grid>
                            <Grid item lg={8} md={8} sm={8} xs={12}>
                                <Box>

                                    {/* <Box mb={2}>
                                        <Link to='/analystpulse'>
                                            <Typography variant='h4' className='blue' >Know More About Our Product on Analytics – AnalystPulse </Typography>
                                        </Link>
                                    </Box> */}

                                    <Typography variant='h4' className='blue' >DPA’S ML Based Solution </Typography>

                                    <Typography variant='h6' className='black jst'>
                                        DPA applied an organized and well-tested approach to this enormous challenge. The team followed a detailed stepwise process to deliver the client's requirements efficiently.
                                    </Typography>

                                    <Typography variant='h6' className='black jst'>
                                        <ul>
                                            <li> <strong>Data Extraction & Management: </strong> The pool of documents was not organized. All the documents were provided haphazardly. Hence, our team started with data extraction, converting the data to excel. </li>
                                            <li> <strong> Quality Check: </strong>Once we achieved the desired level of uniformity in data, it became easier to comprehend and manage. We checked the data thoroughly to tackle any exceptions  </li>
                                            <li> <strong> Analysis with Fidelity Pulse:  </strong>We analyzed the financial data post-refinement with Fidelity Pulse. </li>

                                        </ul>
                                    </Typography>


                                    <Typography variant='h6' className='black jst'>
                                        DPA delivered a robust solution with the right analytics to solve the complexities.
                                    </Typography>

                                </Box>
                            </Grid>
                        </Grid>
                    </Box>


                    <Box className='whitebx' mt={1}>
                        <Box>
                            <Typography variant='h4' className='blue' >
                                Fidelity Pulse - New Age Data Analytics Tool</Typography>
                            <Typography variant='h6' className='black jst'>
                                Fidelity Pulse is the Machine Learning model that absorbs refined financial data to find inconsistencies & outliers. In the next step, it assigns a score to suggest the underlying risk of fraud. The score indicates the degree of risk.
                            </Typography>
                        </Box>
                    </Box>


                    <Box className='whitebx' mt={1}>
                        <Box>
                            <Typography variant='h4' className='blue' >How did Fidelity Pulse Help?</Typography>
                            <Typography variant='h6' className='black jst'>
                                It flagged suspicious accounts and accounts with insufficient data from the information provided by the client. The team started with a forensic analysis of results with clear and concise data. It also highlighted the missing documentation for easy identification of gaps in data. The output was segmented into different categories:
                            </Typography>
                            <Typography variant='h6' className='black jst'>
                                <ol>
                                    <li> Extremely suspicious accounts </li>
                                    <li> Relatively less suspicious accounts</li>
                                    <li> Accounts with inadequate data  </li>
                                    <li> Safe accounts  </li>
                                </ol>
                            </Typography>

                            <Typography variant='h6' className='black jst'>This segmentation was represented in a graphical presentation through different colors. The color scheme was used in a legible way to establish a quick understanding of accounts.   </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li> Red depicted highly suspicious accounts</li>
                                    <li> Yellow depicted customers who might have or would do fraudulent activities. These accounts were in the less-suspicious zone. </li>
                                    <li>Green depicted safe accounts  </li>
                                </ul>
                            </Typography>

                            <Typography variant='h6' className='black jst'>The graphical representation of data is the easiest to understand and details each data insight, presenting the financial analysis accurately.  </Typography>
                        </Box>
                    </Box>

                    <Box className='whitebx' mt={1}>
                        <Box>
                            <Typography variant='h4' className='blue' >Delivering Leading Edge Solutions For Clients</Typography>
                            <Typography variant='h6' className='black jst'>
                                The classification was cost and time-effective for our client, aiding them in identifying distrustful accounts & potentially fraudulent customers.
                            </Typography>
                            <Typography variant='h6' className='black jst'>
                                Through Fidelity Pulse, DPA employs fully automated processes from data ingestion to the computation of scores. The algorithm-backed technology eliminates any scope for human emotions to impact results. It is capable of detecting pre-planned misreporting of numbers and data tampering.
                            </Typography>
                            <Typography variant='h6' className='black jst'>
                                Fidelity Pulse is a solution-centric tool that systematically declutiers any data ambiguity and foregrounds coherent results. It breaks down the otherwise exhausting process of churning out scientific data insights and makes it convenient & time-efficient.
                            </Typography>
                            <Typography variant='h6' className='black jst'>
                                If you are searching for such clutier-breaking data analytics solutions,you can schedule your free consultation here.
                            </Typography>

                            <Link to='/contact-us' className='link'>
                                <Typography variant='h6' className='blue jst'>
                                    Let’s talk!
                                </Typography>
                            </Link>

                        </Box>
                    </Box>



                    <Box className='graybbx' mt={1} >
                        <Grid container spacing={1} alignItems="top" >
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <Box className='al_left'>
                                    <Typography variant='h4' className='black'>
                                        Explore More:
                                    </Typography>

                                    <Link to='/case-studies/process-and-cost-efficiency-through-structured-data-management-solutions' className='link'>
                                        <Typography variant='h6' className='black jst' style={{ marginBottom: '0px' }}>
                                            <b> Next Case Study: </b> Process & Cost Efficiency through DPA's Structured Data Management Solutions.

                                        </Typography>
                                    </Link>

                                    <Link to='/articles/solving-challenges-in-esg-investing' className='link'>
                                        <Typography variant='h6' className='black jst'>
                                            <b>Insights: </b> Solving Challenges in ESG Investing.
                                        </Typography>
                                    </Link>

                                </Box>
                            </Grid>
                        </Grid>

                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}


                    </Box>


                    <Casestudyback />




                </Container>
            </Box>


        </>
    );
}
