
import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from '../../../../../../Component/Writemessage3';

import Chart1 from "../../../../../../asset/images/insight/esg/emission_chart1.png";
import Chart2 from "../../../../../../asset/images/insight/esg/emission_chart2.png";
import Chart3 from "../../../../../../asset/images/insight/esg/emission_chart3.png";
import ESGBlogsBack from '../../../../../../Component/BackButtons/ESGBlogsBack';
import { Helmet } from 'react-helmet';
import EmployeeProfile from '../../../../../../Component/EmployeeProfile';
import AJ from '../../../../../../asset/images/team/ajay_jindal.png'


export default function Emission_performance() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>

      <Helmet>
        <title>Global Auto Industry's Emission Performance in 2021 | DPA </title>
        <meta name="description" content="Explore the emission performance of global auto manufacturers in 2021. Discover trends in emissions reduction and intensity-based comparisons." />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/esg-insights/esg-blogs/emission-performance-of-global-auto-a-mixed-bag-in-2021" />
      </Helmet>

      <Box className='section sec_bg' component='section'>

        <Container maxWidth='lg' className='bdr'>

          <Grid container spacing={3} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className='al_center' mb={2}>
                <Typography variant='h3' className='subhead skblue al_center'>
                  ESG Insights
                </Typography>
                <Typography variant='h1' className='mainhead blue al_center'>
                  Emission Performance of Global Auto a mixed bag in 2021
                </Typography>
              </Box>
            </Grid>
          </Grid>


          <Writemessage3 pageVisited="Emission Performance of Global Auto a mixed bag in 2021" />



          <Box className='whitebx' mt={1}  >

            <Box>

              <Typography variant='h4' className='blue'>
                Introduction
              </Typography>

              <Typography variant='h6' className='black jst'>
                While on an absolute contraction basis, the major automakers show reduction in emissions, on an intensity basis the trend is not consistent
              </Typography>

            </Box>


            <Box pt={1} pb={2}>

              <Typography variant='h6' className='black jst'>
                The Global automobile industry, one of the sectors in the spotlight with respect to emissions, is still showing a patchy trend in terms of emissions reduction.
              </Typography>



              <Typography variant='h6' className='black jst'>
                On an absolute contraction basis, the numbers do look good for all the manufacturers in the chart below (the charts below are not a comprehensive list of global makers; they also show only Scope 1 and Scope 2). The US firms, GM and Ford, show the sharpest drop, both showing a reduction of over 30% in 2021 over 2019.
              </Typography>


              <Box>
                <img src={Chart1} className="topbanner4" alt='charts' />
              </Box>


            </Box>



            {/*-------------------------------------------------------*/}

            <Box pt={1} pb={2}>
              <Typography variant='h4' className='blue jst'>
                Intensity numbers mixed
              </Typography>

              <Typography variant='h6' className='black jst'>
                However, when one turns the numbers into an intensity-based emissions comparison (see chart below) – based on vehicles produced – the trend changes. For some of the leading players, the intensity-based emission trend appears upward instead of downwards. This is because production numbers in 2021 remained below 2019 numbers for most automakers. GM and Ford show good progress on this parameter too, their reduction trajectory appears well within the requirement for their Net Zero goals.
              </Typography>

              <Box>
                <img src={Chart2} className="topbanner4" alt='charts' />
              </Box>


            </Box>




            {/*-------------------------------------------------------*/}


            <Box pt={1} pb={2}>
              <Typography variant='h4' className='blue jst'>
                Tesla finally reveals
              </Typography>

              <Typography variant='h6' className='black jst'> In the chart below, we compare the producers with Tesla on Scope 1+2 emission intensity for 2021.
              </Typography>

              <Typography variant='h6' className='black jst'> Tesla shows better performance compared to some of the larger Internal Combustion Engine (ICE) based car makers, but not so compared to two of the Asian manufacturers: Hyundai and Maruti Suzuki. They have the least intensity numbers for Scope 1 and Scope 2. Both could be benefiting from the larger share of small cars in their portfolio.
              </Typography>


              <Typography variant='h6' className='black jst'> Tesla’s lower numbers compared to a GM or VW could be a reflection of its comparatively newer plants. Any new plant will generally have better energy efficiency and emission characteristics compared to an older plant. It appears Tesla has revealed emission data for the first time in 2021. Its 2020 sustainability report – Tesla calls it ‘Impact Report’ – has this line: At the moment, the manufacturing process of a Model 3 results in slightly higher GHG emissions than an equivalent combustion engine vehicle.   </Typography>


              <Box>
                <img src={Chart3} className="topbanner4" alt='charts' />
              </Box>

              <Typography variant='h6' className='black jst'>
                While the current progress may not be clearly positive, all car makers are making concerted efforts towards Net Zero targets via twin routes: increasing use of renewable energy; and increasing their portfolio of EVs.
              </Typography>

              <Typography variant='h6' className='black jst'>
                Building a renewable portfolio may be the way to go for all automakers. In terms of its overall carbon footprint, Tesla has benefited from its 2016 acquisition of SolarCity (now called Tesla Energy). Till the end of 2021, Tesla Energy had cumulatively generated over 25.37 Terawatt-hours (TWhs), which Tesla claimed is more than the total energy Tesla has used to run all its factories since it began producing Model S in 2012 and electricity used to power all of its vehicles in that same period combined. Tesla claims this number – total energy used at factories and for recharging all its vehicles produced since inception – is 25.27 TWh. It is interesting how close this number is to Tesla Energy’s generation.
              </Typography>




            </Box>
            {/*-------------------------------------------------------*/}




            {/* <Box pt={1}>
              <Typography variant='h4' className='black jst'>
                <span className='blue'> Ajay Jindal, </span>  CFA
              </Typography>
              <Typography variant='h6' className='black jst'>
                ESG Lead, Algo Circle
              </Typography>
            </Box> */}


            {/*-------------------------------------------------------*/}
            <Box mt={1}>
              <EmployeeProfile imgSrc={AJ} name={" Mr. Ajay Jindal"} designation={"EVP,"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/ajay-jindal-cfa-33aa961/"} />
            </Box>
            {/*-------------------------------------------------------*/}




          </Box>
          {/*-------------------------------------------------------*/}



          <Box className='graybbx' mt={1} >
            <Grid container spacing={1} alignItems="top" >
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Box className='al_left'>

                  <Typography variant='h6' className='black jst'>
                    <b>Category:</b>  Environment
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    <b>Tags:</b> Volkswagen, Toyota, GM, Ford, Tesla, Hyundai, Maruti Suzuki, GHG Emissions, Scope 1, Scope 2, Global Auto, absolute contraction basis, emission intensity
                  </Typography>

                </Box>
              </Grid>
            </Grid>
          </Box>



          <ESGBlogsBack />




        </Container>
      </Box>


    </>
  );
}

