import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Writemessage3 from '../../../Component/Writemessage3';
import Blogback from '../../../Component/Blogback'
import fallacy1 from "../../../asset/images/insight/blog_pages/the_fallacy.jpg";
import { Helmet } from 'react-helmet';


export default function The_fallacy_of_paradox() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>The Fallacy Of The Repugnant Paradox </h1>
                <title>The Fallacy of The Repugnant Paradox | Decimal Point Analytics</title>
                <meta name="description" content="Explore the fallacy of the Repugnant Paradox and its implications for population welfare. Discover the potential for technology to increase happiness and support." />
                <meta name="keywords" content="Repugnant Paradox, philosophical topic, population welfare, quality of life, happiness, sorrow, cumulative happiness, cumulative sorrow, bottom-up welfare, mathematical proof, advanced technology, net happiness, infinite happy population" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/the-fallacy-of-paradox" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                The Fallacy Of The Repugnant Paradox
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                July 10, 2017
                            </Typography>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>Recently, I became aware of a philosophical topic “The Repugnant Paradox” via an excellent YouTube Video <a href=' http://bit.ly/2tWEgf5' target='_blank' rel="noopener noreferrer">  http://bit.ly/2tWEgf5 </a>. A more technical description of the paradox can be found at <a href='http://stanford.io/2tAbE9a' target='_blank' rel="noopener noreferrer"> http://stanford.io/2tAbE9a </a>. In short, the Paradox states that “For any possible population of at least ten billion people, all with a very high quality of life, there must be some much larger imaginable population whose existence, if other things are equal, would be better even though its members have lives that are barely worth living.”</Typography>

                        <Box className='al_left' mt={4} mb={4}>
                            <img src={fallacy1} className='topbanner2' alt='Fallacy1' />
                        </Box>

                        <Typography variant='h6' className='black jst'>where “N” is the count of individuals in the universe.</Typography>

                        <Typography variant='h6' className='black jst'>Also, it is natural to assume that a person is either happy or sorrow at a particular moment and the cumulative happiness or sorrow respectively of an individual is the count of happy or sorrow moments in her life till this moment respectively, as measured by net sum of the two.</Typography>

                        < Typography variant='h6' className='black jst'>When one systematically defines the welfare of the world from bottom up in this way, the Repugnant Paradox vanishes pretty soon. The moment the number of individuals who have more moments of sorrow than of happiness; exceeds the number of individuals who are net happy, the welfare of the universe turns negative.</ Typography>

                        <Typography variant='h6' className='black jst'>I can give a mathematical proof the concept but it is not relevant here. You, the smart reader, can work it out yourself, from the objective function.</Typography>

                        <Typography variant='h6' className='black jst'>However, let me end by saying that as technology becomes more advanced, the individuals start having greater access to tools to increase their net happiness at individual level, the universe can support bigger populations. Given that I am basically a cornucopian, I think the world can eventually support infinite happy population.</Typography>




                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}



                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="The Fallacy Of The Repugnant Paradox" />
                    </Box>


                    <Blogback />

                </Container>
            </Box>

        </>
    );
}
