import CANADA from '../../../../../asset/images/insight/podcast/Exploring_Canadas_ESG_Landscape.png';
import CRTA from '../../../../../asset/images/insight/podcast/esg_canada_podcast_for_crta.png';
import UnlockingSuccess2024 from '../../../../../asset/images/insight/podcast/unlocking_success_2024.png';


const ESG_Podcasts_data = [
    {
        type: "external",
        link: "https://www.youtube.com/watch?v=CtZGDkqROCY&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjI",
        imgsrc: UnlockingSuccess2024,
        heading: "Unlocking Success: 2024's Top Five ESG Trends for Asset Managers",
    },
    // {
    //     type: "external",
    //     link: "https://www.youtube.com/watch?v=L_W3oLcUQw8",
    //     imgsrc: SmallBusiness24,
    //     heading: "Navigating Challenges for Small Businesses in 2024: Rising Interest Rates in US",
    // },
    {
        type: "external",
        link: "https://open.spotify.com/episode/3hBMTuRrENqKYBeYz3pcnG?si=PPONZwUiSie6Futo1XsBIg&nd=1",
        imgsrc: CRTA,
        heading: "Ajay Jindal in Conversation with CRTA on Canada ESG Review 2023",
    },
    {
        type: "external",
        link: "https://www.youtube.com/watch?v=wsSgZ22V-Tw",
        imgsrc: CANADA,
        heading: "Exploring Canada’s ESG Landscape: Insights from DPA’s ESG Review",
    },
];

export default ESG_Podcasts_data;   