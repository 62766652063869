import React, { useEffect, } from "react";

  import {
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Container,
  Grid,
  Typography,
  Box,
  MenuItem,
  Select,
  Checkbox
} from "@material-ui/core";
import PropTypes from "prop-types";
import Aos from "aos";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import flags from 'react-phone-number-input/flags';
import {contactApi} from "./api/api";
import CustomePhoneNumber from "./CustomePhoneNumber_Not_Mandatory";

import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../Style.css";
import "react-toastify/dist/ReactToastify.css";
import 'react-phone-number-input/style.css'; 


const usePlaceholderStyles = makeStyles(theme => ({
  placeholder: {
    color: "#aaa"
  }
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


export default function Get_in_touch() {


  const [value, setValue] = React.useState("Business Inquiry");
  const [phoneValueError, setPhoneValueError] = React.useState();
  const [phoneValue1, setPhoneValue1] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const nameRegexExp = /^[A-Za-z\s]+$/;
  const indiaPhoneRegex = /^[6-9]\d{9}$/;

  const emailreg =
    /^[a-zA-Z0-9_]+(?:[-._][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+(?:[-.][a-zA-Z0-9_]+)*\.[a-zA-Z]{2,}$/;
  const organizationRegex = /^[a-zA-Z0-9 ,-./!@#$%^&*~]*$/;
  const jobtitleRegex = /^[a-zA-Z0-9 ,-./!@#$%^&*~]+$/;
  const schema = yup.object({
    firstname: yup
      .string()
      .required("First name is required")
      .typeError("Required feild")
      .matches(nameRegexExp, "Enter valid first name"),
    lastname: yup
      .string()
      .required("Last name is required")
      .typeError("Required feild")
      .matches(nameRegexExp, "Enter valid last name"),
    organisation: yup
      .string()
      .required("Organization is required")
      .matches(organizationRegex, "Enter valid organization name")
      .typeError("Required feild")
    ,
    jobtitle: yup
      .string()
      .required("Job title is required")
      .typeError("Required feild")
      .matches(jobtitleRegex, "Enter valid job title")
    ,
    email: yup
      .string()
      .required("Email is required")
      .typeError("Required feild")
      .matches(emailreg, "Email is not valid")
    ,
    hear_aboutus: yup
      .string()
      .typeError("Required feild")
      .required("Required field"),
    tandc: yup.bool(),
    tandce: yup.bool().oneOf([true], "You must accept the terms and conditions")
  });

  const formOptions = {
    mode: "onBlur",
    resolver: yupResolver(schema),
  };

  const { register, control, formState, handleSubmit } = useForm(formOptions);
  const { errors } = formState;
  const [setErrorReason] = React.useState("");
  const history = useHistory();
  const firstName = React.useRef();
  const lastName = React.useRef();
  const email = React.useRef();
  const organisation = React.useRef();
  const jobtitle = React.useRef();
  const message = React.useRef();
  const [hearAboutUs, setHearaboutUS] = React.useState();



  React.useEffect(() => {
    const fetchIfaEmployees = () => {

      const hearabout = ["Search Engine",
        "Google",
        "Facebook",
        "Youtube",
        "Other paid social media advertising",
        "Facebook post/group",
        "Twitter post",
        "Instagram post/story",
        "Other social media",
        "Email",
        "Radio",
        "TV",
        "Newspaper",
        "Word of mouth",
        "Other"];
      const menuItem = hearabout.map((d) => {
        return (
          <MenuItem key={d} value={d}>
            <>{d}</>
          </MenuItem>
        );
      });
      setHearaboutUS(menuItem);

    };


    fetchIfaEmployees();
  }, []);

  const isPhoneValid = () => {
    if (!phoneValue1) {
      setPhoneValueError("");
      return;
    }

    var phoneValue2 = phoneValue1.includes("+91") ? phoneValue1.substring(3) : phoneValue1;
    if (phoneValue2.trim() === "") {
      setPhoneValueError("Enter phone number");
      return;
    }

    if (isValidPhoneNumber(phoneValue1)) {
      if (phoneValue1.includes("+91")) {
        if (!indiaPhoneRegex.test(phoneValue2)) {
          setPhoneValueError("Invalid phone number");
          return;  
        }
      }
      setPhoneValue1(phoneValue1);
      setPhoneValueError("");
    } else {
      setPhoneValueError("Invalid phone number");
    }
  };

  const onSubmit = (data) => {

    if (phoneValue1) {
      if (isValidPhoneNumber(phoneValue1)) {
        if (phoneValue1.includes("+91")) {
          var phoneValue2 = parseInt(phoneValue1.substring(3));
          if (!indiaPhoneRegex.test(phoneValue2)) {
            setPhoneValueError("Invalid phone number"); 
            return;
          }
        }
        setPhoneValue1(phoneValue1);
        setPhoneValueError(""); 
      } else {
        setPhoneValueError("Invalid phone number"); 
        return;
      }
    }

    let tac = false;
    if (data.tandc) {
      tac = true
    }
    else {
      tac = false
    }

    const formData = {
      first_name: data.firstname,
      last_name: data.lastname,
      contact_no: phoneValue1,
      message: message.current.value,
      email: data.email,
      job_title: data.jobtitle,
      reason: value,
      organization: data.organisation,
      hear_aboutus: data.hear_aboutus,
      obtain_email_flag: tac
    };

    if (value === "") {
      setErrorReason("Please select reason");
      return;
    } 
    if (!(data.tandce)) {

      return
    }
    handleFormSubmit(formData);
  };

  const post = async (formData) => {
    setLoading(true);
    const message = "Oops, something went wrong, Please try again leter.";
    try {
      const { data, status } = await contactApi(formData);

      if (status === 200) {
        if (data.status === true) {


          setValue("");
          history.push("/form-submit");
        } else {
          toast.error(message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });


        }
      }
    } catch (error) {
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    }
    setLoading(false);
  };



  const handleFormSubmit = (data) => {
    post(data);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };




  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>


      <Box id="contact_form" className="section section_mob" component="section" >
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="al_center">
                <Typography variant="h2" className="black">
                  Get in touch with us
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box className="whitebx" mt={2} mb={2}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box className="bluebg">
                  <Box mb={1}>
                    <Typography variant="h4" className="white jst">
                      Select Reason *
                    </Typography>
                  </Box>
                  <Box className="reason al_left">
                    <FormControl component="fieldset">
                      <RadioGroup

                        aria-label="reason"
                        name="reason1"
                        value={value}
                        defaultValue="Business Inquiry"
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="Business Inquiry"
                          control={<Radio />}
                          label="Business Inquiry"
                          color="primary"

                        />
                        <FormControlLabel
                          value="Alliances & Partnerships"
                          control={<Radio />}
                          label="Alliances & Partnerships"
                        />
                        <FormControlLabel
                          value="Analysts & Media Relationships"
                          control={<Radio />}
                          label="Analysts & Media Relationships"
                        />
                        <FormControlLabel
                          value="Career Enquiry"
                          control={<Radio />}
                          label="Career Enquiry"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="alltxfieldlg ">
                        <TextField
                          id="filled-basic1"
                          label="First Name"
                          variant="standard"
                          fullWidth
                          name="firstname"
                          inputRef={firstName}
                          required
                          {...register("firstname", { required: true })}
                          error={!!errors?.firstname}
                          helperText={errors?.firstname?.message}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="alltxfieldlg ">
                        <TextField
                          id="filled-basic2"
                          label="Last Name"
                          variant="standard"
                          fullWidth
                          name="lastname"
                          inputRef={lastName}
                          required
                          {...register("lastname", { required: true })}
                          error={!!errors?.lastname}
                          helperText={errors?.lastname?.message}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="alltxfieldlg ">
                        <TextField
                          id="filled-basic3"
                          label="Organization Name"
                          variant="standard"
                          fullWidth
                          name="Organization Name"
                          inputRef={organisation}
                          required
                          {...register("organisation", { required: true })}
                          error={!!errors?.organisation}
                          helperText={errors?.organisation?.message}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="alltxfieldlg ">
                        <TextField
                          id="filled-basic4"
                          label="Job Title"
                          variant="standard"
                          fullWidth
                          name="jobtitle"
                          inputRef={jobtitle}
                          required
                          {...register("jobtitle", { required: true })}
                          error={!!errors?.jobtitle}
                          helperText={errors?.jobtitle?.message}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="alltxfieldlg " >
                        <TextField
                          id="filled-basic5"
                          label="Email Address"
                          variant="standard"
                          fullWidth
                          name="email"
                          inputRef={email}
                          required
                          {...register("email", {
                            required: true,
                            pattern:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          })}
                          error={!!errors?.email}
                          helperText={errors?.email?.message}
                        />
                      </Box>
                    </Grid>


                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="alltxfieldlg " mt={2}>

                        <PhoneInput
                          flags={flags}
                          international
                          name="phoneInput"
                          placeholder='Enter phone number' 
                          value={phoneValue1}
                          onChange={setPhoneValue1}
                          inputComponent={CustomePhoneNumber}
                        />

                      </Box>
                      <Typography style={{ color: "red", fontSize: "13px" }}>{phoneValueError}</Typography>
                    </Grid>


                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="alltxfieldlg " mt={2}>
                        <FormControl variant="standard" fullWidth>
                          <Controller
                            control={control}
                            name="hear_aboutus"
                            defaultValue={""}
                            label="user role"
                            required
                            placeholder="Hear Aount us?"
                            render={({ field, field: { value } }) => (
                              <>
                                <Select
                                  {...field}
                                  displayEmpty
                                  error={!!errors?.hear_aboutus}
                                  placeholder="Select Role"
                                  renderValue={value !== "" ? undefined : () => <Placeholder>How did you hear about us? *</Placeholder>}
                                >
                                  <MenuItem value="" selected></MenuItem>
                                  {hearAboutUs}
                                </Select>
                                <Typography style={{ color: "red", fontSize: "13px" }}>{errors?.hear_aboutus?.message}</Typography>


                              </>
                            )}
                          />
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="alltxfieldlg ">
                        <TextField
                          id="filled-basic6"
                          label="Your Message"
                          variant="standard"
                          fullWidth
                          inputRef={message}
                          multiline
                          rows={4}
                        />
                      </Box>
                    </Grid>


                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="alltxfieldlg chkbx" >
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={true}
                              name="checkedB"
                              color="primary"
                              size="small"
                              {...register("tandc")}
                              error={!!errors?.tandc}

                            />
                          }
                          label="Subscribe to our Newsletter"
                        />
                      </Box>

                      <Box className="alltxfieldlg chkbx" >

                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={true}
                              name="checkedB"
                              color="primary"
                              size="small"
                              {...register("tandce")}
                              error={!!errors?.tandce}

                            />
                          }
                          label={<Typography className="label2">By clicking submit, I agree to Decimal Point Analytics <Link to="/terms">Terms of Use</Link> and <Link to="/policy">Privacy Policy</Link>
                          </Typography>
                          }
                        />


                      </Box>
                      <Box className="checkbx-error">
                        <Typography variant="body2" style={{ color: "red", fontSize: "13px", fontWeight: "500" }}>
                          {errors?.tandce?.message}
                        </Typography>
                      </Box>
                    </Grid>


                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="al_center" mt={3}>
                        {loading ? (
                          <LoadingButton
                            loading={loading}
                            variant="outlined"
                            disabled
                          >
                            disabled
                          </LoadingButton>
                        ) : (
                          <Button
                            onClick={() => { handleSubmit(onSubmit)(); isPhoneValid() }}
                            variant="contained"
                            className="dpabtn_1"
                          >
                            Submit
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>

        </Container>
      </Box>
    </>
  );
}
