
import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from '../../../../../../Component/Writemessage3';
import EmployeeProfile from '../../../../../../Component/EmployeeProfile';
import AJ from '../../../../../../asset/images/team/ajay_jindal.png'
import ESGBlogsBack from '../../../../../../Component/BackButtons/ESGBlogsBack';
import { Helmet } from 'react-helmet';


export default function Implementation_commitments() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>

      <Helmet>
        <title>COP27 Focus - Implementation Commitments and Loss & Damage Discussions </title>
        <meta name="description" content="Stay updated on COP27 discussions focusing on implementation commitments and Loss and Damage issues. Explore insights at Decimal Point Analytics." />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/esg-insights/esg-blogs/implementation-commitments-loss-and-damage-to-be-the-focus-at-cop27" />
      </Helmet>

      <Box className='section sec_bg' component='section'>

        <Container maxWidth='lg' className='bdr'>

          <Grid container spacing={3} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className='al_center' mb={2}>
                <Typography variant='h3' className='subhead skblue al_center'>
                  ESG Insights
                </Typography>
                <Typography variant='h1' className='mainhead blue al_center'>
                  Implementation commitments, Loss and Damage to be the focus at COP27
                </Typography>
              </Box>
            </Grid>
          </Grid>


          <Writemessage3 pageVisited="Implementation commitments, Loss and Damage to be the focus at COP27" />



          <Box className='whitebx' mt={1}  >

            <Box>

              <Typography variant='h4' className='blue'>
                Introduction
              </Typography>

              <Typography variant='h6' className='black jst'>
                The COP27 has started in Egypt. Poor implementation commitments and Loss and Damage discussions will be keenly followed
              </Typography>

            </Box>


            <Box pt={1} pb={2}>

              <Typography variant='h6' className='black jst'>
                The main agenda item for COP27 is implementation, listed clearly as the first item in its target list. Says the COP27 website: Shifting from pledging to implementation at scale and on time, based on the agreed work streams in Paris and the ambition reflected until and during Glasgow, it is time to accelerate, scale up, replicate success stories and deliver through the right mechanisms.
              </Typography>



              <Typography variant='h6' className='black jst'>
                At COP26, held last year at Glasgow, countries agreed to deliver stronger commitments this year, including updated national plans with more ambitious targets. However, only 24 countries have submitted their plans to the UN so far. Implementation has been a weak link, as the UN Environment Programme's (UNEP) annual Emissions Gap report released the last week pointed out. The current Nationally Determined Contributions (NDCs) are estimated to reduce global emissions in 2030 by 5 and 10 per cent respectively, says the UNEP report, while to limit global warming to 2°C and 1.5°C, these percentages must reach 30 per cent and 45 per cent respectively.
              </Typography>


              <Typography variant='h6' className='black jst'>
                The other agenda item is discussion around Financing. Within this, the COP27 this year is debating a contentious issue: Loss and Damage. In fact, the start yesterday was delayed as delegates took time in debating around the issue of loss and damage, and whether it should be on the agenda. This is the first-time loss and damage appears on any COP agenda.
              </Typography>


            </Box>



            {/*-------------------------------------------------------*/}

            <Box pt={1} pb={2}>
              <Typography variant='h4' className='blue jst'>
                What is loss and damage?
              </Typography>

              <Typography variant='h6' className='black jst'>
                It refers to guaranteed compensation from developed countries to developing countries, which are facing the brunt of climate change led natural disasters. Developing countries says those historically responsible for climate change should pay some damages. However, developed countries are not fully on board with this demand.
              </Typography>


              <Typography variant='h6' className='black jst'> The concept of loss and damage was first introduced by Alliance of Small Island States at international climate negotiations in Geneva in 1991 with the proposal of an insurance scheme against rising sea levels with costs to be covered by industrialized countries. But it was not seriously considered again until 2013 at the COP 19 climate conference in Warsaw, Poland.  </Typography>

              <Typography variant='h6' className='black jst'> The Warsaw International Mechanism for Loss and Damage was created with the aim of enhancing knowledge of the issue and finding ways to approach it. There has been little movement since then. At last year's UN climate conference in the Scottish city of Glasgow, negotiators rejected a proposal made by members of the G77 group of over a hundred developing countries and China for a formal loss and damage financial facility. The big fear developed countries have is that if they open up that space of acknowledging the need for additional finance, for loss and damage, it will open them up to liability and compensation claims, which could be financially prohibitive. </Typography>


            </Box>




            {/*-------------------------------------------------------*/}


            <Box pt={1} pb={2}>
              <Typography variant='h4' className='blue jst'>
                What is COP?
              </Typography>

              <Typography variant='h6' className='black jst'> ‘COP’ is short for ‘Conference of the Parties’ to the UN Framework Convention on Climate Change. In 1992, the UN organised the Earth Summit in Rio de Janeiro, Brazil, in which the UN Framework Convention on Climate Change (UNFCCC) was adopted and its coordinating agency, the UN Climate Change secretariat, was created.
              </Typography>

              <Typography variant='h6' className='black jst'> In this treaty, nations agreed to "stabilize greenhouse gas concentrations in the atmosphere to prevent dangerous interference from human activity on the climate system”. So far, 197 different parties have signed it.
              </Typography>


              <Typography variant='h6' className='black jst'> Since 1994, when the treaty entered into force, every year the UN has been bringing together almost every country on earth for global climate summits or “COPs”, which stands for ‘Conference of the Parties’.  </Typography>


              <Typography variant='h6' className='black jst'>
                The 2022 edition of this annual event started in Egypt yesterday. The importance of the event can be gauged from its attendance: the two-week summit will see participation from 196 countries, 45,000 people and 120 world leaders.
              </Typography>

              <Typography variant='h6' className='black jst'>
                During these meetings, nations have negotiated various extensions of the original treaty to establish legally binding limits on emissions, for example, the Kyoto Protocol in 1997 and the Paris Agreement adopted in 2015, in which all countries of the world agreed to step up efforts to try and limit global warming to 1.5°C above pre-industrial temperatures, and boost climate action financing.
              </Typography>




            </Box>
            {/*-------------------------------------------------------*/}




            {/*-------------------------------------------------------*/}
            <Box mt={1}>
              <EmployeeProfile imgSrc={AJ} name={" Mr. Ajay Jindal"} designation={"EVP,"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/ajay-jindal-cfa-33aa961/"} />
            </Box>
            {/*-------------------------------------------------------*/}





          </Box>
          {/*-------------------------------------------------------*/}



          <Box className='graybbx' mt={1} >
            <Grid container spacing={1} alignItems="top" >
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Box className='al_left'>

                  <Typography variant='h6' className='black jst'>
                    <b>Category:</b>  Environment
                  </Typography>


                  <Typography variant='h6' className='black jst'>
                    <b>Tags:</b> Conference of the Parties, COP27, COP26, Earth Summit, UN Framework Convention on Climate Change (UNFCCC), loss and damage, Nationally Determined Contributions (NDCs)
                  </Typography>

                </Box>
              </Grid>
            </Grid>
          </Box>



          <ESGBlogsBack />




        </Container>
      </Box>


    </>
  );
}

