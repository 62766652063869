  import React, { useEffect } from "react";

import {
    Container,
    Grid,
    Typography,
    Box,
    Button, 
  } from "@material-ui/core";
  import Aos from "aos";
  import { Link } from "react-router-dom";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import OutsourceSBABanner from '../asset/images/outsourcesba/outsourcesba_banner.jpg'
  import Logo from "../asset/images/outsourcesba/dpa-white-logo.png"

  import "aos/dist/aos.css";
  
  export default function Oracle_Form_submit({ msg = "Thank you for your submission!" }) {
  
  
    useEffect(() => {
      Aos.init({ duration: 1000 });
    }, []); 
  
    return (
      <>
  
        <Box className="sbasubmit-banner" component="section" style={{ backgroundImage: "url(" + OutsourceSBABanner + ")" }} pb={2}>
          <Box>
            <Container maxWidth="lg">
              <Grid container spacing={1} alignItems="center">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box>
                    <img src={Logo} alt="DPA Logo" className="sba_dpa_logo" />
                  </Box>
                </Grid>
  
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box id="submit-message" mb={6} mt={4}>
                    <Typography variant="h2" className="white al_center">
                      {" "}
                      {msg}
                      {" "}
                    </Typography>
                    <Typography variant="h4" className="white al_center">
                      {" "}
                      We appreciate your interest and will get back to you soon.
                      {" "}
                    </Typography>
                    <Typography variant="h4" className="white al_center">
                      {" "}
                      We value your time and effort. Our team will review your information shortly.
                      {" "}
                    </Typography>
                  </Box>
                </Grid>
  
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className="al_center" mt={"50px"}>
                    <Link to="/oracle">
                      <Button
                        variant="contained"
                        className="dpabtn_2"
                        startIcon={<ArrowBackIcon />} 
                      >
                        Back to Home
                      </Button>
                    </Link>
                  </Box>
                </Grid>
              </Grid>
  
            </Container>
          </Box>
        </Box>
  
      </>
    );
  }
  