import { Container, Grid, Typography, Box, Tabs, Tab, AppBar } from "@material-ui/core";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet } from 'react-helmet';
import ESG_Blogs_data from "./Blogs/ESG_Blogs_data";
import Design from "./Blogs/ESG_Blogs_design";



export default function ESG_Blogs() {


    const [value, setValue] = React.useState(3);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>


            <Helmet>
                <meta charset="UTF-8" />
                <title>Get daily ESG insights with Decimal Point Analytics</title>
                <meta name="description" content="Expert ESG insights for financial institutions and businesses from Decimal Point Analytics one of the leading ESG service provider in US" />
                <meta name="keywords" content="market research solutions
Research company
Sector research provider" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/esg-insights/esg-blogs" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>




            <Box className="maintabbedsection stiky linkedtabs">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <AppBar position="static" className="tabbedbar">
                                <Tabs value={value} onChange={handleChange} variant="scrollable">
                                    <Tab
                                        label="Whitepaper"
                                        component={Link}
                                        to="/esg-insights/esg-whitepaper"
                                        value={0}
                                    />
                                    <Tab
                                        label="Newsletter"
                                        component={Link}
                                        to="/esg-insights/esg-newsletter"
                                        value={1}
                                    />
                                    <Tab
                                        label="Podcast"
                                        component={Link}
                                        to="/esg-insights/esg-podcast"
                                        value={2}
                                    />
                                    <Tab
                                        label="Blogs"
                                        component={Link}
                                        to="/esg-insights/esg-blogs"
                                        value={3}
                                    />
                                </Tabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                </Container>
            </Box>



            <Box className="section sec_bg section_mob" component="section" style={{ paddingTop: "3%" }} >
                <Container maxWidth="lg" className="bdr">

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center" mt={5}>
                                <Typography variant="h1" className="black newh1">
                                    ESG Blogs
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className="reportinfo">

                        <Grid container spacing={1} alignItems="top">


                            {ESG_Blogs_data.map((item, index) => (
                                <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                                    <Design
                                        type={item.type}
                                        link={item.link}
                                        imgsrc={item.imgsrc}
                                        heading={item.heading}
                                    />
                                </Grid>
                            ))}

                        </Grid>
                    </Box>

                </Container>
            </Box>
        </>
    );
}
