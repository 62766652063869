import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Business from '../../../asset/images/media/business_standard.jpg';
import Economic from '../../../asset/images/media/eco_time.png';
import thePrint from '../../../asset/images/media/the_print.png';
import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';


export default function FintechEntity() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    return (
        <>

            <Helmet>
                <title>DPA SmartCloud - India’s First 'Fintech Entity' Authorized by IFSCA</title>
                <meta name="description" content="DPA SmartCloud Becomes India’s first ever organisation to be authorized as a ‘Fintech Entity’ By the International Financial Services Centres Authority" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/fintechentity" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    DPA SmartCloud becomes India’s first ever organisation to be authorized as a ‘Fintech Entity’ by the International Financial Services Centres Authority (IFSCA)
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>



                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst'>
                                        <ul>
                                            <li>Becomes India’s first ever fintech to receive authorization from the IFSCA </li>
                                            <li>DPA SmartCloud to launch Cloud-based solutions and services for AI-ML driven Data Analytics, Credit Scoring, and Data Management for the BFSI sector addressing the huge US$195 billion opportunity in the SaaS market space. </li>

                                        </ul>
                                    </Typography>

                                    <Typography variant='h6' className='black jst'>
                                        Mumbai (Dec 20,2022): DPA SmartCloud IFSC Private Limited, a wholly owned subsidiary of Decimal Point Analytics (DPA), has become India’s first ever fintech start-up to be authorised as a ‘Fintech Entity’ by the International Financial Services Centres Authority (IFSCA), the omnibus regulator of the financial SEZ at Gift City, Gujarat.
                                    </Typography>

                                    <Typography variant='h6' className='black jst'>
                                        The authorization will enable DPA SmartCloud to offer AI-ML driven solutions, products, and end-to-end data management services on the Cloud. DPA SmartCloud will serve a wide range of financial services firms globally and provide them access to better quality services at a lower cost, along with benefits that come as part of a Cloud solution.
                                    </Typography>

                                    <Typography variant='h6' className='black jst'>
                                        Speaking on the development Mr. Shailesh Dhuri, CEO of Decimal Point Analytics, said, “With this authorization, we are all set to launch an extensive bouquet of AI-ML driven products and services over the Cloud that match the requirements of our BFSI client base across the USA and Europe. The offerings from DPA SmartCloud will help us to expand into newer markets and cater to a diverse client-base, who in-turn, will benefit by leveraging our AI-ML driven solutions, all housed under one roof, to make strategic business decisions.”
                                    </Typography>

                                    <Typography variant='h6' className='black jst'>
                                        DPA SmartCloud would be an integrated Software as a Service (SaaS) platform, which would host several AI-ML based data management and analytics solutions and products over the Cloud. AI, ML and Cloud have been the buzzwords going around for a few years now and organizations across the world are exploring ways to implement such solutions to gain even the slightest competitive advantage. Some of the most common challenges faced by BFSIs and particularly Asset Managers are managing large amount of data, ingesting and integrating data from several sources, cleaning and validating the data, structuring of unstructured data to generate insights and training and deploying AI models to ensure accurate results. DPA SmartCloud would provide Asset Managers access to a comprehensive data management platform with automated data ingestion, data standardization, advanced quality check measures, and more. The cost and implementation time dimension of AI platforms are constant worry for asset managers looking for alpha edge.
                                    </Typography>

                                    <Typography variant='h6' className='black jst'>
                                        Mr. Dhuri further added, “Financial services firms require fast, reliable, and cost-effective data analytics products and data management services to convert data to business value. Cloud-based AI-ML solutions are the need of the hour to address the needs of these firms. However, developing these solutions is a costly affair due to high infrastructure setup costs along with the availability of talented and experienced personnel needed to develop them. DPA SmartCloud would allow financial services firms to implement customized AI-ML-based solutions based on their specific requirements at a fraction of the cost and time needed to set up an inhouse system. This, we believe, is a win-win for all stakeholders.”
                                    </Typography>

                                    <Typography variant='h6' className='black jst'>
                                        Mr. Dhuri further added, “The Gujarat International Finance Tec-City (GIFT City) is India’s first International Financial Services Centre (IFSC) and IFSCA is the unified regulator tasked with regulating financial products as well as financial institutions in GIFT City. IFSC will be the country’s portal to the world catering to institutions and individuals outside the Indian jurisdiction, dealing with finance flows, financial products, and services across borders. The vision encompassed in IFSC is to play a pivotal role in international finance across the globe, and Decimal Point Analytics believes that DPA Smart Cloud will be one of the key enablers to give the technological edge to the IFSC.”
                                    </Typography>



                                </Box>

                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Grid container spacing={0} alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                <Box className='graybbx' mt={2}>
                                    <Grid container spacing={0} alignItems="center">
                                        <Grid item lg={4} md={4} sm={4} xs={6}>

                                            <a href="https://www.business-standard.com/content/press-releases-ani/dpa-smartcloud-becomes-india-s-first-ever-organisation-to-be-authorized-as-a-fintech-entity-by-the-international-financial-services-centres-authority-ifsca-122121900923_1.html" target="_blank" rel="noopener noreferrer">
                                                <Box
                                                    className="awardsindexcircle"
                                                    style={{ backgroundImage: "url(" + Business + ")" }}
                                                ></Box>
                                            </a>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={6}>
                                            <a href="https://economicindia.co.in/business/dpa-smartcloud-becomes-indias-first-ever-organisation-to-be-authorized-as-a-fintech-entity-by-the-international-financial-services-centres-authority-ifsca/" target="_blank" rel="noopener noreferrer">
                                                <Box
                                                    className="awardsindexcircle"
                                                    style={{ backgroundImage: "url(" + Economic + ")" }}
                                                ></Box>
                                            </a>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={6}>
                                            <a href="https://theprint.in/ani-press-releases/dpa-smartcloud-becomes-indias-first-ever-organisation-to-be-authorized-as-a-fintech-entity-by-the-international-financial-services-centres-authority-ifsca/1271633/" target="_blank" rel="noopener noreferrer">
                                                <Box
                                                    className="awardsindexcircle"
                                                    style={{ backgroundImage: "url(" + thePrint + ")" }}
                                                ></Box>
                                            </a>
                                        </Grid>

                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>


                </Container>
            </Box>





        </>
    );
}
