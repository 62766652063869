import news1 from "../../../../asset/images/insight/case_studies/case_studies_thumbnails/nav_calculations.png";
import news2 from "../../../../asset/images/insight/case_studies/case_studies_thumbnails/enhance_asset.png";
import news3 from "../../../../asset/images/insight/case_studies/case_studies_thumbnails/lending-company-transforms.png";
import news4 from "../../../../asset/images/insight/case_studies/case_studies_thumbnails/enhancing-business-intelligence.png";



const Press_Release_data = [
    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "23Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2023",
        year: 2023,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "21LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2021",
        year: 2021,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "22LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2022",
        year: 2022,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "24LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2024",
        year: 2024,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "23Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2023",
        year: 2023,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "21LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2021",
        year: 2021,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "22LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2022",
        year: 2022,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "24LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2024",
        year: 2024,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },

    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "23Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2023",
        year: 2023,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "21LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2021",
        year: 2021,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "22LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2022",
        year: 2022,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "24LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2024",
        year: 2024,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },

    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "23Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2023",
        year: 2023,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "21LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2021",
        year: 2021,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "22LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2022",
        year: 2022,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "24LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2024",
        year: 2024,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "23Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2023",
        year: 2023,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "21LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2021",
        year: 2021,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "22LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2022",
        year: 2022,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "24LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2024",
        year: 2024,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },

    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "23Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2023",
        year: 2023,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "21LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2021",
        year: 2021,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "22LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2022",
        year: 2022,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "24LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2024",
        year: 2024,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "23Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2023",
        year: 2023,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "21LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2021",
        year: 2021,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "22LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2022",
        year: 2022,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "24LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2024",
        year: 2024,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "23Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2023",
        year: 2023,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "21LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2021",
        year: 2021,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "22LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2022",
        year: 2022,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "24LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2024",
        year: 2024,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },

    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "23Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2023",
        year: 2023,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "21LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2021",
        year: 2021,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "22LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2022",
        year: 2022,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "24LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2024",
        year: 2024,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },

    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "23Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2023",
        year: 2023,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "21LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2021",
        year: 2021,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "22LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2022",
        year: 2022,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "24LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2024",
        year: 2024,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "23Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2023",
        year: 2023,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "21LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2021",
        year: 2021,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "22LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2022",
        year: 2022,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "24LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2024",
        year: 2024,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },

    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "23Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2023",
        year: 2023,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "21LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2021",
        year: 2021,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "22LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2022",
        year: 2022,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "24LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2024",
        year: 2024,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },

];

export default Press_Release_data;