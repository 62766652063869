import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Yahoo from '../../../asset/images/media/yahoo.jpg';
import Fox5 from '../../../asset/images/media/fox5.png';
import MarketWatch from '../../../asset/images/media/marketwatch.png';

import { Link } from 'react-router-dom';
import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';

export default function QuantifyingESGRisk() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Decimal Point Analytics and PRMIA Host 'Quantifying ESG Risk' Event</title>
                <meta name="description" content="Discover the key takeaways from the 'Quantifying ESG Risk' event hosted by Decimal Point Analytics and PRMIA. Learn about the critical role of data analytics in ESG. " />

                <meta name="keywords" content="Decimal Point Analytics, PRMIA, Quantifying ESG Risk, data analytics solutions, environmental social governance, ESG risk assessment, investment decision-making, sustainable businesses, data-driven approaches, thought leadership, networking opportunities, interactive sessions, sustainable investing" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimal-pointa-analytics-and-prmia-collaborate-for-successful-quantifying-esg-risk-event" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Decimal Point Analytics and PRMIA Collaborate for Successful "Quantifying ESG Risk" Event
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        [New York] - <Link to="/" className='link'>Decimal Point Analytics</Link>, a leading provider of data analytics solutions, partnered with the Professional Risk Managers' International Association (PRMIA) to host the highly successful "Quantifying ESG Risk" event. The event took place on June 1st at New York Bar Association in New York, featuring a keynote session by Shailesh Dhuri, CEO of <Link to="/" className='link'>Decimal Point Analytics</Link>, and an esteemed panel of industry experts.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The event brought together industry professionals, investors, and experts in environmental, social, and governance (ESG) to explore the critical role of data analytics in assessing ESG risk. Attendees gained valuable insights into integrating ESG factors into investment decision-making, leveraging quantitative analysis, and identifying investment opportunities in sustainable businesses.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The keynote session was delivered by Shailesh Dhuri, CEO of <Link to="/" className='link'>Decimal Point Analytics</Link>, who shared his expertise and insights on the significance of data analytics in ESG risk assessment. Shailesh’s presentation provided attendees with a deep understanding of how data-driven approaches can contribute to sustainable investing strategies.
                                    </Typography>



                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The panel discussion, moderated by ESG expert Henry Shilling, included the following distinguished panelists:
                                        <ul>
                                            <li>
                                                <strong>  Shailesh Dhuri</strong>,  CEO, Decimal Point Analytics: Shailesh brought his extensive experience in data analytics and discussed the application of quantitative analysis in ESG risk assessment.
                                            </li>
                                            <li>
                                                <strong> John Finnerty</strong>, Ph.D., Professor, Fordham University: As an expert in finance and ESG, Finnerty shared his insights on the integration of ESG factors in investment decision-making and its impact on financial performance.
                                            </li>

                                            <li>
                                                <strong> Richard Freund</strong>, Associate Director, CDP Capital Markets: Freund provided valuable perspectives on the role of ESG data and disclosure in assessing ESG risk for investors.
                                            </li>
                                            <li>
                                                <strong>  Bruce Kahn</strong>, Ph.D., Head of ESG Implementation, Shelton Sustainable Equity Fund: Kahn discussed the practical implementation of ESG strategies, and the considerations involved in aligning investments with sustainability goals.
                                            </li>

                                        </ul>
                                    </Typography>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The event fostered thought leadership, facilitated networking opportunities and encouraged discussions among participants. Attendees had the chance to engage in interactive sessions, gaining hands-on experience in utilizing data analytics tools for evaluating ESG performance.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        "We are proud to have collaborated with PRMIA for the successful 'Quantifying ESG Risk' event," said Shailesh Dhuri, CEO of Decimal Point Analytics. "The event provided an invaluable platform for industry professionals to share insights and perspectives on the integration of data analytics in sustainable investing."</Typography>

                                </Box>

                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Grid container spacing={0} alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                <Box className='graybbx' mt={2}>
                                    <Grid container spacing={0} alignItems="center">
                                        <Grid item lg={4} md={4} sm={4} xs={6}>

                                            <a href="https://finance.yahoo.com/news/decimal-point-analytics-prmia-collaborate-130000621.html" target="_blank" rel="noopener noreferrer">
                                                <Box
                                                    className="awardsindexcircle"
                                                    style={{ backgroundImage: "url(" + Yahoo + ")" }}
                                                ></Box>
                                            </a>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={6}>
                                            <a href="https://www.marketwatch.com/press-release/decimal-point-analytics-and-prmia-collaborate-for-successful-quantifying-esg-risk-event-2023-06-05?mod=search_headline" target="_blank" rel="noopener noreferrer">
                                                <Box
                                                    className="awardsindexcircle"
                                                    style={{ backgroundImage: "url(" + MarketWatch + ")" }}
                                                ></Box>
                                            </a>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={6}>
                                            <a href="https://fox5sandiego.com/business/press-releases/cision/20230605IO18260/decimal-point-analytics-and-prmia-collaborate-for-successful-quantifying-esg-risk-event/" target="_blank" rel="noopener noreferrer">
                                                <Box
                                                    className="awardsindexcircle"
                                                    style={{ backgroundImage: "url(" + Fox5 + ")" }}
                                                ></Box>
                                            </a>
                                        </Grid>

                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
