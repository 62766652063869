import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import D_Sutra from "../../asset/images/aboutus/esg/d_sutra.svg";
import D_Sutra1 from "../../asset/images/aboutus/esg/learn.svg";
import D_Sutra2 from "../../asset/images/aboutus/esg/wow.svg";
import D_Sutra3 from "../../asset/images/aboutus/esg/holistic.svg";
import D_Sutra4 from "../../asset/images/aboutus/esg/creative.svg";
import D_Sutra5 from "../../asset/images/aboutus/esg/support.svg";

import sutras from "../../asset/images/new_banner/sutras_banner.jpg"
import { Helmet } from 'react-helmet';

export default function Decimal_sutras() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>
            <Helmet>
                <title>Decimal Point Analytics Mantra & Sutras </title>
                <meta name="description" content="Explore Decimal Sutras, our powerful aphorisms guiding organizational values and culture, developed collaboratively by our dedicated team." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimal-sutras" />
            </Helmet>

            {/* <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12} data-aos="fade-up">
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Decimal’s Mantra & Sutras
                                </Typography>

                                <Typography variant='h4' className='black'>
                                    Our Operating Principle
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12} data-aos="fade-up">
                            <Box className='al_right mobcenter'>
                                <img src={indexbanner} className='topbanner4' alt='Index Banner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}

            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + sutras + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={5} md={6} sm={7} xs={10}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Decimal’s Mantra & Sutras</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">Our Operating Principle</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>


            <Box className='section2 section_mob sec_bg' component='section' style={{ paddingTop: "2%" }}>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12} data-aos="faddde-up">
                            <Box className='al_center'>
                                <Typography variant='h5' className='blue '>
                                    Deliver  |  Performance  |  Advantage
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Box className='section section_mob' component='section' style={{ paddingTop: "3%" }}>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12} data-aos="faddde-up">
                            <Box className='al_center' mt={2}>
                                <Typography variant='h2' className='black'>
                                    Why do we call our values Decimal Sutra?
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={10} xs={10} data-aos="fassde-up">
                            <Box mt={1}>
                                <Typography variant='h6' className='black jst'>
                                    Sutra is an ancient Sanskrit word that if you translate it into English would be called an “Aphorism”. It is a short powerful saying full of meaning and wisdom that is relevant to life. Here too our values convey a strong spirit of our culture and will be a guiding light for how we think, behave, and interact with each other internally and externally with clients and suppliers. It will also be touching every segment – clients, stakeholders, planet, and us – the decimals that are central to these principles.
                                </Typography>


                                <Typography variant='h6' className='black jst'>
                                    Our organizational values were developed through extensive exercises and workshops involving employees from Mumbai, Nasik, and Gift City. Our leaders contributed their insights, but also emphasized that values are about how individuals collaborate within teams, with clients, and with each other. Therefore, it was important for everyone to share their thoughts on what should be our values.
                                </Typography>
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>



            <Box className='section ourstrength  sec_bg section_mob' component='section' style={{ paddingTop: "3%" }}>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>

                    <Grid container spacing={2} alignItems="top" justifyContent='center'>

                        <Grid item lg={4} md={6} sm={8} xs={10}>
                            <Box className='al_center ' mb={3}>
                                <img src={D_Sutra} className='topbanner' alt='Index Banner' />
                            </Box>
                        </Grid>
                    </Grid>

                    <Box mt={2}>
                        <Grid container spacing={2} alignItems="top" justifyContent='center'>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='al_center sutrabox'>
                                    <img src={D_Sutra1} className='topbanner' alt='Index Banner' />
                                    <Typography variant='h4' className='sutratxt'> Learn <FiberManualRecordIcon /> Stay Ahead </Typography>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='al_center sutrabox'>
                                    <img src={D_Sutra2} className='topbanner' alt='Index Banner' style={{ maxWidth: '72%' }} />
                                    <Typography variant='h4' className='sutratxt'> deliver <FiberManualRecordIcon /> wow </Typography>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='al_center sutrabox'>
                                    <img src={D_Sutra3} className='topbanner' alt='Index Banner' style={{ maxWidth: '96%' }} />
                                    <Typography variant='h4' className='sutratxt'> holistic <FiberManualRecordIcon /> responsible </Typography>
                                </Box>
                            </Grid>


                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='al_center sutrabox'>
                                    <img src={D_Sutra4} className='topbanner' alt='Index Banner' style={{ maxWidth: '72%' }} />
                                    <Typography variant='h4' className='sutratxt'> Creative <FiberManualRecordIcon /> bold </Typography>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='al_center sutrabox'>
                                    <img src={D_Sutra5} className='topbanner' alt='Index Banner' style={{ maxWidth: '78%' }} />
                                    <Typography variant='h4' className='sutratxt'> support <FiberManualRecordIcon /> listen </Typography>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                </Container>
            </Box>


        </>
    );
}
