import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import FE from "../../../asset/images/media/fe_transformx.png"
import TC from '../../../asset/images/media/tech_circle.png';
import YS from '../../../asset/images/media/your_story.png';
import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';


export default function GautamNaiduJoinsDPA() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title> Gautam Naidu Appointed Chief Revenue Officer at Decimal Point Analytics </title>
                <meta name="description" content="Gautam Naidu joins Decimal Point Analytics as Chief Revenue Officer. Explore his role in driving growth strategies and business development for the company." />
                <meta name="keywords" content="Gautam Naidu, Chief Revenue Officer, Decimal Point Analytics, Data Analytics, AI, Market Research Solutions, Business Development, Technology, Market Dynamics, Strategic Partnerships, Revenue Growth, Leadership, Expansion, Market Leadership." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/gautam-naidu-joins-decimal-point-analytics-as-chief-revenue-officer-in-mumbai" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Gautam Naidu joins Decimal Point Analytics as Chief Revenue Officer in Mumbai
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <Link to="/" className="link">Decimal Point Analytics</Link>,  a leading provider of data analytics, AI & market research solutions, is thrilled to announce the appointment of Gautam Naidu as its new Chief Revenue Officer (CRO). In his role, Mr. Naidu will spearhead revenue generation strategies, drive business development initiatives, and play a pivotal role in furthering the company's growth trajectory.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Gautam Naidu brings over 25 years of diverse experience in the technology and business development sectors. Mr. Naidu holds degrees from two prestigious institutions, having earned his post-graduate degree from the Indian Institute of Management Ahmedabad (IIM A) and his undergraduate degree from the Indian Institute of Technology Bombay (IIT B).
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Prior to joining Decimal Point Analytics, he held the position of Senior Vice President of Business Development at Talentica Software. His impressive career includes stints at prominent companies such as Talentica Software, IBM, CCR Technology, and Schlumberger. Throughout his career, Mr. Naidu has demonstrated exceptional leadership skills, a keen understanding of market dynamics, and a proven track record of driving revenue growth. His expertise in acquiring new logos, building strategic partnerships, and interdisciplinary knowledge of industry, technology & business development make him a valuable addition to Decimal Point Analytics.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Commenting on his role, Gautam Naidu said,</strong> "I am excited to join Decimal Point Analytics, a company known for its innovative solutions and commitment to excellence. I look forward to leveraging my experience to contribute to the company's success and drive revenue growth in this dynamic market." <strong>Decimal Point Analytics, CEO, Shailesh Dhuri,</strong> expressed confidence in Mr. Naidu's ability to lead the revenue generation efforts, stating, "We are delighted to welcome Gautam to our leadership team. His extensive experience and proven track record align seamlessly with our goals for expansion and market leadership. We are confident that Gautam's strategic vision and expertise will play a key role in accelerating Decimal Point Analytics' growth."
                                    </Typography>

                                </Box>

                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center">
                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <a href="https://www.financialexpress.com/business/digital-transformation-decimal-point-analytics-announces-gautam-naidu-as-its-cro-3360782/" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + FE + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <a href="https://yourstory.com/2024/01/startup-news-and-updates-daily-roundup-january-10-2024#:~:text=Decimal%20Point%20Analytics%2C%20a%20provider,help%20in%20the%20company%27s%20growth." target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + YS + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <a href="https://www.techcircle.in/2024/01/10/gautam-naidu-assumes-role-of-chief-revenue-officer-at-decimal-point-analytics" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + TC + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
