import ESG_USA from '../../../../../asset/images/insight/esg/esg_usa.png';
import ESG_SA from '../../../../../asset/images/insight/esg/esg_south_africa.jpg';
import ESG_CA from '../../../../../asset/images/insight/esg/esg_canada_review.jpg';

const ESG_Whitepapers_data = [
    {
        type: "internal",
        link: "/esg-insights/esg-whitepaper/navigating-sustainability-usa-esg-landscape-in-2023",
        imgsrc: ESG_USA,
        heading: "Navigating Sustainability: USA's ESG Landscape in 2023",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-whitepaper/esg-south-africa-review-2023-whitepaper",
        imgsrc: ESG_SA,
        heading: "ESG South Africa Review 2023 Whitepaper",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-whitepaper/esg-canada-review-2023-whitepaper",
        imgsrc: ESG_CA,
        heading: "ESG Canada Review 2023 Whitepaper",
    },
];

export default ESG_Whitepapers_data;