
import { Container, Grid, Typography, Box, Divider } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import { Helmet } from 'react-helmet';
import ESGDownload from '../../../../../../Component/forms/ESG_download';

import Banner from '../../../../../../asset/images/insight/esg/esg_canada_banner.jpg';
import ESGWhitepaperBack from '../../../../../../Component/BackButtons/ESGWhitepaperBack';


export default function ESG_canada_review() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const link = "https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/creative_dpa/o/white-papers%2Fwhite-papers-dpa-esg-insights-canada-esg-review-2023.pdf"


  return (
    <>

      <Helmet>
        <meta charset="UTF-8" />
        <title>ESG Canada Review 2023 Whitepaper | Decimal Point Analytics</title>
        <meta name="description" content="Gain insights into Canada's role in the global ESG movement with the Canada ESG Review 2023 Whitepaper. Explore asset management, government initiatives, and disclosures." />
        <meta name="keywords" content="Canada ESG Review, ESG adoption, sustainability, asset management sector, real economy, ESG disclosures, government initiatives, financial support, mandatory ESG disclosures, ESG performance, transparency, sustainable future." />
        <meta name="author" content="Decimal Point Analytics" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/esg-insights/esg-whitepaper/esg-canada-review-2023-whitepaper" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>


      <Box className='section sec_bg' component='section'>

        <Container maxWidth='lg' className='bdr'>

          <Grid container spacing={3} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className='al_center' mb={2}>
                <Typography variant='h3' className='subhead skblue al_center'>
                  ESG Insights
                </Typography>
                <Typography variant='h1' className='mainhead blue al_center mb0'>
                  ESG Canada Review 2023 Whitepaper
                </Typography>
              </Box>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              {/* <Box className='al_center' mb={2}>
                <img src={Banner} alt='ESG Whitepaper banner' className='banner-head' />
              </Box> */}
              <Box className="">
                <Box
                  className="banner-head"
                  style={{
                    backgroundImage: 'url(' + Banner + ')',
                  }}
                >
                </Box>
              </Box>
            </Grid>


          </Grid>


          {/* <Writemessage3 pageVisited="  ESG Canada Review 2023 Whitepaper in ESG Insights" /> */}

          <Box className='whitebx' mt={1}>

            <Box mb={1}>
              <Grid container spacing={4} alignItems="flex-start">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box>
                    <Typography variant='h4' className='blue jst'>
                      Canada ESG Review 2023 Whitepaper: Unraveling Canada's ESG Landscape for a Sustainable Future
                    </Typography>



                    <Typography variant='h6' className='black jst'>
                      As the global focus on sustainability and responsible investing intensifies, we are excited to present our latest publication, the Canada ESG Review 2023 Whitepaper. </Typography>


                    <Typography variant='h6' className='black jst'>
                      In this edition of our whitepaper, we explore the progress of ESG in Canada, with a particular emphasis on the pressing issue of Net Zero and climate goals. The Canada ESG Review 2023 Whitepaper offers a comprehensive exploration of Canada's pivotal role in the global ESG movement and its profound impact on sustainability. Through this in-depth analysis, we shed light on crucial aspects shaping Canada's ESG landscape. </Typography>


                    <Typography variant='h6' className='black jst'>
                      Our extensive whitepaper showcases the commendable strides made by Canada's asset management sector in embracing ESG principles. Their commitment sets them apart as industry outperformers. However, in the context of the real economy, there is room for improvement. Canada's corporate sector lags behind in ESG adoption and transparency, highlighting the need to align businesses with sustainable practices. </Typography>



                    <Typography variant='h6' className='black jst'>
                      Notably, Canada stands among the highest per capita emitters globally. This observation emphasizes the urgency and significance of ESG initiatives in addressing environmental challenges and creating a more sustainable future. </Typography>


                    <Typography variant='h6' className='black jst'>
                      Within the whitepaper, we also highlight government initiatives and financial support for ESG-focused projects and industries. These measures play a vital role in fostering sustainability and aligning Canada with international efforts for a greener tomorrow. </Typography>


                    <Typography variant='h6' className='black jst'>
                      Furthermore, we emphasize the essential role of mandatory ESG disclosures for Canadian companies. Transparent reporting on ESG performance enables effective evaluation and comparison, driving positive change.  </Typography>


                    <Typography variant='h6' className='black jst'>
                      The Canada ESG Review 2023 Whitepaper serves as a valuable resource for individuals and organizations seeking profound insights into Canada's ESG landscape. By equipping our readers with comprehensive knowledge, we empower them to make informed decisions that contribute meaningfully to a sustainable future.  </Typography>


                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Divider />

            <Box mt={2} className='al_center'>
              <Typography variant="h4" className="black">
                Get Your Full Copy Now – Simply Fill Out the Form
              </Typography>
            </Box>


            <Box className='graybbx2222' mt={2}>
              <ESGDownload reportby={"ESG Canada Review 2023 Whitepaper"} link={link} />
            </Box>


          </Box>




          <ESGWhitepaperBack />




        </Container>
      </Box>


    </>
  );
}

