import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function GiftCity_table() {

    const data = [

        { monthYear: 'Jun-24', wtc: 7957, signature: 2276, total: 10233, employees: 150, ue: 68 },
        { monthYear: 'May-24', wtc: 7952, signature: 2504, total: 10456, employees: 150, ue: 70 },
        { monthYear: 'Apr-24', wtc: 6637, signature: 2027, total: 8664, employees: 153, ue: 57 },
        { monthYear: 'Mar-24', wtc: 5516, signature: 1778, total: 7294, employees: 153, ue: 48 },
        { monthYear: 'Feb-24', wtc: 4980, signature: 1781, total: 6761, employees: 154, ue: 44 },
        { monthYear: 'Jan-24', wtc: 4722, signature: 1626, total: 6348, employees: 155, ue: 41 },
        { monthYear: 'Dec-23', wtc: 4515, signature: 2036, total: 6551, employees: 157, ue: 42 },
        { monthYear: 'Nov-23', wtc: 4686, signature: 1858, total: 6544, employees: 161, ue: 41 },
        { monthYear: 'Oct-23', wtc: 5803, signature: 1951, total: 7754, employees: 165, ue: 47 },
        { monthYear: 'Sep-23', wtc: 5716, signature: 1757, total: 7473, employees: 159, ue: 47 },
        { monthYear: 'Aug-23', wtc: 5876, signature: 2443, total: 8319, employees: 165, ue: 50 },
        { monthYear: 'Jul-23', wtc: 6114, signature: 2910, total: 9024, employees: 142, ue: 64 },
        { monthYear: 'Jun-23', wtc: 6774, signature: 181, total: 6955, employees: 143, ue: 49 },
        { monthYear: 'May-23', wtc: 7119, signature: 20, total: 7139, employees: 133, ue: 54 },
        { monthYear: 'Apr-23', wtc: 5867, signature: 1, total: 5868, employees: 130, ue: 45 },
        { monthYear: 'Mar-23', wtc: 4589, signature: 2, total: 4591, employees: 110, ue: 42 },
        { monthYear: 'Feb-23', wtc: 3132, signature: 0, total: 3132, employees: 105, ue: 30 },
        { monthYear: 'Jan-23', wtc: 2383, signature: 0, total: 2383, employees: 104, ue: 23 },
        { monthYear: 'Dec-22', wtc: 2670, signature: 0, total: 2670, employees: 96, ue: 28 },
        { monthYear: 'Nov-22', wtc: 2593, signature: 0, total: 2593, employees: 95, ue: 27 },
        { monthYear: 'Oct-22', wtc: 3025, signature: 0, total: 3025, employees: 92, ue: 33 },
        { monthYear: 'Sep-22', wtc: 3176, signature: 0, total: 3176, employees: 100, ue: 32 },
        { monthYear: 'Aug-22', wtc: 2825, signature: 0, total: 2825, employees: 94, ue: 30 },
        { monthYear: 'Jul-22', wtc: 174, signature: 0, total: 174, employees: 81, ue: 2 },
        { monthYear: 'Jun-22', wtc: 232, signature: 0, total: 232, employees: 67, ue: 3 },
        { monthYear: 'May-22', wtc: 59, signature: 0, total: 59, employees: 61, ue: 1 },
        { monthYear: 'Apr-22', wtc: 0, signature: 0, total: 0, employees: 60, ue: 0 },

    ];

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', borderRadius: '0px', padding: '5px' }}>
            <TableContainer sx={{ maxHeight: '260px' }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ minWidth: '50px' }}>MM/YY</TableCell>
                            <TableCell align="right" className='skblue' sx={{ minWidth: '20px' }}>WTC</TableCell>
                            <TableCell align="right" className='skblue' sx={{ minWidth: '20px' }}>SIGNATURE</TableCell>
                            <TableCell align="right" className='skblue' sx={{ minWidth: '20px' }}>Total</TableCell>
                            <TableCell align="right" sx={{ minWidth: '30px' }}>EMPLOYEES</TableCell>
                            <TableCell align="right" sx={{ minWidth: '50px' }}>U/E</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.monthYear}</TableCell>
                                <TableCell align="right">{item.wtc}</TableCell>
                                <TableCell align="right">{item.signature}</TableCell>
                                <TableCell align="right">{item.total}</TableCell>
                                <TableCell align="right">{item.employees}</TableCell>
                                <TableCell align="right">{item.ue}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}