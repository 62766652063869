import { Container, Grid, Typography, Box, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import Writemessage3 from '../../Component/Writemessage3'
import { Helmet } from 'react-helmet';


import fidelity1 from "../../asset/images/product/fidelitypulse/fidelity1.svg";
import fidelity2 from "../../asset/images/product/fidelitypulse/fidelity2.svg";
import fidelity3 from "../../asset/images/product/fidelitypulse/fidelity3.svg";
import fidelity4 from "../../asset/images/product/fidelitypulse/fidelity4.svg";
import fidelity5 from "../../asset/images/product/fidelitypulse/fidelity5.svg";
import fidelity6 from "../../asset/images/product/fidelitypulse/fidelity6.svg";
import fidelity7 from "../../asset/images/product/fidelitypulse/fidelity7.svg";
import fidelity8 from "../../asset/images/product/fidelitypulse/fidelity8.svg";


import WhyFidelity from "../../asset/images/product/fidelitypulse/why_fidelity.png";
import ProductCTAA from '../../Component/ProductCTAA';
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import GetInTouch from '../../Component/Get_in_touch';
import FidelitypulseFaq from './Fidelitypulse_components/FidelitypulseFaq';

import FidelityPulse from "../../asset/images/new_banner/fidelity_pulse_banner.jpg"

export default function Fidelitypulse() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };



  return (
    <>


      <Helmet>
        <meta charset="UTF-8" />
        <h1>Fidelity Pulse - Machine Learning Fraud Detection for Financial Organizations</h1>
        <title>Fidelity Pulse - Fraud Detection System for Financial Organizations</title>
        <meta name="description" content="Protect your financial organization with Fidelity Pulse's ML fraud detection system. Identify discrepancies and outliers with a Fidelity Score." />
        <meta name="author" content="Decimal Point Analytics" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/fidelitypulse" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org/", 
        "@type": "Product", 
        "name": "Fidelity Pulse",
        "image": "https://www.decimalpointanalytics.com/static/media/fidelitypulsebanner.602a8bc4d8435c17a5d3.gif",
        "description": "Financial organizations lose a significant percentage of annual revenue to fraud every year. Undetected frauds also lead to loss of productivity and eroding customer confidence.
      FidelityPulse is a fraud detection system which employs machine learning to help identify and flag potential financial frauds.
      We have designed a model which can ingest multiple forms of financial data to identify discrepancies and outliers.
      The resulting Fidelity Score which lies between (0 to 1000) helps you identify the frauds and make pre-emptive decision.
      The score is calculated based on the financial details fed to the system.",
        "brand": {
          "@type": "Brand",
          "name": "Decimal Point Analytics"
        }
      }          
          `}
        </script>
      </Helmet>


      <Box id="product-page">


        {/* <Box id='fidelitypulse' className="mainsection" component="section">
          <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography variant="h1" className="blue">
                    FidelityPulse
                  </Typography>

                  <Typography variant="h4" className="black">
                    Empowering Financial Security with Advanced Fraud Detection
                  </Typography>
                </Box>


              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box className="creditvdo" style={{ backgroundImage: 'url(' + indexbanner + ')', }}>
              
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box> */}

        {/* <Box className="banner_bx">
          <Box style={{ backgroundImage: "url(" + FidelityPulse + ")" }} className="banner_img">
            <Box className="img_over bl_overlay">
              <Grid container spacing={0} alignItems="center" justifyContent='center'>
                <Grid item lg={3} md={3} sm={5} xs={8}>
                  <Box className="al_center">
                    <Typography className="white" variant="h1">Fidelity Pulse</Typography>
                    <Box mt={'10px'}>
                      <Typography className="white" variant="h4">Empowering Financial Security With Advanced Fraud Detection</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box> */}

        <Box className="banner_bx">
          <Box style={{ backgroundImage: "url(" + FidelityPulse + ")" }} className="banner_img">
            <Box className="img_over bl_overlay">
              <Grid container spacing={0} alignItems="center" justifyContent='center'>
                <Grid item lg={3} md={4} sm={5} xs={7}>
                  <Box className="al_center">
                    <Typography className="white" variant="h1">Fidelity Pulse</Typography>
                    <Box mt={'10px'}>
                      <Typography className="white" variant="h4">Empowering Financial Security With Advanced Fraud Detection</Typography>
                      <Box className='banner_btns mb32'>
                        <Box className='banner_pulse'>
                          <IconButton className="header_pulse_button" onClick={openModal} >
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        <Box id='fidelitypulse' className='section2 sec_bg' component='section'>
          <Container maxWidth='lg' className='bdr'>


            <Grid container direction="row"
              justifyContent="center"
              alignItems="center">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_center synopsic_bx">
                  <Typography variant='h6' className='black jst'>
                    FidelityPulse is an advanced fraud detection system that utilizes machine learning and statistical methods to identify and flag potential financial frauds. By analyzing various financial data sources, such as reported accounts and returns, FidelityPulse detects discrepancies and outliers to provide organizations with a comprehensive understanding of fraud risks. With its data science-driven approach, FidelityPulse generates a Fidelity Score ranging from 0 to 1000, indicating the likelihood of fraudulent activity. This score empowers organizations to take proactive measures and make informed decisions to mitigate fraud risks before they escalate. </Typography>

                  <Typography variant='h6' className='black jst'>
                    Offering rapid results and an accuracy rate of over 90%, FidelityPulse has successfully conducted proof-of-concepts for renowned entities, including an Indian regulator, and leading private bank. Its ability to handle diverse data formats makes it a flexible solution for organizations across various sectors, enabling them to strengthen their security measures and safeguard their financial well-being.
                  </Typography>

                </Box>

                <Box mt={2}>
                  <ProductCTAA allignment="flx_fs" cta_content="Secure your financial future with FidelityPulse -" cta_btn_name="Contact us to learn more!" pageVisited="Fidelity Pulse" />
                </Box>

              </Grid>
            </Grid>
          </Container>
        </Box>



        <Box id='fidelitypulse' className="section mob_card" component="section">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_center">
                  <Typography className="black" variant="h2"> Harnessing the Power of FidelityPulse
                    <Typography variant="h2" className="black h2v2">  for Advanced Fraud Detection and Unparalleled Benefits </Typography>
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Box mt={3}>
              <Grid container spacing={2} alignItems="top">

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={fidelity1} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two" >
                          Powerful Algorithm:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Utilizes advanced statistical methods and machine learning for effective fraud detection.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={fidelity2} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two" >
                          Multi-source Data Analysis:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Analyzes diverse financial data sources for a comprehensive assessment of fraud risks.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>


                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={fidelity3} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two" >
                          Timely and Accurate Results:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Rapid detection within seconds with an accuracy rate over 90%.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>


                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={fidelity4} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two" >
                          Real-time Operation at Scale:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Operates at internet scale, suitable for organizations of all sizes.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>


                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={fidelity5} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two" >
                          Data Science Principles:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Objective and unbiased detection by distinguishing natural patterns from malicious numbers.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={fidelity6} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two" >
                          Comprehensive Risk Assessment:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Highlights systemic risks and provides a proactive approach to risk mitigation.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>


                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={fidelity7} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two" >
                          Proven Track Record:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Successful proof-of-concepts with notable entities in real-world scenarios.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>


                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={fidelity8} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two" >
                          Industry Adaptability:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Flexible solution for various sectors, handling different data formats.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

              </Grid>
            </Box>
          </Container>
        </Box>


        <Box id='fidelitypulse' className="section sec_bg" component="section">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={0} alignItems="center" justifyContent='center'>
              <Grid item lg={12} md={10} sm={12} xs={12}>
                <Box className="al_center" pb={'2%'}>
                  <Typography className="black" variant="h2">
                    Why Choose FidelityPulse ?
                  </Typography>
                </Box>

                <Box className="al_center">
                  <img src={WhyFidelity} className="topbanner2" alt="Banner2" />
                </Box>

                <Box mt={2}>
                  <ProductCTAA allignment="al_center" cta_content="Unlock the power of data-driven fraud detection" cta_btn_name="Schedule Your Demo Today!" pageVisited="Fidelity Pulse" />
                </Box>


              </Grid>
            </Grid>
          </Container>
        </Box>





        <Box id='fidelitypulse' className='section' component='section'>
          <Container maxWidth='lg' className='bdr'>

            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* <Box className='al_center'>
                <Typography variant='h2' className='black'>
                Unleash the Power of Proactive Fraud Detection for Enhanced Security 
                </Typography>
              </Box> */}
                <Box className="al_center">
                  <Typography className="black" variant="h2"> Unleash the Power of
                    <Typography variant="h2" className="black h2v2">  Proactive Fraud Detection for Enhanced Security  </Typography>
                  </Typography>
                </Box>
              </Grid>
            </Grid>


            <Grid container direction="row"
              justifyContent="center"
              alignItems="center">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box mt={2}>
                  <Typography variant='h6' className='black jst'>
                    FidelityPulse stands as a robust fraud detection algorithm that combines statistical methods and machine learning to effectively identify potential financial frauds. By processing diverse financial data sources, it generates a Fidelity Score that serves as an early warning system, enabling organizations to proactively detect and mitigate fraud risks. With its exceptional accuracy and real-time operation, FidelityPulse proves to be an invaluable asset for financial institutions aiming to safeguard their revenue streams and uphold customer trust.  </Typography>

                </Box>

                <Box mt={2}>
                  <ProductCTAA allignment="flx_fs" cta_content="Supercharge your fraud detection efforts with FidelityPulse –" cta_btn_name="Contact us today!" pageVisited="Fidelity Pulse" />
                </Box>

              </Grid>
            </Grid>
          </Container>
        </Box>



        <Box id='fidelitypulse' className='section sec_bg' component='section'>
          <Container maxWidth='lg' className='bdr'>
            <FidelitypulseFaq />
          </Container>
        </Box>


        <Box className="al_center">
          <GetInTouch />
        </Box>




        <Writemessage3 pageVisited="Fidelity Pulse" />

      </Box>

      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="bpdDoF4WO2k"
        onClose={() => setIsOpen(false)}
      />

    </>
  );
}
