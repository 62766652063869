import { Container, Typography, Box, Grid, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';
import Writemessage3 from '../../../Component/Writemessage3';
import Blogback from '../../../Component/Blogback'
import DS from "../../../asset/images/team/dheeraj_singh.png";


export default function Digital_security_tokens() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>What is digital security token?  </h1>
                <title>Digital Security Tokens – A Better Way of Raising Capital</title>
                <meta name="description" content="Security token is intended to be treated as an investment instrument. They are of various types like Equity backed, Debt backed, Asset-backed & Utility tokens." />
                <meta name="keywords" content="security token,
raising capital,
token crypto,
security token offering,
token cryptocurrency,
cryptocurrency security" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/digital-security-tokens-a-better-way-of-raising-capital" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>




            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center'>
                                Digital Security Tokens – A Better Way  of Raising Capital
                            </Typography>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>


                        <Typography variant='h4' className='black jst' gutterBottom> Digital security tokens, issued on a distributed ledger like “the blockchain”, represent a stake in an asset or an enterprise.
                        </Typography>


                        <Box pt={1} pb={1}>
                            <Typography variant='h4' className='blue jst'>  What are digital security tokens?    </Typography>
                        </Box>



                        <Typography variant='h6' className='black jst' gutterBottom>
                            Security tokens can be of various types:
                            <ol type="1">
                                <li>
                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>   Equity backed tokens: </strong>   As the name suggests, an equity backed token represents ownership of an underlying asset such as company stock. An equity backed token holder may be entitled to dividends, voting rights, or both.
                                    </Typography>
                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong> Debt backed tokens: </strong> Debt backed tokens are tokens that accrue an interest. This interest could be at a fixed rate or variable (linked to some benchmark). They represent debt owed to the token holder by the issuer (generally a government or a corporate enterprise).
                                    </Typography>
                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong> Asset backed tokens: </strong>  Tokens that are backed by cashflows arising out of specific real world assets are called asset backed tokens. These assets could be anything from real estate to jewellery to paintings or even intangibles like music rights etc.
                                    </Typography>
                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>   Utility tokens: </strong> Utility tokens confer some sort of rights, benefits to the token holder that would not otherwise fall in any of the three categories listed above. Typical examples could be frequent flyer miles from airline companies, credit card reward points etc.
                                    </Typography>
                                </li>


                            </ol>
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom> These digital tokens (or digital assets) provide a powerful and flexible method for enterprises to raise capital (equity, debt or asset-backed) through security token offerings.
                        </Typography>


                        <Box pt={1} pb={1}>
                            <Typography variant='h4' className='blue jst'> Advantages of Security Token Offerings  </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            <ol type="1">
                                <li>
                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Security token offerings (STOs) are a type of public offering that facilitates the trading of financial assets via tokenized digital securities. Token transactions are stored on, and validated through, a distributed public ledger like <strong>“the blockchain”</strong>.
                                    </Typography>
                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        STOs are also subject to regulatory oversight and therefore have to abide by rules and regulations, providing token holders safe and legitimate investment avenues.
                                    </Typography>
                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        STOs are also more cost-effective than, traditional initial public offerings, or IPOs. All the legalities can be coded into a smart contract.
                                    </Typography>
                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The use of a public distributed ledger to store and validate transaction also eliminates traditional paperwork and dependencies, reducing processing time drastically.
                                    </Typography>


                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Further, STOs allow fractionalization similar to what we see in the crypto world. This enables even beginner investors, with very small amounts available to deploy, participate in these offerings. Trading can also be done 24/7, providing investors with both convenience and liquidity.
                                    </Typography>
                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        While making it easier and convenient for investors, STOs also allow issuers to raise capital relatively cheaply and with convenience. <strong> Tokenization </strong> allows issuers to tap investors that would have been either impossible or very expensive to tap.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The magic of <strong> distributed ledger technology </strong>  provides a cheap and alternative option to approach a wide swathe of investors across geographies.
                                    </Typography>

                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Additionally, issuers can raise money in smaller amounts and more frequently instead of lumpy large issuances normally seen in the traditional way of raising financing.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        This allows for more efficient deployment of capital in their core businesses and obviates or reduces the need to worry about treasury management (deploying money raised temporarily until it is finally needed in the core business).
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Taken to the extreme, corporates can raise money on a “just in time” basis, raising money only when they need and in amounts that is actually needed.
                                    </Typography>
                                </li>


                            </ol>
                        </Typography>




                        <Typography variant='h6' className='black jst' gutterBottom> <strong> Smart contracts, </strong> an inherent part of most  <strong>  distributed ledgers  </strong>  also allow corporates convenience and ease of investor servicing.
                        </Typography>



                        <Typography variant='h6' className='black jst' gutterBottom> Be it information flow and/or actual cash flows/payments like interest, dividends etc., everything can be automated and made simple.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom> Corporates which use technology like <strong>Enterprise Resource Planning (ERP)</strong> can even prepare daily financial statements and disseminate them to investors seamlessly and in an automated manner. This would give a big fillip to transparency and corporate governance standards.
                        </Typography>


                        <Box pt={1} pb={1}>
                            <Typography variant='h4' className='blue jst'> Regulatory Norms Across the World  </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            To enable all that I’ve mentioned above, it is imperative that sound rules and regulations are put in place so that investors feel assured of the process and are not defrauded.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            While the technology provides global access to both issuers and investors, the rules and regulations tend to differ widely from one country (or jurisdiction) to another.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            In fact most countries (including India) are yet to formulate rules and regulations that are specific to digital security tokens. The US uses the <a href='https://www.investopedia.com/terms/h/howey-test.asp' target='_blank' rel="noopener noreferrer"> Howey Test </a> to bring digital security tokens under the ambit of the <strong>Securities and Exchange Commission (SEC)</strong> rules and regulations.
                        </Typography>



                        <Typography variant='h6' className='black jst' gutterBottom>
                            Some other countries have enacted rules and regulations that are specific to <strong>digital assets</strong> (including <strong>security tokens</strong>).
                        </Typography>


                        <Typography variant='h6' className='black jst' gutterBottom>
                            Most notable amongst them is Dubai (UAE), which has not only framed specific regulations for virtual (digital) assets, but has also set up an independent regulatory authority, <a href='https://www.vara.ae/' target='_blank' rel="noopener noreferrer"> Virtual Assets Regulatory Authority </a> <strong>(VARA )</strong> for the same. <strong>VARA</strong> has released simple and concise regulations for regulating this area/activity a month ago (on February 7, 2023).
                        </Typography>


                        <Typography variant='h6' className='black jst' gutterBottom>
                            <strong>India</strong> is yet to finalise its regulatory framework for virtual (or digital) assets. We hope the policymakers come up with a simple, clear and concise regulatory framework as <strong>Dubai</strong> has done.
                        </Typography>


                        <Typography variant='h6' className='black jst' gutterBottom>
                            <em>
                                “At Decimal Point, we intend to assist/facilitate corporates and other institutions issue digital security tokens towards their objective of raising capital and/or monetizing assets. We believe that virtual digital assets are the way forward and we remain committed to remain at the cutting edge of this technology to foster innovation”.
                            </em>
                        </Typography>


                        <Box id='EmployeeProfile'>

                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Box className='teama' style={{ backgroundImage:  'url(' + DS + ')', }}></Box>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<>
                                            <Typography variant='h4' className='teaminfo11 blue al_left mb0' gutterBottom>
                                            Mr. Dheeraj Singh
                                            </Typography>
                                            <Box className='line'></Box>
                                        </>}

                                        secondary={<>
                                            <Box my={1}>
                                                <Typography variant='h6' className='black al_left mb0' gutterBottom>
                                                    <strong>Executive Vice President,</strong> <br />
                                                    Decimal Point Analytics Pvt. Ltd.
                                                </Typography>
                                            </Box>
                                        </>} />
                                </ListItem>
                            </List>

                        </Box>

                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Digital Security Tokens – A Better Way of Raising Capital" />
                    </Box>

                    <Blogback />


                </Container>
            </Box>

        </>
    );
}
