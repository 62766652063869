import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import Writemessage3 from '../../../Component/Writemessage3';
import { Helmet } from 'react-helmet';
import Blogback from '../../../Component/Blogback'

import ETF1 from "../../../asset/images/insight/blog_pages/1ETF_shock.jpg";

export default function Beginning_of_ETF_shock() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Beginning of ETF Shock </h1>
                <title>Beginning of ETF Shock | Decimal Point Analytics</title>
                <meta name="description" content="Discover the beginning of an ETF shock in financial markets and its implications. Learn about the challenges faced by ETF providers during disruptions." />
                <meta name="keywords" content="ETF shock, disruptions, real economy, short duration ETFs, standard deviation move, structural fallacy, construction, visual impact, exit fees, ETF providers, market stability, global central banks, financial markets, BlackRock, Vanguard, redemption fees, challenges." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/beginning-of-etf-shock" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                Beginning of ETF Shock
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                March 23, 2020
                            </Typography>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst'>Last Monday I wrote about the issues that ETFs will face due to the disruptions to real economy. I did not expect the problems to surface at the short-end of ETFs and so fast.</Typography>

                        <Typography variant='h6' className='black jst'>Last week some of the short duration ETFs had a 30 plus-standard deviation move in one day. A thirty standard deviation move in many different ETFs mathematically should not normally happen in our lifetime even if we assume we inhabit a multiverse with zillions of universes, each with trillions of galaxies with billions of stars in each galaxy and most stars having an advance life form with ETFs trading at the centre of their respective planetary financial capital, and all these planets are subject to one single multiversal pdf.</Typography>

                        <Typography variant='h6' className='black jst'>A thirty standard deviation move in short ETFs only indicates the structural fallacy in construction of ETFs which I described in my earlier post.</Typography>


                        <Typography variant='h6' className='black jst'>I post screenshot from website of one such ETF, just so that readers can visually see how a 30 SD move looks like.</Typography>

                        <br />


                        <Typography variant='h4' className='blue jst'>Growth of Hypothetical $10,000</Typography>


                        <Box className='al_left' mt={4} mb={4}>
                            <img src={ETF1} className='topbanner2' alt='ETF1' />
                        </Box>

                        <Typography variant='h6' className='black jst'>Source: ishares website</Typography>

                        <Typography variant='h6' className='black jst'>What is worse, is that alongside this sharp downmove, many ETF providers have increased exit fees by 400% overnight. 1</Typography>

                        <Typography variant='h6' className='black jst'>I repeat here that global central banks are busy stabilizing real economy and they will not be able to clear mess in the ETF market at the same time.</Typography>

                        <br />

                        <Typography variant='h6' className='black jst'><a href=' https://www.wsj.com/articles/blackrock-vanguard-raise-price-of-cash-redemption-for-some-etfs-11584746900' target='_blank'> https://www.wsj.com/articles/blackrock-vanguard-raise-price-of-cash-redemption-for-some-etfs-11584746900</a></Typography>



                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}



                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Beginning of ETF Shock" />
                    </Box>

                    <Blogback />


                </Container>
            </Box>


        </>
    );
}
