import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Writemessage3 from '../../../Component/Writemessage3';

import Casestudyback from '../../../Component/Casestudyback'



import Process_and_cost1 from "../../../asset/images/insight/case_studies/process_and_cost1.jpg";

export default function Process_and_cost() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <title>Cost Efficiency through Structured Data Management Solutions</title>
                <meta name="description" content="Learn how DPA's structured data management solution saved $250K annually for an international enterprise. Read the detailed case study now." />
                <meta name="keywords" content="
AI in data analytics,
artificial intelligence solutions company,
business intelligence analytics,
Corporate Data Providers,
market research company in US,
market research solutions,
Sector research provider
" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/process-and-cost-efficiency-through-structured-data-management-solutions" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            </Helmet>

            <Box id="case-studies" className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center mb0'>
                                    Process & Cost Efficiency through DPA's Structured Data Management Solutions
                                </Typography>

                                <Typography className=' mainsmhead black al_center '>
                                    The solution that saved $250,000 annually: Process & Cost Efficiency through Structured Data Management Solutions
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Writemessage3 pageVisited="Process And Cost Efficiency through DPAs Structured Data Management Solutions" />



                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>
                            Decimal Point Analytics (DPA) unlocked an effective solution for another data-based problem for a notable firm’s financial intelligence division. The client offers well-grounded data insights and publishes recent investment trends through a core fund database. The firm collects, processes, and disseminates enormous data in different fund groups. In addition, the client provides security-level reports to their customer. It serves individuals who make data-backed investment decisions.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            DPA’s methodical approach aided the client by saving $250,000 annually and creating a master database to fast-track the overall processes. It helped create a self-propriety master database and avoid third-party data dependency.
                        </Typography>
                    </Box>

                    <Box className='whitebx' mt={1}>
                        <Typography variant='h4' className='blue' >Problem Outline </Typography>

                        <Typography variant='h6' className='black jst'>
                            Creating detailed data reports requires the client to deal with a large data universe. It involves multiple data points such as stock identifiers, the holdings value of the stocks, the number of shares outstanding, the market price of the stocks, etc, arranged in an unruly and unsystematic way. The client deploys a third-party database to procure the enormous data. Using data from different sources is time-consuming and results in inefficient & expensive processes.
                        </Typography>
                    </Box>

                    <Box className='whitebx' mt={1}>
                        <Grid container spacing={3} alignItems="top">

                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <Box className='al_center'>
                                    <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + Process_and_cost1 + ')', }}></Box>
                                </Box>
                            </Grid>

                            <Grid item lg={8} md={8} sm={8} xs={12}>
                                {/*
                                <Box mb={2}>
                                    <Link to='/analystpulse'>
                                        <Typography variant='h4' className='blue' >Know More About Our Product on Analytics – AnalystPulse </Typography>
                                    </Link>
                                </Box> 
                                */}

                                <Box>
                                    <Typography variant='h4' className='blue' >Identifying the Challenges </Typography>

                                    <Typography variant='h6' className='black jst'>
                                        The client was looking for a solution to evade third-party database usage. Its purpose was to expedite the process of producing security-level data products for customers. However, achieving the end goal was only possible by building a master database that involved all the necessary units in creating an impeccable data product.
                                    </Typography>

                                    <Typography variant='h6' className='black jst'> The primary problem was data disorganization. A third-party database meant there were three different sources for data reception. They were:
                                        <ul>
                                            <li> Fund managers </li>
                                            <li> Client FTP </li>
                                            <li> Various fund websites </li>
                                        </ul>
                                    </Typography>

                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className='whitebx' mt={1}  >
                        <Typography variant='h6' className='black jst'>
                            All these methods of data procurement were free, and data was available in raw form. Fund information providers sent the files in Excel, PDFs, and other formats. As a result, data lacked uniformity and, at times, even the required information.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            DPA had to collect and process these crucial data points to construct a methodology for third-party database usage eradication.
                        </Typography>
                    </Box>


                    <Box className='whitebx' mt={1}  >
                        <Typography variant='h4' className='blue' >How did DPA approach the problem? </Typography>

                        <Typography variant='h6' className='black jst'>
                            DPA’s team profusely worked on data collection, processing, and quality check. Finally, DPA’s data, engineering, and the quant team worked in tandem to generate & analyze the final security-level data reports. Afterward, the process of eliminating the third-party database started.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The Quant team is a part of the client's in-house team that mainly writes algorithms or programs based on mathematical models to identify trends or exceptions in the data.
                        </Typography>

                    </Box>

                    <Box className='whitebx' mt={1}  >
                        <Typography variant='h4' className='blue' >Resolution Strategy in Process</Typography>


                        <Typography variant='h6' className='black jst'> Handling a scattered and desultory database is a lengthy process. Hence, DPA’s team masterfully devised a solution by initiating with analysis of the mapping process. The following steps were:
                            <ul>
                                <li> <strong>  Ideating the Methodology -</strong>  Strategizing a methodology was essential to replicate the third-party database effectively using the client’s holdings database.  </li>
                                <li>  <strong>  Developing a New Database -</strong>  the team moved ahead to create a clean security master database devoid of any inconsistencies or data irregularities. </li>
                                <li>  <strong>  Adapting to Admin Database - </strong> The final step included replacing the new security master file in the client’s administrative database. </li>
                            </ul>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The entire stepwise process enabled the client’s engineering team to build a 1:1 mapping with a security master database against the holdings database of the client.
                        </Typography>

                    </Box>



                    <Box className='whitebx' mt={1}  >

                        <Typography variant='h4' className='blue' >The solution that saved $250,000 annually</Typography>


                        <Typography variant='h6' className='black jst'> The solution in its entirety was highly-beneficial for the client. DPA’s result-oriented approach finally led to a 360-system transformation, helping the client shift reliance on the self-propriety master database. The team achieved the desired result (a clean security master database) in a stipulated timeline of a month - an exemplary instance.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The key highlight is the company's annual cost reduction of $250,000. The figure constitutes an estimated 1% of the client’s yearly revenue.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            In addition, the well-executed solution led to programming expertise & technical advancement and ensured the inclusion of all stock-related in the final report. The client’s team had better data reliability and definite data with the master database compared to the third-party database. All-round process strengthening resulted in faster turnarounds for the client.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            A successful trial of delivered service showcasing an improved output accuracy proves it. DPA’s service excellence ushered in to smooth transition of in-house processes & effective elimination of the third-party database.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            If you also want to implement such systematized solutions to ease the processes and increase revenue - connect with us here!
                        </Typography>


                        <Link to='/contact-us' className='link'>
                            <Typography variant='h6' className='blue jst'>
                                Let’s talk!
                            </Typography>
                        </Link>

                    </Box>



                    <Box className='graybbx' mt={1} >
                        <Grid container spacing={1} alignItems="top" >
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <Box className='al_left'>
                                    <Typography variant='h4' className='black'>
                                        Explore More:
                                    </Typography>

                                    <Link to='/case-studies/salesforce-automation-to-simplify-your -complex-processes' className='link'>
                                        <Typography variant='h6' className='black jst' style={{ marginBottom: '0px' }}>
                                            <b>Next Case Study: </b> Salesforce Automation to Simplify Your Complex Processes.

                                        </Typography>
                                    </Link>

                                    <Link to='/articles/data-visualization-and-the-art-of-effective-storytelling' className='link'>
                                        <Typography variant='h6' className='black jst'>
                                            <b>Insights: </b> Data Visualization and the Art of Effective Storytelling.
                                        </Typography>
                                    </Link>

                                </Box>
                            </Grid>
                        </Grid>

                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}


                    </Box>


                    <Casestudyback />




                </Container>
            </Box>


        </>
    );
}
