import { Container, Grid, Typography, Box, IconButton, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from '../../Component/Writemessage3'
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';

import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

import adv1 from "../../asset/images/product/creditpulse/adv1.svg";
import adv2 from "../../asset/images/product/creditpulse/adv2.svg";
import adv3 from "../../asset/images/product/creditpulse/adv3.svg";
import adv4 from "../../asset/images/product/creditpulse/adv4.svg";
import adv5 from "../../asset/images/product/creditpulse/adv5.svg";
import adv6 from "../../asset/images/product/creditpulse/adv6.svg";

import Approach from "../../asset/images/product/creditpulse/credit_pulse_approach.png";
import whyCP from "../../asset/images/product/creditpulse/why_credit_pulse.png"

import ProductCTAA from '../../Component/ProductCTAA';
import CreditpulseFaq from './Creditpulse_components/CreditpulseFaq';


import Exp_non_trad from "../../asset/images/product/creditpulse/company_credit_risk_insight.png"

import CreditPulse from "../../asset/images/new_banner/credit_pulse_banner.jpg"

export default function Creditpulse() {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };



    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title> Credit Pulse - Proactive Risk Management Solutions </title>
                <meta name="description" content="Empower your investment decisions with Credit Pulse. Utilize machine learning for accurate credit risk assessments. Book a free demo now!" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/creditpulse" />
                <meta name="author" content="Decimal Point Analytics" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                <script type="application/ld+json">
                    {`
        {
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name": "Credit Pulse",
            "image": "https://www.decimalpointanalytics.com/static/media/creditpulsebanner.762b407123624879f6a2.gif",
            "description": "CreditPulse is based on the underlying principle that textual analysis offers better insights over number crunching. We employ Machine Learning techniques to carry out textual analysis of recent SEC filings with those of bankrupt companies just before their bankruptcy filing.
          Our daily output then categorizes companies into either 'High Risk' or 'Low Risk' for the upcoming two to three quarters.
          CreditPulse has been built and tested on 10 years of real market data and our model has given 90% plus accuracy on the test datasets.",
            "brand": {
              "@type": "Brand",
              "name": "Decimal Point Analytics"
            }
          }
                
          `}
                </script>
            </Helmet>


            <Box id='product-page'>
                {/* <Box className='mainsection' component='section'>
                    <Container maxWidth='lg'>
                        <Grid container spacing={4} alignItems="center">
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Box>
                                    <Typography variant='h1' className='blue'>
                                        Credit Pulse
                                    </Typography>

                                    <Typography variant='h4' className='black'>
                                        Harness the Power of Textual Analysis for Proactive Risk Management
                                    </Typography>

                                    <Box className='bt_button' mt={2}>
                                        <a target="_blank" rel="noopener noreferrer" href="https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/CreditPulse%2FCreditPulse_Latest_Report.pdf">
                                            <Button variant="contained" className='dpabtn_sm1'>
                                                Download The Latest Report
                                            </Button>
                                        </a>
                                    </Box>

                                </Box>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Box className='al_right'>
                                    <Box className="creditvdo" style={{ backgroundImage: 'url(' + landing + ')', }}>
                                        <IconButton className="pulse-button pulse_button_2" onClick={openModal} >
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box> */}

                <Box className="banner_bx">
                    <Box style={{ backgroundImage: "url(" + CreditPulse + ")" }} className="banner_img">
                        <Box className="img_over bl_overlay">
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>
                                <Grid item lg={3} md={4} sm={6} xs={10}>
                                    <Box className="al_center">
                                        <Typography className="white" variant="h1">Credit Pulse</Typography>
                                        <Box mt={'10px'}>
                                            <Typography className="white" variant="h4">Harness the Power of Textual Analysis for Proactive Risk Management</Typography>
                                            <Box className='banner_btns'>
                                                <Box className='bt_button'>
                                                    <a target="_blank" rel="noopener noreferrer" href="
https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/CreditPulse%2FCreditPulse_Latest_Report.pdf">
                                                        <Button variant="contained" className='dpabtn_sm1'>
                                                            Download The Latest Report
                                                        </Button>
                                                    </a>
                                                </Box>
                                                <Box className='banner_pulse'>
                                                    <IconButton className="header_pulse_button" onClick={openModal} >
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>

                <Box className='section2 sec_bg' component='section'>
                    <Container maxWidth='lg' className='bdr'>

                        {/* <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        What is Credit Pulse ?
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid> */}


                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center synopsic_bx">
                                    <Typography variant='h6' className='black jst'>
                                        Credit Pulse, an innovative credit analysis platform that utilizes cutting-edge machine learning algorithms to comprehensively analyze textual information extracted from regulatory filings by efficiently identifying credit default and bankruptcy risks. Credit Pulse empowers financial institutes with a significant competitive edge when making crucial investment decisions
                                    </Typography>

                                    <Typography variant='h6' className='black jst'>
                                        With 10 years of real market data as its foundation, Credit Pulse has undergone rigorous testing to deliver a comprehensive and impartial analysis, achieving a remarkable accuracy rate of over 90% on test datasets. Our extensive database now encompasses a 13-year history. With a highly trained ML algorithm based on data from over 7,000 companies and 54,000 filings, Credit Pulse delivers accurate risk assessments, offering valuable insights one to two quarters ahead of potential risks. By solely focusing on text information from filings, Credit Pulse uncovers underlying financial stress that traditional analytical tools may miss. The platform's real-time analysis, available within four minutes of SEC filings, empowers risk management professionals and hedge fund managers to make informed decisions and mitigate risks proactively.
                                    </Typography>

                                </Box>

                                <Box mt={2}>
                                    <ProductCTAA allignment="flx_fs" cta_content=" Experience the power of Credit Pulse firsthand." cta_btn_name="Book Your Free Demo Now!" pageVisited="Credit Pulse" />
                                </Box>

                            </Grid>
                        </Grid>
                    </Container>
                </Box>


                <Box className="section mob_card" component="section">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center">
                                    <Typography className="black" variant="h2"> The Credit Pulse Advantage:
                                        <Typography variant="h2" className="black h2v2"> Empowering Smarter Investment Decisions </Typography>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box mt={3}>
                            <Grid container spacing={2} alignItems="top">
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img src={adv1} className="bxicon" alt="ECO1" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two">
                                                    Tailored Portfolio Tracking:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black jst'>
                                                    Customize Credit Pulse to monitor specific companies or indices relevant to your investment strategy, such as S&P 500 or Russell 2000
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img src={adv2} className="bxicon" alt="ECO1" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two">
                                                    Adjustable Thresholds:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black jst'>
                                                    Apply flexible thresholds that align with your unique investment strategy, ensuring accurate risk categorizations tailored to your requirements
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img src={adv3} className="bxicon" alt="ECO1" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two" >
                                                    Real-Time Results:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black jst'>
                                                    Access up-to-date information and make timely decisions with Credit Pulse's efficient processing of ongoing filings, providing real-time results
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img src={adv4} className="bxicon" alt="ECO1" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two" >
                                                    Seamless Integration:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black jst'>
                                                    Integrate Credit Pulse seamlessly into your existing systems using its REST API, enabling easy data access and incorporation into your preferred workflow
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img src={adv5} className="bxicon" alt="ECO1" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two" >
                                                    Advance Indications of Underlying Stress:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black jst'>
                                                    Receive analysis within two minutes of filing, allowing you to stay ahead of potential risks and make proactive decisions
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>


                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img src={adv6} className="bxicon" alt="ECO1" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two" >
                                                    Robust Analysis and Reliability:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black jst'>
                                                    Credit Pulse's ability to detect subtle patterns and resistance to manipulation ensures robust analysis and reliable insights into credit risk.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>


                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box mt={2}>
                                        <Typography variant='h6' className='black al_center'>
                                            <a href="/contact-us#contact_form" className='link'><strong>Unlock the Credit Pulse</strong></a> advantage to enhance your risk management capabilities with demonstrated accuracy levels of over 90% for Credit Score and over 80% for Upgrade & Downgrade Scores, ensuring reliable and trustworthy results for informed investment decisions
                                        </Typography>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>
                    </Container>
                </Box>


                <Box className="section sec_bg" component="section">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={8} md={10} sm={12} xs={12}>
                                <Box className="al_center" pb={4}>
                                    <Typography className="black" variant="h2"> Transform Your Approach with
                                        <Typography variant="h2" className="black h2v2"> Credit Pulse's State-of-the-Art Features </Typography>
                                    </Typography>
                                </Box>

                                <Box className="al_center">
                                    <img src={Approach} className="topbanner2" alt="Banner2" />
                                </Box>

                                <Box mt={3}>
                                    <ProductCTAA allignment="al_center" cta_content="Experience the revolutionary capabilities of Credit Pulse and gain a competitive edge in the market through its powerful credit analysis tools." cta_btn_name="Book a Free Demo" pageVisited="Credit Pulse" />
                                </Box>

                            </Grid>
                        </Grid>
                    </Container>
                </Box>


                <Box className="section" component="section">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center">
                                    <Typography className="black" variant="h2">
                                        Why Credit Pulse?
                                    </Typography>
                                </Box>
                            </Grid>



                            <Grid item lg={5} md={5} sm={6} xs={12}>
                                <Box className='al_center'>
                                    <img src={whyCP} className='topbanner' alt='Why Credit Pulse ?' />
                                </Box>
                            </Grid>



                            <Grid item lg={7} md={7} sm={6} xs={12}>
                                <Box>
                                    <Typography className="blue" variant="h6">

                                        <ul>
                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom> <strong> Advanced Analytics: </strong> Credit Pulse utilizes cutting-edge analytics and machine learning algorithms to provide a sophisticated credit risk assessment. By leveraging the power of data, it delivers comprehensive insights into creditworthiness.</Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom> <strong> Comprehensive Evaluation: </strong> Unlike traditional methods, Credit Pulse considers a wide range of data sources and variables, enabling a holistic evaluation of credit risk. This comprehensive approach enhances the accuracy and reliability of credit assessments. </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom> <strong> Early Warning Signals: </strong> Credit Pulse is equipped with early warning signal capabilities that allow you to identify potential credit deterioration in its early stages. By detecting warning signs and patterns, you can proactively address and mitigate risks before they escalate. </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom> <strong> Proactive Risk Management: </strong> With Credit Pulse, you can take proactive measures to manage credit risk effectively. By monitoring key indicators and analyzing historical trends, you gain actionable insights that enable you to make informed decisions and stay ahead of potential risks. </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom> <strong> Financial Well-being: </strong> Credit Pulse is designed to protect your institution's financial well-being. By providing advanced risk management tools and enhancing credit risk assessment, it helps safeguard your investments and maintain a strong financial position. </Typography>
                                            </li>

                                        </ul>

                                    </Typography>
                                </Box>
                            </Grid>


                            <Grid item lg={10} md={10} sm={10} xs={10}>
                                <Box mt={3}>
                                    <ProductCTAA allignment="al_center" cta_content="Experience the power of Credit Pulse and unlock the potential for smarter credit risk management. Schedule a live demonstration with our experts today to see how Credit Pulse can benefit your organization." cta_btn_name="Schedule a Live Demonstration" pageVisited="Credit Pulse" />
                                </Box>
                            </Grid>



                        </Grid>
                    </Container>
                </Box>



                <Box className="section sec_bg" component="section">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center">
                                    <Typography className="black" variant="h2">
                                        Credit Pulse Insights
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} alignItems="center" justifyContent='center'>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='reportinfo'>
                                    <Link to="/blog/non-traditional-to-access-credit-risk">
                                        <Box className="reportm_bg">
                                            <Box
                                                className="reportimg_bg"
                                                style={{
                                                    backgroundImage: 'url(' + Exp_non_trad + ')',
                                                }}
                                            >
                                            </Box>

                                            <Box mt={4} mb={2}>
                                                <Typography className="reporttitle two">
                                                    Exploring Non-traditional Ways to
                                                    Assess Company Credit Risk
                                                </Typography>
                                            </Box>
                                            <Box className="line"></Box>
                                        </Box>
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>





                <Box className='section' component='section'>
                    <Container maxWidth='lg' className='bdr'>
                        <CreditpulseFaq />
                    </Container>
                </Box>




                <Writemessage3 pageVisited="Credit Pulse" />

            </Box>

            <ModalVideo
                channel="youtube"
                isOpen={isOpen}
                videoId="1o7DlE35IS4"
                onClose={() => setIsOpen(false)}
            />



        </>
    );
}
