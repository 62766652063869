import { Container, Grid, Typography, Box, Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import Yahoo from '../../../asset/images/media/yahoo.jpg';
import IO from '../../../asset/images/media/investor_observer.png';
import MorningStar from '../../../asset/images/media/morningstar.png';
import MarketWatch from '../../../asset/images/media/marketwatch.png';

import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';
import OracleImg from "../../../asset/images/media/press_release/oracle-award-image.jpg"


import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';



export default function OracleGlobalLeaders() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>DPA Receives Intel Oracle Global Leaders Award for ISV of the Year 2023</title>
                <meta name="description" content="Discover how Decimal Point Analytics won the Intel Oracle Global Leaders Award for ISV of the Year 2023 in the APAC region. Explore their innovative solutions." />

                <meta name="keywords" content="Decimal Point Analytics, Intel Oracle Global Leaders Award, ISV of the Year, Oracle Cloud, APAC region, Innovation, Data Solutions, Advanced Analytics" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/oracle-global-leaders-recognizes-decimal-point-analytics-as-isv-of-the-year-2023" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Oracle Global Leaders Recognizes Decimal Point Analytics as ISV of the Year 2023
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <Link to="/" className="link" >Decimal Point Analytics</Link>, a leading provider of advanced analytics, research, and data solutions, is proud to announce that it has received the prestigious Intel Oracle Global Leaders Award for ISV of the Year in the APAC region for the year 2023. This award recognizes Decimal Point Analytics for its exceptional contributions in leveraging Oracle cloud technologies to drive innovation and deliver outstanding solutions to clients across the globe. </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The Intel Oracle Global Leaders Award is an industry-leading recognition that celebrates champions   for their outstanding accomplishments and success in deploying Oracle Data platform solutions. The Intel Oracle Global Leaders ISV award is awarded to an Independent Software Vendor (ISV) for remarkable achievements and dedication to embrace latest Oracle Database and cloud technologies to offer innovative services to their customers. This year, Decimal Point Analytics stands out as a prime example of excellence for the innovative Oracle solution implementation and migration. </Typography>



                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Decimal Point Analytics has consistently showcased an unwavering commitment to excellence, delivering state-of-the-art solutions that enable businesses across diverse sectors to harness the full potential of Oracle’s JSON Document Store and autonomous Database. By adeptly leveraging Oracle's advanced technologies, Decimal Point Analytics has empowered organizations to make informed, data-driven decisions, optimize their operations, and drive growth.
                                    </Typography>


                                    <Grid container direction="row" justifyContent="center" spacing={2} alignItems="top">
                                        <Grid item lg={6} md={12} sm={12} xs={12}>
                                            <Box pt={3} pb={3}>
                                                <img src={OracleImg} className='fullimg' alt='Oracle Banner' />
                                                <Box>
                                                    <Typography variant='h6' className='imgsource-txt jst black'>
                                                        In the photo, from left to right: &nbsp;
                                                        <span className='blue fw5'>Çetin Özbütün</span>, Executive Vice President, Oracle; &nbsp;
                                                        <span className='blue fw5'>Andy Mendelsohn</span>, Executive Vice President, Oracle; &nbsp;
                                                        <span className='blue fw5'>Shailesh Dhuri</span>, Chief Executive Officer, Decimal Point Analytics; &nbsp;
                                                        <span className='blue fw5'>Andrea Carbajal</span>, Global Sales Development Manager, Intel Corporation; &nbsp;
                                                        <span className='blue fw5'>Juan Loaiza</span>, Executive Vice President, Oracle.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>

                                    </Grid>


                                    <Typography variant='h4' className='blue jst' gutterBottom>
                                        Shailesh Dhuri, CEO of Decimal Point Analytics, after receiving this prestigious award, said:
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        "We are truly honored to receive the Intel Oracle Global Leaders Award for ISV of the Year in the APAC region for the year 2023. This recognition is a testament to the hard work and dedication of our team at Decimal Point Analytics, who have consistently pushed the boundaries of innovation to deliver exceptional solutions to our clients. We are proud to partner with Oracle Cloud to harness their cutting-edge technologies, and this award motivates us to continue our pursuit of excellence in innovative solutions. Oracle's state-of-the-art technologies have helped us push the boundaries of our solutions for our clients, adding tremendous value for our customers."</Typography>


                                    <Typography variant='h6' className='black jst' gutterBottom> The Intel Oracle Global Leaders Award is a testament to <Link to="/" className="link" >Decimal Point Analytics</Link>' commitment to staying at the forefront of technology and delivering value-driven solutions to its clients. </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        For more information about Decimal Point Analytics please visit <Link to="/" className="link"> decimalpointanalytics.com</Link>
                                    </Typography>


                                    <Box pt={2}>

                                        <Typography variant='h4' className='black jst' gutterBottom>
                                            For media inquiries, please contact:
                                        </Typography>
                                        <Box pt={1}>
                                            <Typography variant='h4' className='blue al_left'>
                                                Mr. Sarath Nair
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Box pt={1}>
                                            <Button
                                                disableRipple
                                                className="txtico"
                                                disabled
                                            >
                                                Vice President Marketing, <br /> Decimal Point Analytics Pvt. Ltd.
                                            </Button>
                                        </Box>

                                        <Box className='txt-iconbtn'>
                                            <a href="mailto:sarath.nair@decimalpointanalytics.com" className="black">
                                                <Button
                                                    startIcon={<MailOutlinedIcon className="bl" />}
                                                    disableRipple
                                                    className="txtico link"
                                                    disableFocusRipple
                                                    disableElevation
                                                >
                                                    sarath.nair@decimalpointanalytics.com
                                                </Button>
                                            </a>
                                        </Box>

                                        <Box className='txt-iconbtn'>
                                            <a href="tel:+19173101906" className="black">
                                                <Button
                                                    startIcon={<CallOutlinedIcon className="bl" />}
                                                    disableRipple
                                                    className="txtico link"
                                                >
                                                    +91 9372821410
                                                </Button>
                                            </a>
                                        </Box>
                                    </Box>


                                </Box>

                            </Grid>
                        </Grid>
                    </Box>



                    <Box className='graybbx' mt={2}>
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>

                            <Grid item lg={3} md={3} sm={3} xs={6}>
                                <a href="https://finance.yahoo.com/news/oracle-global-leaders-recognizes-decimal-130000999.html" target="_blank" rel="noopener noreferrer">
                                    <Box
                                        className="awardsindexcircle"
                                        style={{ backgroundImage: "url(" + Yahoo + ")" }}
                                    ></Box>
                                </a>
                            </Grid>

                            <Grid item lg={3} md={3} sm={3} xs={6}>
                                <a href="https://www.investorsobserver.com/news/qm-pr/4768960164235458" target="_blank" rel="noopener noreferrer">
                                    <Box
                                        className="awardsindexcircle"
                                        style={{ backgroundImage: "url(" + IO + ")" }}
                                    ></Box>
                                </a>
                            </Grid>

                            <Grid item lg={3} md={3} sm={3} xs={6}>
                                <a href="https://www.morningstar.com/news/pr-newswire/20230927io11005/oracle-global-leaders-recognizes-decimal-point-analytics-as-isv-of-the-year-2023" target="_blank" rel="noopener noreferrer">
                                    <Box
                                        className="awardsindexcircle"
                                        style={{ backgroundImage: "url(" + MorningStar + ")" }}
                                    ></Box>
                                </a>
                            </Grid>

                            <Grid item lg={3} md={3} sm={3} xs={6}>
                                <a href="https://www.marketwatch.com/press-release/oracle-global-leaders-recognizes-decimal-point-analytics-as-isv-of-the-year-2023-9bdebdeb?mod=search_headline" target="_blank" rel="noopener noreferrer">
                                    <Box
                                        className="awardsindexcircle"
                                        style={{ backgroundImage: "url(" + MarketWatch + ")" }}
                                    ></Box>
                                </a>
                            </Grid>


                        </Grid>
                    </Box>



                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
