import {
    Container,
    Grid,
    Typography,
    Box,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";

import Case_studies_Data from './Case_studies_Data';
import { Helmet } from "react-helmet";


export default function Case_studies() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <title>Case Studies | Decimal Point Analytics </title>
                <meta name="description" content="Explore our case studies showcasing real world applications of innovative solutions. See how our expertise drives tangible business success." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies" />
            </Helmet>

            <Box
                className="section sec_bg"
                component="section"
                style={{ paddingTop: "3%" }}
            >

                <Container maxWidth="lg" className="bdr">
                    <Box>
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center" mt={5}>
                                    <Typography variant="h1" className="black newh1">
                                        Success Stories
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center synopsic_bx" mb={3}>
                                    <Typography variant="h6" className="black jst" gutterBottom>
                                        At the heart of our success lie the triumphs of our clients, where strategic brilliance meets and harmonizes with forward-thinking progress.
                                    </Typography>

                                    <Typography variant="h6" className="black jst">
                                        Our client success stories are not just tales of achievement; they are blueprints for navigating the complexities of the financial world and unlocking remarkable outcomes.
                                    </Typography>

                                    <Typography variant="h6" className="black jst">
                                        Through meticulous analysis and an unwavering commitment to excellence, we've empowered our clients to turn challenges into actionable strategies, propelling their businesses to new heights of success.
                                    </Typography>

                                    <Typography variant="h6" className="black jst">
                                        Explore these compelling narratives and witness the transformative power of collaboration & innovation.
                                    </Typography>
                                </Box>
                            </Grid>


                        </Grid>
                    </Box>
                </Container>


                <Box className="reportinfo">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={1} alignItems="top">
                            {Case_studies_Data.map((item, index) => (
                                <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                                    {item.linking === 'external' ? (
                                        <a href={item.link} target="_blank" rel="noopener noreferrer" className="external-link">
                                            <Box className="reportm_bg">
                                                <Box
                                                    className="reportimg_bg"
                                                    style={{
                                                        backgroundImage: `url(${item.imgsrc})`,
                                                    }}
                                                ></Box>
                                                <Box className="reporttitle_box">
                                                    <Typography className="reporttitle two" title={item.heading}>
                                                        {item.heading}
                                                    </Typography>
                                                </Box>
                                                <Box className="line"></Box>
                                                <Box className="reporttitle_box">
                                                    <Typography variant="h6" className="black jst thr" title={item.subheading}>
                                                        {item.subheading}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </a>
                                    ) : (
                                        <Link to={item.link}>
                                            <Box className="reportm_bg">
                                                <Box
                                                    className="reportimg_bg"
                                                    style={{
                                                        backgroundImage: `url(${item.imgsrc})`,
                                                    }}
                                                ></Box>
                                                <Box className="reporttitle_box">
                                                    <Typography className="reporttitle two" title={item.heading}>
                                                        {item.heading}
                                                    </Typography>
                                                </Box>
                                                <Box className="line"></Box>
                                                <Box className="reporttitle_box">
                                                    <Typography variant="h6" className="black jst thr" title={item.subheading}>
                                                        {item.subheading}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Link>
                                    )}
                                </Grid>
                            ))}


                        </Grid>
                    </Container>
                </Box>
            </Box>


        </>
    );
}
