import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import EC from '../../../asset/images/media/express_computers.png';
import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';


export default function CentralBankDigitalCurrency() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Central Bank Digital Currency (CBDC) Enabled FX Transactions </title>
                <meta name="description" content="Explore how CBDCs and DeFi revolutionize FX transactions. Learn about the benefits and challenges with innovative solutions from Decimal Point Analytics." />

                <meta name="keywords" content="Central Bank Digital Currency, CBDC, FX Transactions, Decentralized Finance, DeFi, Machine Readable Regulations, MRR, Financial Inclusion, Currency Movement, Foreign Exchange, DLT, Smart Contracts, Decimal Point Analytics, Shailesh Dhuri, CEO, Financial Technology, Transparent Fund Distribution, Interoperability, Wholesale CBDC, Retail CBDC, International Transactions, Security Features, Compliance, Digital Currencies, Financial Market, Currency Exchange, Monetary Policy, Financial Cost, Financial Programs, Transnational Network, Transaction Costs, Ownership, Dispute Settlement, Financial Implications, Technology Innovation, Direct Benefit Schemes, Financial Instruments, Trust, Compliance Confidence" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/central-bank-digital-currency-enabled-fx-transactions" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Central Bank Digital Currency (CBDC) Enabled FX Transactions: An Opportunity to Facilitate Currency Movement
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Foreign Exchange (FX) is the largest financial market in the world, an over-the-counter marketplace which determines the exchange rate of currencies. According to a recent report by the BIS, approximately, USD 7.5 trillion of FX trading occurs in a day globally. FX is traded through a network of international dealers and brokers, of which central banks are the biggest entities.
                                    </Typography>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Although, central banks are responsible for formulating and implementing the monetary policy and regulating the currency flow of a country, they themselves rely on intermediaries for FX transactions. This reliance comes with security concerns, high dependency, and significant financial cost which limits a country’s ability to execute international transactions.
                                    </Typography>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Given the sheer size and complexity of the FX universe, there is an urgent need to simplify trades, strengthen the confidence in compliance and reduce transaction costs. This is where Web 3.0 and particularly Decentralized Finance (DeFi) comes to the fore. DeFi’s focus on a more transparent financial system will enhance the FX value chain by overcoming the aforementioned challenges. DeFi, which operates on the principle of Distributed Ledger Technology (DLT), is built on top of existing smart contracts, which makes the process simple, fast, and the same time cost effective. Moreover, it also provides a high level of trust among the participants.
                                    </Typography>


                                    {/* <Typography variant='h6' className='black jst' gutterBottom>
                                       
                                    </Typography> */}


                                    <Typography variant='h6' className='black jst'>
                                        Central banks across the world are introducing digital currencies (CBDC) to develop an ecosystem free from intermediaries. CBDC is an efficient way to execute financial programs in countries that could directly impact people. CBDCs can be broadly classified as:
                                        <ul>
                                            <li>Wholesale CBDC (wCBDC) - for intermediaries’ transactions</li>
                                            <li>Retail CBDC - local transactions at granular level</li>
                                        </ul>
                                    </Typography>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        While Retail CBDCs majorly depend on a country’s domestic platforms for the execution and implementation of smart contracts, Wholesale CBDCs depend on other countries to become effective. wCBDC requires the development of a transnational network to host smart contracts where CBDCs from domestic platforms can be relayed to transnational platforms to instantly execute smart contracts enabling the exchange of two or more CBDCs. This rule-based platform can also be used to execute CBDCs encoded with a specific purpose, a security feature underpinning suspicious transaction (illegal or terror funding). Smart contracts on the transnational platform will facilitate interoperability of CBDCs.
                                    </Typography>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Further, central banks could introduce Machine Readable Regulations (MRR) embedded in the DLT, thereby reducing the cost of compliance, while enhancing confidence in compliance at the same time. With zero reliance on intermediaries, countries will have the freedom to execute FX transactions as per their needs and will.
                                    </Typography>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        CBDC-enabled FX transactions thus add multiple features to the ecosystem:
                                        <ul>
                                            <li>
                                                <strong>Access Control:</strong> Each central bank can control the access to its wCBDCs by maintaining an ‘allow lists’ for domestic platforms and transnational platforms, respectively.
                                            </li>
                                            <li>
                                                <strong>Pause Transactions:</strong> In the event of operational disruptions, individual central banks have the authority to temporarily halt transactions related to their wCBDC. This pause encompasses the cessation of wCBDC issuance, redemption, recovery processes, and the updating of associated allow lists.
                                            </li>

                                            <li>
                                                <strong> Upgradeability:</strong> Central banks have the capability to enhance their wCBDC smart contracts seamlessly, ensuring that wCBDCs can stay in circulation without operational disruptions, even as features are modified, added, or removed.
                                            </li>
                                        </ul>
                                    </Typography>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        While CBDCs are like to be a game changer for the global financial eco system, implementation and success will depend on how central banks work through the challenges of ownership and dispute settlement. Developing and deploying a robust system to cater such issues effectively, without having any financial implication on involved entities in the transactions, is another challenge to tackle.
                                    </Typography>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        DeFi has the potential to extend the accessibility of financial instruments, thereby promoting financial inclusion. CBDCs can be a powerful tool to enhance government-sponsored direct benefit schemes, ensuring more efficient and transparent fund distribution. However, effective implementation of will require fool proof MRRs and central banks will have to collaborate with experienced partners in the field to ensure the success of CBDC-enabled FX transactions.
                                    </Typography>


                                </Box>

                            </Grid>
                        </Grid>
                    </Box>


                    <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>

                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <a href="https://www.expresscomputer.in/guest-blogs/central-bank-digital-currency-cbdc-enabled-fx-transactions-an-opportunity-to-facilitate-currency-movement/106117/" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + EC + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>

                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>



        </>
    );
}
