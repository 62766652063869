import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import val1 from "../../asset/images/aboutus/val1.svg";
import val2 from "../../asset/images/aboutus/val2.svg";
import val3 from "../../asset/images/aboutus/val3.svg";
import val4 from "../../asset/images/aboutus/val4.svg";

import Extracting from "../../asset/images/new_banner/extracting_banner.jpg"
import { Helmet } from 'react-helmet';

export default function Valueproposition() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);





    return (
        <>

            <Helmet>
                <title>Value Proposition | Decimal Point Analytics </title>
                <meta name="description" content="Explore our tailored analytics solutions to optimize strategies and drive growth. Decimal Point Analytics unlocks insights for informed decision-making" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/valueproposition" />
            </Helmet>

            {/* <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Extracting critical information
                                </Typography>

                                <Typography variant='h4' className='black'>
                                    Generating business value while using time and resources optimally
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12}>
                            <Box className='al_right'>
                                <img src={indexbanner} className='topbanner' alt='Index Banner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}

            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + Extracting + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={5} md={6} sm={8} xs={10}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Extracting Critical Information</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">Generating Business Value While Using Time and Resources Optimally</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    {/* <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Value Proposition
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid> */}


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center synopsic_bx">
                                <Typography variant='h6' className='black jst'>
                                    To the finance industry professionals who are looking to seek ideas to deliver superior results, we offer a number of tailor-made solutions. Our flexible, scalable and cost-effective research and technology solutions enable our customers to free-up in-house time and efforts for more strategic and client-facing activities. We facilitate harnessing of relevant insights amidst the ‘information overload’ occurring nowadays. With our support, our customers are also able to test out new ideas and create new business opportunities.
                                </Typography>
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>



            <Box className='section ourstrength ' component='section'>
                <Box className='over'></Box>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>
                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Our Advantage
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='mob_card' mt={3}>
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='str_bg mnhg2'>
                                    <Box mb={1}>
                                        <img src={val1} className='bxicon' alt='val1' />
                                    </Box>
                                    <Box mt={2}>
                                        <Typography variant='h6' className='black al_center'>
                                            Actionable Intelligence provided in <strong>Real-Time</strong>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='str_bg mnhg2'>
                                    <Box mb={1}>
                                        <img src={val2} className='bxicon' alt='val2' />
                                    </Box>
                                    <Box mt={2}>
                                        <Typography variant='h6' className='black al_center'>
                                            Powered by technology; Delivering efficient <strong>Automation</strong>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='str_bg mnhg2'>
                                    <Box mb={1}>
                                        <img src={val3} className='bxicon' alt='val3' />
                                    </Box>
                                    <Box mt={2}>
                                        <Typography variant='h6' className='black al_center'>
                                            Proven Methodologies… <strong>Quality Focus…</strong> Stringent Timelines
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='str_bg mnhg2'>
                                    <Box mb={1}>
                                        <img src={val4} className='bxicon' alt='val4' />
                                    </Box>
                                    <Box mt={2}>
                                        <Typography variant='h6' className='black al_center'>
                                            Customized deliverables; white label research – everything as per your <strong>Business Requirement</strong>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='str_bg mnhg2'>
                                    <Box mb={1}>
                                        <img src={val4} className='bxicon' alt='val4' />
                                    </Box>
                                    <Box mt={2}>
                                        <Typography variant='h6' className='black al_center'>
                                            Grooming High-end <strong>Knowledge workforce</strong> - 70% of our employees have a Post Graduate Degree in Finance
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='str_bg mnhg2'>
                                    <Box mb={1}>
                                        <img src={val4} className='bxicon' alt='val4' />
                                    </Box>
                                    <Box mt={2}>
                                        <Typography variant='h6' className='black al_center'>
                                            Constantly augmenting our capability – both in terms of domain <strong>skills and technology</strong> infrastructure
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>



                </Container>

            </Box>


        </>
    );
}
