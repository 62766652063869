import case1 from "../../asset/images/insight/case_studies/case_studies_thumbnails/nav_calculations.png";
import case2 from "../../asset/images/insight/case_studies/case_studies_thumbnails/intelligent_doc_processing.png";
import case3 from "../../asset/images/insight/case_studies/case_studies_thumbnails/solution_for_crm.png";
import case4 from "../../asset/images/insight/case_studies/case_studies_thumbnails/response_management.png";
import case5 from "../../asset/images/insight/case_studies/case_studies_thumbnails/portfolio_monitoring.png";
import case6 from "../../asset/images/insight/case_studies/case_studies_thumbnails/robotics_process.png";
import case7 from "../../asset/images/insight/case_studies/case_studies_thumbnails/collatoral_management.png";
import case8 from "../../asset/images/insight/case_studies/case_studies_thumbnails/intelligent_dashboard.png";


import case13 from "../../asset/images/insight/case_studies/case_studies_thumbnails/offering_solutions.png";
import case12 from "../../asset/images/insight/case_studies/case_studies_thumbnails/cutting_edge.png";
import case11 from "../../asset/images/insight/case_studies/case_studies_thumbnails/auto_and_custom.png";
import case10 from "../../asset/images/insight/case_studies/case_studies_thumbnails/process_and_cost.png";
import case9 from "../../asset/images/insight/case_studies/case_studies_thumbnails/fraud_detection.png";

import case15 from "../../asset/images/insight/case_studies/case_studies_thumbnails/quotemedia.png";
import case16 from "../../asset/images/insight/case_studies/case_studies_thumbnails/salesforce.png";


import case17 from "../../asset/images/insight/case_studies/case_studies_thumbnails/extraction_ghg.png";
import case18 from "../../asset/images/insight/case_studies/case_studies_thumbnails/automateddatawarehouse_thumbnail.png";


import case19 from "../../asset/images/insight/case_studies/case_studies_thumbnails/security_level_database.png";
import case20 from "../../asset/images/insight/case_studies/case_studies_thumbnails/technical_support.png";

import case21 from "../../asset/images/insight/case_studies/case_studies_thumbnails/database_management_for_enhanced.png";


import case23 from "../../asset/images/insight/case_studies/case_studies_thumbnails/driving_investment_banking.png";
import case24 from "../../asset/images/insight/case_studies/case_studies_thumbnails/how-ai-driven-automation.png";
import case25 from "../../asset/images/insight/case_studies/case_studies_thumbnails/enhanced_esg.jpg";
import case26 from "../../asset/images/insight/case_studies/case_studies_thumbnails/advanced-fund-accounting-solutions.jpg";

import case27 from "../../asset/images/insight/case_studies/case_studies_thumbnails/investment.png";
import case28 from "../../asset/images/insight/case_studies/case_studies_thumbnails/risk_management.png";
import case29 from "../../asset/images/insight/case_studies/case_studies_thumbnails/revolutionizing_financial_data.png"
import case30 from "../../asset/images/insight/case_studies/case_studies_thumbnails/revolutionising_machinery.png"
import case31 from "../../asset/images/insight/case_studies/case_studies_thumbnails/enhancing-business-intelligence.png"
import case32 from "../../asset/images/insight/case_studies/case_studies_thumbnails/wealth_management.png"
import case33 from "../../asset/images/insight/case_studies/case_studies_thumbnails/enhance_asset.png"
import case34 from "../../asset/images/insight/case_studies/case_studies_thumbnails/automated_data.png"
import case35 from "../../asset/images/insight/case_studies/case_studies_thumbnails/lending-company-transforms.png"
import case36 from "../../asset/images/insight/case_studies/case_studies_thumbnails/overcoming-data-challenges.png"
import case37 from "../../asset/images/insight/case_studies/case_studies_thumbnails/rent_roll.png"
import case38 from "../../asset/images/insight/case_studies/case_studies_thumbnails/enhancing_portfolio.png"


const Case_studies_Data = [
    {
        link: '/case-studies/enhancing-portfolio-management-through-advanced-analytics-and-machine-learning',
        imgsrc: case38,
        heading: "Enhancing Portfolio Management through Advanced Analytics and Machine Learning",
        subheading: "Explore how a US event analytics provider improved portfolio management by tackling manual data processing challenges with an advanced machine learning platform, boosting accuracy, efficiency, and decision-making."
    },
    {
        link: '/case-studies/transforming-rent-roll-data-management-efficiency-and-automation-with-dpas-data-ingestion-platform',
        imgsrc: case37,
        heading: "Transforming Rent Roll Data Management: Efficiency and Automation with DPA’s Data Ingestion Platform",
        subheading: "Discover how a US asset management firm transformed its rent roll data management with an automated platform, enhancing efficiency and enabling more accurate, data-driven decisions. They reduced manual processing errors and high costs."
    },
    {
        link: '/case-studies/overcoming-data-challenges-to-build-a-credible-esg-index',
        imgsrc: case36,
        heading: "Overcoming Data Challenges to Build a Credible ESG Index",
        subheading: "Discover how Decimal Point Analytics helped an Indian financial index service provider overcome ESG data collection challenges to build a credible ESG index. Learn about their innovative solutions in data management and quality control, ensuring high-quality, auditable data for informed decision-making."
    },
    {
        link: '/case-studies/a-lending-company-transforms-data-management-a-case-study-in-efficiency',
        imgsrc: case35,
        heading: "A Lending Company Transforms Data Management: A Case Study in Efficiency",
        subheading: "Discover how a leading US lending company overcame data silos and inefficiencies with Decimal Point Analytics (DPA). By streamlining data management, they reduced errors, cut costs, and enhanced operational efficiency, enabling better decision-making and business success."
    },
    {
        link: '/case-studies/automating-data-and-news-updation-for-enhanced-index-management',
        imgsrc: case34,
        heading: "Automating Data and News Updation for Enhanced Index Management",
        subheading: "Discover how Decimal Point Analytics transformed a leading financial institution's data management with advanced automation solutions, enhancing index management, optimizing data retrieval, and seamlessly integrating news updates for informed decision-making"
    },
    {
        link: '/case-studies/enhancing-asset-forecasting-capabilities-for-a-leading-financial-institution',
        imgsrc: case33,
        heading: "Enhancing Asset Forecasting Capabilities for a Leading Financial Institution",
        subheading: "Implemented advanced asset forecasting for a leading financial firm, elevating both accuracy and efficiency. Discover unparalleled forecasting capabilities with Decimal Point Analytics"
    },
    {
        link: '/case-studies/wealth-management-solution',
        imgsrc: case32,
        heading: "Optimizing Asset Allocation: A Tailored Wealth Management Solution",
        subheading: "Decimal Point Analytics delivers tailored software for asset allocation in insurance and investment. Our expertise ensures seamless data integration, empowering staff and enabling swift product launches. Explore innovative wealth management with us."
    },
    {
        link: '/case-studies/financial-data-extraction-global-index-provider',
        imgsrc: case31,
        heading: "Enhancing Business Intelligence: Transforming Data Extraction for a Leading Financial Institution",
        subheading: "Decimal Point Analytics enhanced data accuracy and efficiency through advanced web scraping, standardizing, and processing 450,000 records monthly."
    },
    {
        link: '/case-studies/revolutionising-machinery-and-equipment-data-management-with-web-scraping-and-advanced-analytics',
        imgsrc: case30,
        heading: "Revolutionising Machinery And Equipment Data Management With Web Scraping And Advanced Analytics",
        subheading: "Decimal Point Analytics enabled 300,000 monthly records acquisition, ensuring data integrity, accuracy, and uniformity."
    },
    {
        link: '/case-studies/revolutionizing-financial-data-analysis-for-global-indices',
        imgsrc: case29,
        heading: "Revolutionizing Financial Data Analysis for Global Indices",
        subheading: "Decimal Point Analytics transformed financial data analysis for a global indices provider, automating data collection, ensuring multilingual document accuracy, and achieving 99.9% data precision."
    },
    {
        link: '/case-studies/optimizing-supplier-risk-management-unleashing-the-power-of-advanced-analytics-for-seamless-operation',
        imgsrc: case28,
        heading: "Optimizing Supplier Risk Management: Unleashing the Power of Advanced Analytics for Seamless Operation",
        subheading: "A top enterprise transforms supplier risk management using advanced analytics, slashing assessment times, boosting operational efficiency, and gaining unprecedented visibility for proactive risk mitigation."
    },
    {
        link: '/case-studies/investment-leader-automates-credit-and-loan-operations-achieving-remarkable-efficiency-and-precision',
        imgsrc: case27,
        heading: "Investment Leader Automates Credit and Loan Operations, Achieving Remarkable Efficiency and Precision",
        subheading: "Investment Management Innovator streamlines credit and loan operations with advanced automation, enhancing efficiency and decision-making accuracy, and ensuring robust risk management."
    },
    {
        link: '/case-studies/advanced-fund-accounting-solutions-for-top-financial-services-group-in-mena-region',
        imgsrc: case26,
        heading: "Advanced Fund Accounting Solutions for Top Financial Services Group in MENA Region",
        subheading: "Decimal Point Analytics provided a tailored fund accounting solution for a prominent MENA financial services group with a $2.5 billion portfolio. Their system automated data processing, ensuring accuracy and cost-efficiency while maintaining reliability."
    },
    {
        link: '/case-studies/enhanced-esg-reporting-from-voluminous-data-to-clear-insights',
        imgsrc: case25,
        heading: "Enhanced ESG Reporting: From Voluminous Data to Clear Insights",
        subheading: "A global asset manager's complex ESG reports were streamlined into actionable insights, enabling efficient, data-driven decisions and offering forward-looking ESG perspectives."
    },
    {
        link: '/case-studies/how-ai-driven-automation-revolutionizes-a-leading-european-industrial-company',
        imgsrc: case24,
        heading: "How AI-Driven Automation Revolutionizes a Leading European Industrial Company",
        subheading: "A Large European Industrial Company faced a critical challenge in transforming its business operations and driving significant value in the longer term. One area requiring improvement was the process of curating news for their newsletter."
    },
    {
        link: '/case-studies/driving-investment-banking-success-decimals-comprehensive-solutions-for-valuation-research-and-trade-recommendations',
        imgsrc: case23,
        heading: "Driving Investment Banking Success: Decimal's Comprehensive Solutions for Valuation, Research, and Trade Recommendations",
        subheading: "In the fast-paced world of investment banking, staying ahead is crucial for success. This case study highlights how Decimal's valuation research and trade recommendations fueled remarkable growth for a top New York investment bank."
    },
    // {
    //     link: '/case-studies/optimum-data-analytics-solution-for-a-leading-indian-bank',
    //     imgsrc: case22,
    //     heading: "Optimum Data Analytics Solution For A Leading Indian Bank",
    //     subheading:"Powered by Decimal's data analytics solution, a leading Indian bank experienced an unprecedented surge in business growth through optimized cross-selling, customer retention, and branch expansion strategies."
    // },
    {
        link: '/case-studies/improving-database-management-for-enhanced-decision-making-in-the-alternate-fixed-income-industry',
        imgsrc: case21,
        heading: "Improving Database Management for Enhanced Decision-Making",
        subheading: "Pioneering the Alternate Fixed Income industry, a transformative database management solution reshaped decision-making for a US-based firm by automating data input, consolidating details, and analyzing creditworthiness improving bill management efficiency."
    },
    {
        link: '/case-studies/streamlining-technical-support-and-enhancing-efficiency-through-process-automation',
        imgsrc: case20,
        heading: "Streamlining Technical Support and Enhancing Efficiency through Process Automation",
        subheading: "Unleashing the power of automation, Decimal revolutionized data management for a financial ratings firm by addressing Excel-based challenges.  leading to significant time and cost savings and enhanced decision-making capabilities."
    },
    {
        link: '/case-studies/transforming-banking-operations-with-advanced-data-analytics',
        imgsrc: case19,
        heading: "Transforming Banking Operations with Advanced Data Analytics",
        subheading: "Through the implementation of advanced data analytics, an esteemed Indian bank experienced an empowering transformation, leading to invaluable insights for informed decision-making, precise customer targeting, and the identification of growth opportunities."
    },
    {
        link: '/case-studies/automated-data-warehouse-and-back-testing-solutions-for-global-index-provider-to-achieve-financial-benefits',
        imgsrc: case18,
        heading: "Automated Data Warehouse & Back-Testing Solutions That Helped a Global Index Provider Achieve Financial Benefits",
        subheading: "Discover how Decimal Point Analytics revolutionized a global index provider's operation by implementing automated data warehouse and back-testing solutions. The transformation led to enhanced data quality, increased profitability, and a competitive advantage in the market."
    },
    {
        link: '/case-studies/extraction-of-ghg-emission-data-from-esg-disclosure-reports',
        imgsrc: case17,
        heading: "Extraction of GHG Emission Data from ESG Disclosure Reports",
        subheading: "Explore how Decimal Point Analytics revolutionized the extensive extraction of GHG emission data from 5,500 companies, fueled a dynamic climate management platform providing valuable insights for informed decision-making and risk reduction with exceptional support."
    },
    {
        link: '/case-studies/delivering-comprehensive-data-solutions-for-a-leading-financial-data-aggregator-and-provider',
        imgsrc: case15,
        heading: "Delivering Comprehensive Data Solutions for a Leading Financial Data Aggregator and Provider",
        subheading: "A prominent Canadian data aggregator successfully harnessed the power of comprehensive data solutions, capitalizing on advanced analytics expertise to optimize services, streamline operations, and elevate customer satisfaction to new heights."
    },
    {
        link: '/case-studies/tailoring-process-automation-to-provide-quality-data-in-salesforce-crm',
        imgsrc: case16,
        heading: "Tailoring Process Automation Solutions to Provide Modernised Quality Data in Salesforce CRM",
        subheading: "A leading financial services provider successfully implemented tailored process automation in Salesforce CRM, ensuring the delivery of high-quality data. This streamlined approach enhanced data accuracy, improved operational efficiency, and empowered the organization to make informed decisions."
    },
    {
        link: '/case-studies/fraud-detection-and-analytics-for-a-leading-german-reinsurer',
        imgsrc: case9,
        heading: "Fraud Detection & Analytics for a Leading German Reinsurer",
        subheading: "Leveraging advanced analytics, Decimal delivered a comprehensive fraud solution to a German reinsurer. Through data extraction, cleansing, and machine learning, suspicious accounts were detected, and future fraud prevention was strengthened using Fidelity Pulse."
    },
    {
        link: '/case-studies/process-and-cost-efficiency-through-structured-data-management-solutions',
        imgsrc: case10,
        heading: "Process & Cost Efficiency through DPA's Structured Data Management Solutions",
        subheading: "By adopting structured data management solutions, a prominent company achieved remarkable process and cost efficiency, leading to substantial savings of $250,000. The implementation improved data organization, minimized errors, optimized resource allocation, and resulted in enhanced productivity and overall operational efficiency."
    },
    {
        link: '/case-studies/salesforce-automation-to-simplify-your-complex-processes',
        imgsrc: case11,
        heading: "Salesforce Automation to Simplify Your Complex Processes",
        subheading: "Revolutionizing operations, Salesforce automation enabled a non-bank lending company to achieve remarkable efficiency gains. With automated data entry, refined user interface, and streamlined workflows, they saved time, improved data analysis, and accelerated loan approvals."
    },
    {
        link: '/case-studies/cutting-edge-data-solutions-for-a-corporate-client',
        imgsrc: case12,
        heading: "Cutting Edge Data Solutions for a Corporate Client",
        subheading: "Empowering a corporate client, a transformative data partnership revolutionized their trading offers and amplified analytical insights. With expertise in data analysis and automation, this collaboration drove informed decision-making and substantial revenue growth."
    },
    {
        link: '/case-studies/offering-solutions-for-every-asset-managers',
        imgsrc: case13,
        heading: "Offering solutions for every Asset Managers",
        subheading: "Decimal Point Analytics provided comprehensive solutions for asset managers, enabling them to optimize operations, enhance investment decisions, and drive superior outcomes. The tailored solutions catered to the unique needs of asset managers, resulting in improved operational efficiency, client satisfaction, and superior outcomes."
    },
    {
        link: '/case-studies/nav-calculations-for-portfolio-management',
        imgsrc: case1,
        heading: "NAV Calculations for Portfolio Management",
        subheading: "Revolutionizing a large insurance company, an automation solution streamlined NAV calculations and enhanced portfolio management efficiency. This reduced calculation time, provided consolidated models for better evaluation, and attracted more investments."
    },
    {
        link: '/case-studies/intelligent-document-processing-of-rent-rolls-for-an-asset-manager',
        imgsrc: case2,
        heading: "Intelligent document processing of Rent Rolls for an Asset Manager",
        subheading: "Transforming document processing for an asset management firm, an advanced solution streamlined rent roll data extraction. By eliminating manual work and enhancing analysis capabilities, remarkable time and cost efficiencies were achieved."
    },
    {
        link: '/case-studies/data-managment-for-efficient-crm-usage',
        imgsrc: case3,
        heading: "Data Management for Efficient CRM Usage",
        subheading: "Optimizing CRM data management, an advanced solution boosted investor interactions and operational efficiency for an investment management firm. It resolved data challenges, automated processes, and introduced comprehensive check reports for streamlined maintenance."
    },
    {
        link: '/case-studies/response-management-system-for-fund-raising',
        imgsrc: case4,
        heading: "Response Management System for Fund Raising",
        subheading: "Revamping response management, an innovative solution streamlined operations for a leading credit-focused asset management firm, enhancing fund raising. It reduced turnaround time, improved response quality, and achieved impressive accuracy rates."
    },
    {
        link: '/case-studies/automated-portfolio-monitoring-system-for-a-private-equity-firm',
        imgsrc: case5,
        heading: "Automated Portfolio Monitoring System for a Private Equity firm",
        subheading: "Revolutionizing portfolio monitoring, an automated system streamlines performance analysis for a leading private equity firm. With a centralized data repository, standardized templates, and interactive dashboards, it enables swift analysis and peer comparison."
    },
    {
        link: '/case-studies/robotic-process-automation-for-credit-funds',
        imgsrc: case6,
        heading: "Robotics Process Automation (RPA) for Credit Funds",
        subheading: "Revolutionizing document and email management, an advanced automation solution empowers a leading credit-focused alternative asset management firm. Leveraging Robotics Process Automation (RPA), it ensures timely and accurate lender approvals for new security purchases."
    },
    {
        link: '/case-studies/customized-credit-platform-for-financial-institution',
        imgsrc: case7,
        heading: "Customized Credit Platform for Financial Institution",
        subheading: "A financial institution partnered with Decimal Point Analytics to develop a customized credit platform. This platform improved risk assessment, streamlined processes, and facilitated informed lending decisions, resulting in increased operational efficiency, reduced credit risk, and enhanced profitability for the institution."
    },
    {
        link: '/case-studies/intelligent-dashboard-solutions-for-banks',
        imgsrc: case8,
        heading: "Intelligent Dashboard Solution for Banks",
        subheading: "Empowering a leading African bank, Decimal Point Analytics delivered an intelligent dashboard solution offering real-time data visualization, performance tracking, and risk monitoring. The intuitive dashboards provided actionable insights, enhancing operational efficiency, empowering data-driven decision-making, and enabling effective risk management."
    },
]

export default Case_studies_Data;