import { Container, Typography, Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Insightback from '../../../Component/Insightback'

import Writemessage3 from '../../../Component/Writemessage3';
import { Helmet } from 'react-helmet';
import EmployeeProfile from '../../../Component/EmployeeProfile';
import SD from '../../../asset/images/team/shailesh_dhuri.png'


export default function DramaAroundOpenAI() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>LLMs, the drama around OpenAI: Is Q* the death* of co-pilot?</title>
                <meta name="description" content="Delve into the drama surrounding OpenAI's secret project Q* and its potential connection to Artificial General Intelligence (AGI)."/>
                <meta name="keywords" content="OpenAI, Q*, DGGP, Co-pilot, GenAI, AGI, Microsoft, Apple, Technical Debt, Laptop, OS, Booting, Memory Optimization, Computation Resources, BIOS" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/articles/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="article-page" className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center' >
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Insight
                                </Typography>
                                <Typography variant='h1' className='blue mainhead al_center' style={{textTransform:'none'}}>
                                    LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>




                    <Box className='whitebx'>



                        <Box pt={1} pb={1}>
                            {/* <Typography variant='h4' className='blue' gutterBottom> MRR – the way forward:  </Typography> */}

                            <Typography variant='h6' className='black jst' gutterBottom>
                                One recent <a href="https://www.reuters.com/technology/sam-altmans-ouster-openai-was-precipitated-by-letter-board-about-ai-breakthrough-2023-11-22/" target='_blank' rel='noreferrer'>Reuters</a> article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI <a href="https://spinningup.openai.com/en/latest/algorithms/ddpg.html" target='_blank' rel='noreferrer'>documents</a> about DGGP. Again, it is just a mere word association and nothing more. However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP.
                            </Typography>


                            <Typography variant='h6' className='black jst' gutterBottom>
                                I have held the view that <strong>co-pilot is mere a market retention strategy of the incumbents</strong> which allows them to adjust their products to the world of GenAI. In my mind, the best co-pilot is no co-pilot. However, we have got an exactly opposite situation of plethora of co-pilots lurking in different environments on my laptop.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                Why do co-pilots exist? Because there is too much of sensory inputs from the instrument panel of modern aircrafts for one pilot to digest everything. <strong>The root cause of existence of co-pilots is dispersed sensors with multiple situations where conflicting signals cannot be processed into one logical end point action.</strong>
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                On the other hand, the Star-Trek Q continuum, <strong>the immortal Q eventually to realize that they had experienced, observed, and solved or discussed literally everything imaginable.</strong>
                            </Typography>


                            <Typography variant='h6' className='black jst' gutterBottom>
                                These two – namely co-pilot and Q are diametrically opposite.
                            </Typography>


                            <Typography variant='h6' className='blue jst fw6' gutterBottom>
                                Who is the Q in this connected world? And what it means?
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                Today, when you start your laptop, it loads all the technical debt accumulated for decades in the OS. One literally has to wait for minutes before starting to work. Then work means determining, if you need to open Outlook/Word/Excel and then start reading or typing.
                            </Typography>


                            <Typography variant='h6' className='black jst' gutterBottom>
                                Since Microsoft and Apple know the entire behavior of all laptop users, in this world of GenAI, with an appropriate policy action framework, it is possible for GenAI to regenerate and compile only the part of OS code that is required for a particular user and keep generating code as it faces new instructions/attacks. This means a very fast booting of machines, optimal use of memory and computation resources. Next is as user starts interacting with laptop, it will decide if it wants to use parts of word processor or spreadsheet program and generate the most optimal spreadsheet program (if that is the case) for that user’s behavior. It may mean taking something from MS Excel and something from Apple Numbers based on what is the most optimal for the user. This will be our first experience of near AGI as laptop warriors. As an aside, In my view, ninety nine percent of Excel users use less than one percent of its technical debt loaded in their laptop memory. And that is true of every modern program on laptop. GenAI can solve that problem.
                            </Typography>


                            <Typography variant='h6' className='black jst' gutterBottom>
                                In conclusion. What I foresee, is that, in a world which is dominated by Ms. <a href="https://www.nytimes.com/2021/06/15/technology/lina-khan-ftc.html" target='_blank' rel='noreferrer'>Lina</a> Khan, your BIOS will become your trusted co-pilot and that is the only co-pilot that you will ever need. All other co-pilots will shine and then disappear like short burst supernova. But is Ms. Lina Khan listening?
                            </Typography>


                        </Box>

                        <Box mt={2}>
                        <Box mt={1}>
                                <EmployeeProfile imgSrc={SD} name={"Mr. Shailesh Dhuri"} designation={"Chief Executive Officer"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/shaileshdhuri/"} />
                            </Box>
                        </Box>


                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="LLMs, the drama around OpenAI: Is Q* the death* of co-pilot?" />
                    </Box>

                    <Insightback />


                </Container>
            </Box>


        </>
    );
}
