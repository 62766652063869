import { IconButton, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import { withStyles } from '@material-ui/core/styles';

import Carousel from "react-elastic-carousel";

import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';

import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

import summit1 from "../../../asset/images/media/event/innovation_summit/summit1.jpg"
import summit2 from "../../../asset/images/media/event/innovation_summit/summit2.jpg"
import summit3 from "../../../asset/images/media/event/innovation_summit/summit3.jpg"
import summit4 from "../../../asset/images/media/event/innovation_summit/summit4.jpg"
import summit5 from "../../../asset/images/media/event/innovation_summit/summit5.jpg"
import summit6 from "../../../asset/images/media/event/innovation_summit/summit6.jpeg"
import summit7 from "../../../asset/images/media/event/innovation_summit/summit7.jpeg"
import summit8 from "../../../asset/images/media/event/innovation_summit/summit8.jpeg"
import summit9 from "../../../asset/images/media/event/innovation_summit/summit9.jpeg"
import summit10 from "../../../asset/images/media/event/innovation_summit/summit10.jpeg"


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


export default function Innovation_Summit_2023() {


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Innovation Summit 2023 - Exploring AI in Business Operations</title>
                <meta name="description" content="Join Shailesh Dhuri at the Innovation Summit 2023 for insights on 'Business in the Era of AI.' Explore AI integration in business with industry experts." />
                <meta name="keywords" content=" Innovation Summit 2023, SDA Bocconi Asia Center, Business in the Era of AI, Shailesh Dhuri, Decimal Point Analytics, Technology, Digital Transformation, AI Integration, Panel Discussion, Vidya Krishnan, Siva Prasath B, Arockia Liborious, Deepesh Hiran, State Bank of India, Marsh McLennan, ICICI, AuxoAI, Modern Business Operations, Industry Experts, Thought Leaders, Event Photos " />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/innovation-summit-2023-at-sda-bocconi-asia-center" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Innovation Summit 2023 at SDA Bocconi Asia Center
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                            Shailesh Dhuri, CEO, Decimal Point Analytics was a key panelist, discussing the topic 'Business in the Era of AI' at the Innovation Summit 2023 held at the SDA Bocconi Asia Centre campus. The event was a one-day exploration of technology, digital transformation, and AI integration in business operations.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The panelists included:
                            <ul>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        Smt. Vidya Krishnan, Deputy Managing Director (IT), State Bank of India, Global IT Centre. </Typography>
                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        Mr. Siva Prasath B, Head of Knowledge Services at Marsh McLennan
                                    </Typography>
                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        Mr. Arockia Liborious, Practice Head of Gen AI, Cognitive, and Cards Portfolio Analytics at ICICI.
                                    </Typography>
                                </li>
                            </ul>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The panel was moderated by Mr. Deepesh Hiran, the Managing Director of AuxoAI, ensuring a productive and engaging discussion.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The Innovation Summit 2023 at SDA Bocconi Asia Center provided a platform for in-depth discussions on technology, digital transformation, and the role of AI in modern business operations.
                        </Typography>

                        {/* <Typography variant='h6' className='black jst'>
                            <a href="" target="_blank" rel="noopener noreferrer"> Click here</a> to explore the photos from the Innovation Summit 2023
                        </Typography> */}

                        <Box className='al_center'>
                            <IconButton className='blue' onClick={handleClickOpen}>
                                <CameraAltOutlinedIcon />
                            </IconButton>
                        </Box>

                        <Box className='al_center'>
                            <Typography className='blink_text'>Click Here...</Typography>
                        </Box>


                    </Box>


                    <Eventback />


                </Container>
            </Box>

            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="b_modal teammodal">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose}>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Grid container spacing={2}>
                            <Carousel itemsToShow={1} animateOut="slideOutDown"
                                animateIn="flipInX">
                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + summit1 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + summit2 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + summit3 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + summit4 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + summit5 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + summit6 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + summit7 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + summit8 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + summit9 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + summit10 + ')', }}></Box>
                                </Box>
                            </Carousel>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>


        </>
    );
}
