import news1 from "../../../../asset/images/insight/case_studies/case_studies_thumbnails/automated_data.png";
import news2 from "../../../../asset/images/insight/case_studies/case_studies_thumbnails/driving_investment_banking.png";
import news3 from "../../../../asset/images/insight/case_studies/case_studies_thumbnails/investment.png";
import news4 from "../../../../asset/images/insight/case_studies/case_studies_thumbnails/solution_for_crm.png";



const Press_Release_data = [
    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "27Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2027",
        year: 2027,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "25LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2025",
        year: 2025,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "26LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2026",
        year: 2026,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "28LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2028",
        year: 2028,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "27Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2027",
        year: 2027,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "25LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2025",
        year: 2025,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "26LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2026",
        year: 2026,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "28LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2028",
        year: 2028,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },

    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "27Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2027",
        year: 2027,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "25LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2025",
        year: 2025,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "26LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2026",
        year: 2026,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "28LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2028",
        year: 2028,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },

    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "27Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2027",
        year: 2027,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "25LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2025",
        year: 2025,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "26LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2026",
        year: 2026,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "28LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2028",
        year: 2028,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "27Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2027",
        year: 2027,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "25LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2025",
        year: 2025,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "26LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2026",
        year: 2026,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "28LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2028",
        year: 2028,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },

    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "27Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2027",
        year: 2027,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "25LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2025",
        year: 2025,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "26LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2026",
        year: 2026,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "28LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2028",
        year: 2028,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "27Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2027",
        year: 2027,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "25LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2025",
        year: 2025,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "26LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2026",
        year: 2026,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "28LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2028",
        year: 2028,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "27Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2027",
        year: 2027,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "25LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2025",
        year: 2025,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "26LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2026",
        year: 2026,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "28LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2028",
        year: 2028,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },

    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "27Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2027",
        year: 2027,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "25LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2025",
        year: 2025,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "26LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2026",
        year: 2026,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "28LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2028",
        year: 2028,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },

    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "27Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2027",
        year: 2027,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "25LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2025",
        year: 2025,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "26LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2026",
        year: 2026,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "28LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2028",
        year: 2028,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "27Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2027",
        year: 2027,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "25LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2025",
        year: 2025,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "26LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2026",
        year: 2026,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "28LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2028",
        year: 2028,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },

    {
        type: "internal",
        link: "/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: news1,
        heading: "27Is it time to write ESG’s Requiem? Not quite",
        date: "Dec 15, 2027",
        year: 2027,
        subheading: "The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news2,
        heading: "25LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2025",
        year: 2025,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news3,
        heading: "26LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2026",
        year: 2026,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },
    {
        type: "internal",
        link: "/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: news4,
        heading: "28LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?",
        date: " Dec 02, 2028",
        year: 2028,
        subheading: " One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom. While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP.Again, it is just a mere word association and nothing more.However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP."
    },

];

export default Press_Release_data;