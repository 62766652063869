import LLM_Models from "../../asset/images/insight/whitepaper_thumbnails/llm_models.png";
import TurningDataIntoDollars from "../../asset/images/insight/whitepaper_thumbnails/turning_data_into_dollars.png";
import AlternativeDataInvestment from "../../asset/images/insight/whitepaper_thumbnails/alternative_data_investment.png";
import GettingVisualWithData from "../../asset/images/insight/whitepaper_thumbnails/banking.png";
import AmplifiedNeedForTechLed from "../../asset/images/insight/whitepaper_thumbnails/tech_led.png";

const Whitepaper_Data = [
    {
        link: "/whitepapers/getting-visual-with-data-for-personalized-banking",
        imgsrc: GettingVisualWithData,
        heading: "Getting Visual with Data for Personalized Banking",
        date: "August, 2024"
    },
    {
        link: "/whitepapers/the-amplified-need-for-tech-led-intelligence-in-insurance",
        imgsrc: AmplifiedNeedForTechLed,
        heading: "The Amplified Need for Tech-Led Intelligence in Insurance",
        date: "August, 2024"
    },
    {
        link: "/whitepapers/alternative-data-that-can-power-successful-alternative-investments",
        imgsrc: AlternativeDataInvestment,
        heading: "Alternative Data that can Power Successful Alternative Investments",
        date: "August, 2024"
    },
    {
        link: "/whitepapers/turning-data-into-dollars-combining-advanced-analytics-and-tech-to-unlock-intel-for-hedge-funds",
        imgsrc: TurningDataIntoDollars,
        heading: "Turning Data into Dollars: Combining Advanced Analytics and Tech to Unlock Intel for Hedge Funds",
        date: "August, 2024"
    },
    {
        link: "/whitepapers/leveraging-large-language-models-to-minimize-technical-debt",
        imgsrc: LLM_Models,
        heading: "Leveraging Large Language Models to Minimize Technical Debt",
        date: "August, 2024"
    }
];

export default Whitepaper_Data;