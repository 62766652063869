import IMG1 from '../../../asset/images/insight/esg/round_table/roundtable_by_dpa.jpg';
import IMG2 from '../../../asset/images/insight/esg/round_table/executive_roundtable.png'; 
import IMG3 from '../../../asset/images/insight/esg/round_table/evolution_of_esg.png'; 



const ESG_roundtable_data = [
   
    {
        link: "/executive-roundtable-the-evolution-of-esg-reporting-implications-for-fund-managers",
        imgsrc: IMG3,
        heading: "Executive Roundtable- The Evolution of ESG Reporting Implications for Fund Managers",
    },
    {
        link: "/executive-roundtable-on-portfolio-alignment-tools",
        imgsrc: IMG2,
        heading: "Executive Roundtable on Portfolio Alignment Tools: Current Practices and Outlook",
    },
    {
        link: "/round-table-by-dpa",
        imgsrc: IMG1,
        heading: "Round Table by DPA - Is Net zero still a myth?",
    },
    
];

export default ESG_roundtable_data;