import Oct_Newsletter from '../../../../../asset/images/esg/esg_newsletter_thumbnail/october-edition.jpg';
import Aug_Newsletter from '../../../../../asset/images/esg/esg_newsletter_thumbnail/august-edition.jpg';
import May_Newsletter from '../../../../../asset/images/esg/esg_newsletter_thumbnail/may-edition.jpg';
import Jan_Newsletter from '../../../../../asset/images/esg/esg_newsletter_thumbnail/january-edition.png';
import June_2024_Newsletter from '../../../../../asset/images/esg/esg_newsletter_thumbnail/june_2024.png';
import July_2024_Newsletter from '../../../../../asset/images/esg/esg_newsletter_thumbnail/july_2024.png';

const ESG_Newsletters_data = [

    {
        type: "internal",
        link: "/esg-insights/esg-newsletter/building-analytical-solutions-for-a-sustainable-future-july-edition",
        imgsrc: July_2024_Newsletter,
        heading: "July 2024 Edition",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-newsletter/building-analytical-solutions-for-a-sustainable-future-june-edition",
        imgsrc: June_2024_Newsletter,
        heading: "June 2024 Edition",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-newsletter/building-analytical-solutions-for-a-sustainable-future-january-edition",
        imgsrc: Jan_Newsletter,
        heading: "January 2024 Edition",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-newsletter/building-analytical-solutions-for-a-sustainable-future-october-edition",
        imgsrc: Oct_Newsletter,
        heading: "October 2023 Edition",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-newsletter/building-analytical-solutions-for-a-sustainable-future-august-edition",
        imgsrc: Aug_Newsletter,
        heading: "August 2023 Edition",
    },
    {
        type: "external",
        link: "https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/creative_dpa/o/Media%2Fuploaded_imgs%2Fesgnewsletter%2Fnewsletter%2Fbuilding-analytical-solutions-for-a-sustainable-future.html",
        imgsrc: May_Newsletter,
        heading: "May 2023 Edition",
    },
];

export default ESG_Newsletters_data;