import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Writemessage3 from '../../../Component/Writemessage3';

import Casestudyback from '../../../Component/Casestudyback'


import Salesf1 from "../../../asset/images/insight/case_studies/salesforce1.jpg";
import Salesf2 from "../../../asset/images/insight/case_studies/salesforce2.jpg";

export default function Automation_and_custom() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>
            <Helmet>

                <meta charset="UTF-8" />
                <title>Salesforce Automation to Simplify Your Complex Processes</title>
                <meta name="description" content="Case Study On How Decimal Point Analytics with its artificial intelligence data analytics solutions automated data analysis problem reducing human errors." />
                <meta name="keywords" content="
AI in data analytics,
artificial intelligence solutions company,
market research company in US,
market research solutions,
Sector research provider
" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/salesforce-automation-to-simplify-your-complex-processes" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            </Helmet>


            <Box id="case-studies" className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center mb0'>
                                    Salesforce Automation to Simplify Your Complex Processes
                                </Typography>

                                <Typography className=' mainsmhead black al_center '>
                                    How our technical team assisted in proficiently simplifying a complex process of manual form filling, ineffective user interface, and critical data transfer, to save time and enhance data analysis
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Writemessage3 pageVisited="Salesforce Automation to Simplify Your Complex Processes" />


                    <Box className='whitebx'>
                        <Typography variant='h4' className='blue' >Summary </Typography>

                        <Typography variant='h6' className='black jst'>
                            There has never been a better time to harness a fully digital transformation in the current landscape, where new levels of sophistication and automation are available. As a result, enterprise leaders are embracing digitalization to stay competitive and empower their organizations with efficient and quicker results.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            A publicly traded non-bank lending company with headquarters in the United States that offers secured and unsecured loans to consumers and businesses partnered with us.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            They wanted us to create a user-friendly Interface on Salesforce and automate their cumbersome process of manually filling forms by extracting the data and adding it to the forms for loan processing.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Our technical professionals determined the potential issues that reduced production and demanded more corporate resources. The team reviewed the institution’s data collection, reporting, and reconciliation processes from the salesforce platform to understand and solve their challenges.
                        </Typography>
                    </Box>



                    <Box className='whitebx' mt={1}>

                        <Grid container spacing={3} alignItems="top">

                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <Box className='al_center'>
                                    <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + Salesf1 + ')', }}></Box>
                                </Box>
                            </Grid>

                            <Grid item lg={8} md={8} sm={8} xs={12}>

                                <Box>


                                    <Typography variant='h4' className='blue' >The Problems</Typography>




                                    <Typography variant='h6' className='black jst'>
                                        <ul>
                                            <li><strong> Manual updation of forms-  </strong> Lack of automation leads to manual updates on the existing data, leading to resource engagement for longer hours with high chances of human errors.  </li>
                                            <li><strong> Inefficient and clustered salesforce user interface-  </strong> Ineffective User Interface creates issues monitoring data and delays sorting out the relevant information. </li>
                                            <li><strong> Discontinuation of workflows and process builders on Salesforce- </strong> The unification of ‘workflows and process builders’ into ‘flows’ created additional complications for the company.  </li>
                                        </ul>
                                    </Typography>


                                    <Typography variant='h6' className='black jst'>
                                        These problems accounted for delayed decision-making with no value addition. The objective, therefore, was to simplify this procedure which would lead to greater productivity, saving time and energy for our client, and further accelerate the process of loan approvals.
                                    </Typography>

                                </Box>

                            </Grid>
                        </Grid>
                    </Box>



                    <Box className='whitebx' mt={1}>

                        <Grid container spacing={3} alignItems="top">

                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <Box className='al_center'>
                                    <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + Salesf2 + ')', }}></Box>
                                </Box>
                            </Grid>

                            <Grid item lg={8} md={8} sm={8} xs={12}>

                                {/* <Box mb={2}>
                                    <Link to='/analystpulse'>
                                        <Typography variant='h4' className='blue' >Know More About Our Product on Analytics – AnalystPulse </Typography>
                                    </Link> 
                                </Box> */}

                                <Box>
                                    <Typography variant='h4' className='blue' >The Solution  </Typography>

                                    <Typography variant='h6' className='black jst'> DPA devised a radical solution after careful planning and design.
                                        <ul>
                                            <li><strong>Process automation – </strong>Team DPA simplified the process by developing programs to trigger the task of automatically updating the data in Salesforce, which led to time-saving, efficiency-driven error-free entries, eliminating friction and redundancy and increasing the client’s productivity.</li>
                                            <li><strong>Organizing and refining data-</strong>The team developed a dynamic and reusable user Interface with the help of Visual force that helped to create customized user interfaces (UI) per the project demand. This further helped in analyzing and sorting out the necessary statistics from the collection of clustered data.  </li>
                                            <li><strong> Migration of existing workflows and process builders to flows-</strong>The migration of the company’s valued data from ‘workflows and process builders’ to ‘flows’ was a crucial task. Still, it was handled with utmost efficiency and ease by the technical experts of the DPA team. </li>
                                        </ul>
                                    </Typography>


                                    <Typography variant='h6' className='black jst'>
                                        DPA delivered a robust solution with the right analytics to solve the complexities.
                                    </Typography>

                                </Box>

                            </Grid>
                        </Grid>
                    </Box>



                    <Box className='whitebx' mt={1}>
                        <Box>
                            <Typography variant='h4' className='blue' >Our Approach </Typography>

                            <Typography variant='h6' className='black jst'> Preparation of automation process to autofill the data in forms</Typography>

                            <Typography variant='h6' className='black jst'> Creation of flows to automate different processes, such as
                                <ul>
                                    <li>Sending email alerts</li>
                                    <li>Auto updating data into Salesforce</li>
                                    <li>Migrating the existing “workflows” and “process builders” to “flows.” </li>
                                    <li>Used Apex Triggers to automate the tasks and auto-update the data in Salesforce </li>
                                    <li>Created a customized and effective user interface with Visualforce that made it simple to employ data-specific rules as per the project requisites.</li>
                                </ul>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Modification of the fields and objects in Salesforce for data cleaning and mapping the data to flow seamlessly from one object to another.
                            </Typography>

                        </Box>
                    </Box>


                    <Box className='whitebx' mt={1}>
                        <Box>
                            <Typography variant='h4' className='blue' >Results </Typography>


                            <Typography variant='h6' className='black jst'> The company moved from manual filling of forms to an end-to-end automated system through sales force that helped in:
                                <ul>
                                    <li>Highly intuitive user interface</li>
                                    <li>Automation process for seamless data collection</li>
                                    <li>Streamlined and highly efficient salesforce process </li>
                                    <li>Significant time saving, thereby accelerating the loan procedure</li>
                                    <li>Cost Saving</li>
                                </ul>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                DPA used relevant programming languages and technical know-how to help this lending organization harmonize and automate its operations. The team also eliminated the loopholes that were left unnoticed.
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                If you have a problem on these lines, contact us to book your 30-minute complimentary consultation now.
                            </Typography>


                            <Link to='/contact-us'>
                                <Typography variant='h6' className='blue jst'>
                                    Let’s talk!
                                </Typography>
                            </Link>


                        </Box>
                    </Box>



                    <Box className='graybbx' mt={1} >
                        <Grid container spacing={1} alignItems="top" >
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <Box className='al_left'>
                                    <Typography variant='h4' className='black'>
                                        Explore More:
                                    </Typography>

                                    <Link to='/case-studies/salesforce-automation-to-simplify-your -complex-processes' className='link'>
                                        <Typography variant='h6' className='black jst' style={{ marginBottom: '0px' }}>
                                            <b>Next Case Study: </b>Cutting Edge Data Solutions for a Corporate Client.

                                        </Typography>
                                    </Link>

                                    <Link to='/articles/how-asset-managers-are-leveraging-natural-language-processing-techniques' className='link'>
                                        <Typography variant='h6' className='black jst'>
                                            <b>Insights: </b>How Asset Managers are Leveraging Natural Language Processing (NLP) Techniques
                                        </Typography>
                                    </Link>

                                </Box>
                            </Grid>
                        </Grid>

                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}


                    </Box>


                    <Casestudyback />




                </Container>
            </Box>


        </>
    );
}
