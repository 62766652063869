import { Container, Grid, Typography, Box, Button, } from '@material-ui/core';
import React, { useEffect } from 'react';
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Link } from 'react-router-dom';

import approved from "../../asset/images/outsourcesba/approved-agreement.jpg"
import loan from "../../asset/images/outsourcesba/hr-handshaking.jpg"
import loanclose from "../../asset/images/outsourcesba/low-section.jpg"

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoanUnderwriting from './LoanUnderwriting';


export default function SBALoanPreApproval() {

    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const stickyItemsHeight = 70;
            const offset = element.getBoundingClientRect().top - stickyItemsHeight;


            setTimeout(() => {
                window.scrollBy({ top: offset, behavior: 'smooth' });
            }, 50);
        }
    };




    useEffect(() => {
        const { hash } = window.location;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const offset = 170;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - offset;
                window.scrollBy({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }
        }
    }, []);


    return (
        <>

            <Box className='al_center'>
                <Typography variant='h2' className='blue fw6 services_heading'>
                    Services we provide
                </Typography>
            </Box>

            <Box className='section2 sec_bg'>
                <Container maxWidth="lg">
                    <Grid container spacing={4} alignItems="top" justifyContent='center'>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box className='al_center'>
                                <img src={approved} className='topbanner2' alt='SBA Loan Pre-Approval' />
                            </Box>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box>

                                <Box py={1}>
                                    <Typography variant='h2' className='black h2v2 fw6'>
                                    SBA Loan Pre - Approval (Pre Qualification )
                                    </Typography>

                                    <Typography variant='h6' className='black jst mb0'>
                                    Ensuring swift and accurate loan approvals requires meticulous preparation and expertise. Decimal Point Analytics streamlines the SBA loan approval processes by:
                                    </Typography>
                                </Box>
                                <Box className='flx_fs' pt={1}>
                                    <CheckCircleIcon fontSize='small' className='blue typo-start-icon' />
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> <strong>Document Verification:</strong> Rigorous verification of documents as per specific loan program requirements ensures compliance and accuracy.</Typography>
                                    </Box>
                                </Box>
                                <Box className='flx_fs' pt={1}>
                                    <CheckCircleIcon fontSize='small' className='blue typo-start-icon' />
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> <strong>Financial Analysis:</strong> Initial financial spreading to assess eligibility and enhance loan structuring.</Typography>
                                    </Box>
                                </Box>
                                <Box className='flx_fs' pt={1}>
                                    <CheckCircleIcon fontSize='small' className='blue typo-start-icon' />
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> <strong>Background Checks:</strong> Conducting initial background searches to mitigate risks and verify borrower credibility.</Typography>
                                    </Box>
                                </Box>
                                <Box className='flx_fs' pt={1}>
                                    <CheckCircleIcon fontSize='small' className='blue typo-start-icon' />
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> <strong>Loan Packaging:</strong> Expertly assembling all necessary documentation and information into a comprehensive loan package ready for submission. Our dedicated project management team functions as a Lender Helpdesk, providing continuous support and guidance to ensure every loan structure meets eligibility criteria and regulatory standards.</Typography>
                                    </Box>
                                </Box> 

                                <Box mt={3}>
                                    <Link to="/sba#sbacontactus" onClick={() => scrollToElement('sbacontactus')}>

                                        <Button variant="contained" className='dpabtn_sm1' >
                                            Get Started
                                        </Button>
                                    </Link>
                                </Box>


                            </Box>


                        </Grid>

                    </Grid>
                </Container>
            </Box>


            <Box className='section2'>
                <Container maxWidth="lg">
                    <Grid container spacing={4} alignItems="top" justifyContent='center'>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box>

                                <Box py={1}>

                                    <Typography variant='h2' className='black h2v2 fw6'>
                                        SBA Loan Underwriting
                                    </Typography>

                                    <Typography variant='h6' className='black jst mb0'>
                                        Underwrite SBA loans at scale by leveraging our Loan Underwriting services. Lenders across the country utilize Decimal Point Analytics to underwrite SBA loans efficiently while providing premium customer support for borrowers.
                                    </Typography>
                                </Box>
                                <Box className='flx_fs' pt={1}>
                                    <CheckCircleIcon fontSize='small' className='blue typo-start-icon' />
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> Ensure Compliance with Rigorous SBA Requirements</Typography>
                                    </Box>
                                </Box>
                                <Box className='flx_fs' pt={1}>
                                    <CheckCircleIcon fontSize='small' className='blue typo-start-icon' />
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> Scale Your SBA Department with a Cost-Variable, Full-Scale Solution</Typography>
                                    </Box>
                                </Box>
                                <Box className='flx_fs' pt={1}>
                                    <CheckCircleIcon fontSize='small' className='blue typo-start-icon' />
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> Enhance Your Borrower Experience with Dedicated Expert Support</Typography>
                                    </Box>
                                </Box>
                               


                            </Box>


                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box className='al_center'>
                                <img src={loan} className='topbanner2' alt='SBA Loan Underwriting' />
                            </Box>
                        </Grid>

                    </Grid>

                    <Box>
                        <LoanUnderwriting />
                    </Box>

                </Container>
            </Box>


            <Box className='section2 sec_bg'>
                <Container maxWidth="lg">
                    <Grid container spacing={4} alignItems="top" justifyContent='center'>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box className='al_center'>
                                <img src={loanclose} className='topbanner2' alt='SBA Loan Processing & Closing' />
                            </Box>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>

                            <Box py={1}>

                                <Typography variant='h2' className='black h2v2 fw6'>
                                    SBA Loan Processing & Closing
                                </Typography>

                                <Typography variant='h6' className='black jst mb0'>
                                    Decimal Point Analytics prepares and submits the application packages to the SBA and USDA, ensuring timely receipt of SBA and USDA “Authorization” under the 7(a) program. We complete and coordinate all necessary third-party items such as searches, verifications, certifications, insurance, reports, and loan documentation.
                                </Typography>
                            </Box>


                        </Grid>

                    </Grid>
                </Container>
            </Box>

        </>
    );
}