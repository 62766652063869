import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from '../../Component/Writemessage3'
import banenr2 from "../../asset/images/animation/researchsolutionmid.gif";

import ic1 from "../../asset/images/innovation/rs1.svg";
import ic2 from "../../asset/images/innovation/rs2.svg";
import ic3 from "../../asset/images/innovation/rs3.svg";
import ic4 from "../../asset/images/innovation/rs4.svg";

import ds1 from "../../asset/images/innovation/ds1.svg";
import ds2 from "../../asset/images/innovation/ds2.svg";
import ds3 from "../../asset/images/innovation/ds3.svg";
import ds4 from "../../asset/images/innovation/ds4.svg";

import { Helmet } from 'react-helmet';

import ResearchSolution from "../../asset/images/new_banner/research_solution_banner.jpg"

export default function Researchsolution() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);





    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Augment Your Resources, Bespoke Research For All Your Needs</h1>
                <title>Best Research Solution Company with Real-Time Updates</title>
                <meta name="description" content="We at Decimal Point Analytics offer daily updated data with enhanced technology based research solutions to cater your business needs. Get In Touch Now" />
                <meta name="keywords" content="Company research provider
Credit research solutions provider
Equity research solutions provider
financial research solutions provider
market research company in US
market research solutions
Research company" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/leading-research-solution-company" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            {/* <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Research Solution
                                </Typography>

                                <Typography variant='h4' className='black'>
                                    Augment your resources, Bespoke Research for all your needs
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12}>
                            <Box className='al_right'>
                                <img src={indexbanner} className='topbanner' alt='indexbanner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}

            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + ResearchSolution + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={3} md={4} sm={6} xs={10}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Research Solution</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">Augment Your Resources, Bespoke Research For All Your Needs</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>


            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    {/* <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Research Solution
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid> */}


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center synopsic_bx">
                                <Typography variant='h6' className='black jst'>
                                    Decimal Point Analytics offers a variety of services to broad spectrum of clients in financial services industry, including buy-side, sell-side, and data providers. Use of algorithms and technology takes productivity and efficacy of many of our services to levels usually unseen in traditional research setups.
                                </Typography>
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Writemessage3 pageVisited="Research Solution" />

            <Box className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={3} alignItems="center">

                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={ic1} className='allic' alt='IC1' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h4' className='black al_center'>Company Analysis and Modeling</Typography>
                                            <Typography variant='h6' className='black al_center'>Methodical and detailed valuation models updated in real-time basis </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={ic2} className='allic' alt='IC2' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h4' className='black al_center'>Sector Study and Intelligence</Typography>
                                            <Typography variant='h6' className='black al_center'>Succinct, updated industry research and gleaning insights customized to your requirements </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={ic2} className='allic' alt='IC2' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h4' className='black al_center'>Risk Analysis</Typography>
                                            <Typography variant='h6' className='black al_center'>Carry out risk and attribution analysis of your portfolio on daily basis </Typography>
                                        </Box>
                                    </Box>
                                </Grid>


                            </Grid>

                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box className='al_center'>
                                <img src={banenr2} className='topbanner2' alt='Banner2' />
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={ic3} className='allic' alt='IC3' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h4' className='black al_center'>Portfolio Valuation</Typography>
                                            <Typography variant='h6' className='black al_center'>We take away drudgery from your daily reconciliation and valuation activity</Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={ic4} className='allic' alt='IC4' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h4' className='black al_center'>Structured Product Pricing</Typography>
                                            <Typography variant='h6' className='black al_center'>We use advanced quants and artificial Intelligence techniques to value structured products</Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={ic4} className='allic' alt='IC4' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h4' className='black al_center'>Market Monitor</Typography>
                                            <Typography variant='h6' className='black al_center'>Daily coverage and analysis of Global markets for Equity, Fixed Income, Commodity, Forex and M&A </Typography>
                                        </Box>
                                    </Box>
                                </Grid>


                            </Grid>
                        </Grid>

                    </Grid>
                </Container>
            </Box>



            <Box className='section sec_bg ourstrength' component='section'>
                <Box className='over'></Box>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>

                    <Box className='mob_card'  mt={3}>
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Box className='str_bg mnhg4'>
                                    <Box mb={2}>
                                        <img src={ds1} className='bxicon' alt='DS1' />
                                    </Box>
                                    <Box>
                                        <Box>
                                            <Typography className='blue' variant='h4'>Technical Alerts</Typography>
                                        </Box>
                                        <Box mt={1}>
                                            <Typography variant='h6' className='black al_center'>
                                                Tailor-made technical reports generated and disseminated through our automation programs
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Box className='str_bg mnhg4'>
                                    <Box mb={2}>
                                        <img src={ds2} className='bxicon' alt='DS2' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>Company Reports</Typography>
                                        </Box>
                                        <Box mt={1}>
                                            <Typography variant='h6' className='black al_center'>
                                                Professional study on companies to create initiation reports
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Box className='str_bg mnhg4'>
                                    <Box mb={2}>
                                        <img src={ds3} className='bxicon' alt='DS3' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>Market Strategy Reports</Typography>
                                        </Box>
                                        <Box mt={1}>
                                            <Typography variant='h6' className='black al_center'>
                                                Detailed market strategy covering multiple asset classes
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Box className='str_bg mnhg4'>
                                    <Box mb={2}>
                                        <img src={ds4} className='bxicon' alt='DS4' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>Monthly Macro Economic Analysis</Typography>
                                        </Box>
                                        <Box mt={1}>
                                            <Typography variant='h6' className='black al_center'>
                                                Comprehensive coverage and update on economies around the world
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>

            </Box>


        </>
    );
}
