import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import indexbanner from "../../asset/images/aboutus/structure_inn.png";
import val1 from "../../asset/images/aboutus/stru1.svg";
import val2 from "../../asset/images/aboutus/stru2.svg";
import val3 from "../../asset/images/aboutus/stru3.svg";

import structure from "../../asset/images/new_banner/structure_innovation_banner.jpg"
import { Helmet } from 'react-helmet';

export default function Structure_innovation() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);





    return (
        <>
            <Helmet>
                <title>Structure Innovation | Decimal Point Analytics </title>
                <meta name="description" content="Learn how our approach, inspired by Edison's philosophy, emphasizes the process-driven nature of innovation & technology to drive continuous value creation" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/structure-innovation" />
            </Helmet>


            {/* <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Structure of Innovation
                                </Typography>

                                <Typography variant='h4' className='black'>
                                    A process comprising of Cultural, Technical and Functional Excellence
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12}>
                            <Box className='al_right'>
                                <img src={indexbanner} className='topbanner' alt='Index Banner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}

            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + structure + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={4} md={5} sm={6} xs={10}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Structure of Innovation</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">A process comprising of Cultural, Technical and Functional Excellence</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>


            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    {/* <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Structure of Innovation
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid> */}


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center synopsic_bx">
                                <Typography variant='h6' className='black jst'>
                                    Many believe that innovators are born, such as some genius like Mozart. However, our experience shows that innovation is an outcome of process and not some magic. In this we should follow the thinking of the most prolific innovator of all times, Thomas Alva Edison, who said “Genius is ninety nine percent perspiration and one percent inspiration.”
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    The way to look at the process of innovation is that Innovation Process can be thought of as a sailboat. The hull of this sailboat is the culture of the firm, the rudder of this sailboat is the functional expertise of the firm and the mainsail represents the winds of technology driving the innovation sailboat. For a layperson, it seems that technology alone matters since she sees only the correspondence between the technology and the innovation direction. However, the hull of culture has to be strong not only to absorb the force of technology changes but also to actually move forward in the desired direction. This desired direction comes from the functional expertise of the firm, hence we term it as the rudder of the sailboat of innovation. The layperson mostly misses the importance of the hull of the culture, in the journey of innovation sailboat, unless it is starts leaking. Also, she cannot fully comprehend that the functional expertise drives the direction of innovation.
                                </Typography>
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>



            <Box className='section ourstrength ' component='section'>

                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <img src={indexbanner} className='topbanner' alt='Index Banner' />
                            </Box>
                            <Box mt={4}>
                                <Typography variant='h6' className='black al_center'>
                                    Hence, the approach to innovation should have the following three aspects:
                                </Typography>
                            </Box>


                        </Grid>
                    </Grid>

                    <Box className='mob_card structure_card' mt={3}>
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='str_bg mnhg1'>
                                    <Box >
                                        <img src={val1} className='bxicon' alt='Val1' />
                                    </Box>
                                    <Box>
                                        <Typography variant='h4' className='blue al_center'>
                                            Cultural Aspects of Innovation
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='str_bg mnhg1'>
                                    <Box>
                                        <img src={val2} className='bxicon' alt='Val2' />
                                    </Box>
                                    <Box>
                                        <Typography variant='h4' className='blue al_center'>
                                            Technical Excellence for Innovation and finally
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='str_bg mnhg1'>
                                    <Box>
                                        <img src={val3} className='bxicon' alt='Val3' />
                                    </Box>
                                    <Box>
                                        <Typography variant='h4' className='blue al_center'>
                                            Functional Excellence for Innovation
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box mt={4}>
                                    <Typography variant='h6' className='black jst'>
                                        The reason we should have this trifurcation is that Innovation is a never-ending process which can yield outcome on regular basis, creating value for the organization and for the society as well. This process, like any other process, needs to be thoughtfully designed and then reframed on regular basis. However, unlike other mundane processes, the process of innovation, although can prove to be an exciting journey, can feel nebulous to control on daily basis. That is because, unlike other processes, this process is deep rooted in the cultural meme of the organization.
                                    </Typography>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>



                </Container>

            </Box>


        </>
    );
}
