import React from 'react';
import { Route } from 'react-router-dom';
import SBAFooter from './SBAFooter';
import SBAHeader from './SBAHeader';
import Header from './Header';
import FooterNew from './Footer_new';
import MegamenuHeader from './MegamenuHeader';
 
const DPALayout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <FooterNew />
    </>
  );
};
 
const MainLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <DPALayout>
          <Component {...props} />
        </DPALayout>
      )}
    />
  );
};

 
const DPALayout1 = ({ children }) => {
  return (
    <>
      <SBAHeader />
      {children}
      <SBAFooter />
    </>
  );
};
 
const SBALayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <DPALayout1>
          <Component {...props} />
        </DPALayout1>
      )}
    />
  );
};


const DPALayout2 = ({ children }) => {
  return (
    <> 
      {children}
      <SBAFooter />
    </>
  );
};


const SBASubmitLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <DPALayout2>
          <Component {...props} />
        </DPALayout2>
      )}
    />
  );
};


const DPALayout3 = ({ children }) => {
  return (
    <> 
    <MegamenuHeader/>
      {children} 
      <FooterNew />

    </>
  );
};


const MegamenuLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <DPALayout3>
          <Component {...props} />
        </DPALayout3>
      )}
    />
  );
};


export { MainLayout, SBALayout, SBASubmitLayout, MegamenuLayout };
