import { IconButton, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Carousel from "react-elastic-carousel";


import Eventback from '../../../Component/Eventback'
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';

import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

import C1 from "../../../asset/images/media/event/21nov_c1.jpg"
import C2 from "../../../asset/images/media/event/21nov_c2.jpg"
import C3 from "../../../asset/images/media/event/21nov_c3.jpg"
import C4 from "../../../asset/images/media/event/21nov_c4.jpg"
import C5 from "../../../asset/images/media/event/21nov_c5.jpg"
import C6 from "../../../asset/images/media/event/21nov_c6.jpg"
import C7 from "../../../asset/images/media/event/21nov_c7.jpg"
import C8 from "../../../asset/images/media/event/21nov_c8.jpg"
import C9 from "../../../asset/images/media/event/21nov_c9.jpg"
import C10 from "../../../asset/images/media/event/21nov_c10.jpg"
import { Helmet } from 'react-helmet';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);



export default function Event_21_Nov() {



    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <title>Trends & Innovations in Start-ups | Decimal Point Analytics</title>
                <meta name="description" content="Explore insights from Mr. Shailesh Dhuri's keynote at Chetana’s Institutes of Management's Elixir 2019 on start-up trends and challenges." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/chetana-institutes-of-management" />
            </Helmet>

            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center mb0'>
                                    Chetana’s Institutes of Management
                                </Typography>
                                <Typography className=' mainsmhead black al_center mb0'>
                                    Nov, 2019
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>





                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                            Mr Shailesh Dhuri was the Chief Guest at Chetana’s Institutes of Managements' Elixir 2019 on 21st November 2019.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The event's focuss was on Start-ups: Trends & More  <br />
                            Twist in the trends- providing better services through food deliveries, cabs, pharma supplies etc. <br />
                            Ways to communicate the vision & purpose of start-ups. <br />
                            Start-ups in uncommon areas such as A.I, Agrotechnology etc. <br />
                            Angel investors and Venture capitalists funding various start-ups. <br />
                            Tackling challenges faced by start-ups- lessons learnt and promoting start-up culture.
                        </Typography>

                        <Box className='al_center'>
                            <IconButton className='blue' onClick={handleClickOpen}>
                                <CameraAltOutlinedIcon />
                            </IconButton>
                        </Box>

                        <Box className='al_center'>
                            <Typography className='blink_text'>Click Here...</Typography>
                        </Box>


                        {/* <Subscribe /> */}

                    </Box>




                    <Eventback />




                </Container>
            </Box>




            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="b_modal teammodal">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose}>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Grid container spacing={2}>
                            <Carousel itemsToShow={1} animateOut="slideOutDown"
                                animateIn="flipInX">
                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + C1 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + C2 + ')', }}></Box>
                                </Box>
                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + C3 + ')', }}></Box>
                                </Box>
                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + C4 + ')', }}></Box>
                                </Box>
                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + C5 + ')', }}></Box>
                                </Box>
                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + C6 + ')', }}></Box>
                                </Box>
                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + C7 + ')', }}></Box>
                                </Box>
                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + C8 + ')', }}></Box>
                                </Box>
                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + C9 + ')', }}></Box>
                                </Box>
                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + C10 + ')', }}></Box>
                                </Box>

                            </Carousel>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>


        </>
    );
}
