import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import AP from '../../../asset/images/media/apnews.png';
import CNO from '../../../asset/images/media/canadian_news.png';
import ENO from '../../../asset/images/media/economic_news_observer.png';
import TER from '../../../asset/images/media/the_entreprenurship_reporter.png';
import Fox40 from '../../../asset/images/media/fox40.png';
import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';


export default function CanadianRegTechAward() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>DPA Wins Canadian Reg Tech Award for Best ESG Solution 2023</title>
                <meta name="description" content="Discover how Decimal Point Analytics won the Canadian RegTech Award for Best ESG Solution 2023. Learn about their commitment to advancing ESG practices." />
                <meta name="keywords" content="Decimal Point Analytics, Canadian Reg Tech Award, Best ESG Solution 2023, Financial Technology Solutions, RegTech, Environmental Social Governance, ESG Solutions, Sustainable Business Practices, Innovation, Regulatory Technology, Award-Winning Solution, ESG Integration, Decision-Making, Excellence, Industry Best Practices, RegTech Sector, Cutting-Edge Solutions" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimal-point-analytics-wins-canadian-reg-tech-award-for-best-esg-solution-2023" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Decimal Point Analytics Wins Canadian Reg Tech Award for Best ESG Solution 2023
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Toronto - <Link to="/" className="link">Decimal Point Analytics</Link>, a leader in innovative financial technology solutions, has been named the winner in the prestigious Canadian RegTech Awards for its exceptional contributions to Environmental, Social, and Governance (ESG) solutions. This recognition underscores the company's unwavering commitment to fostering sustainable and responsible business practices.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The <a href="https://www.canadianregtech.ca" className="link" rel='noreferrer' target='_blank'>Canadian RegTech</a> Awards recognize excellence and innovation in the rapidly evolving field of regulatory technology. Decimal Point Analytics stood out among tough competition for its outstanding contributions to Environmental, Social, and Governance (ESG) solutions, showcasing the company's commitment to advancing sustainable and responsible business practices.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        "We are honored to receive this recognition from the Canadian RegTech Awards," said Shailesh Dhuri, CEO, of Decimal Point Analytics. " At Decimal Point, we are driven by a mission to develop cutting-edge solutions that not only meet but surpass the ever-changing needs of our clients, particularly in the critical domain of ESG. This award is a testament to the unwavering dedication and innovation of our team."
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        ESG considerations have become integral to modern business practices, and Decimal Point Analytics has demonstrated a profound understanding of the complexities associated with integrating ESG factors into decision-making processes. The company's winning solution not only aligns with industry best practices but also sets new standards for excellence in the RegTech sector.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The Canadian RegTech Awards are a benchmark for recognizing leaders and innovators in the regulatory technology space, and Decimal Point Analytics is proud to be acknowledged as the winner in the 'Best ESG Solution' category.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        For more information about Decimal Point Analytics and its award-winning ESG solution, please visit <Link to="/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses" className="link">Decimalpointanalytics.com</Link>
                                    </Typography>



                                </Box>

                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='space-between'>

                                <Grid item lg={2} md={3} sm={3} xs={6}>
                                    <a href="https://apnews.com/press-release/ein-presswire-newsmatics/financial-technology-059c89d87151dff97f08826e551cfc43" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + AP + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={2} md={3} sm={3} xs={6}>
                                    <a href="https://www.canadiannewsonline.com/article/671136855-decimal-point-analytics-wins-canadian-reg-tech-award-for-best-esg-solution-2023" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + CNO + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={2} md={3} sm={3} xs={6}>
                                    <a href="https://www.economicnewsobserver.com/article/671136855-decimal-point-analytics-wins-canadian-reg-tech-award-for-best-esg-solution-2023" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + ENO + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>


                                <Grid item lg={2} md={3} sm={3} xs={6}>
                                    <a href="https://fox40.com/business/press-releases/ein-presswire/671136855/decimal-point-analytics-wins-canadian-reg-tech-award-for-best-esg-solution-2023/" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + Fox40 + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={2} md={3} sm={3} xs={6}>
                                    <a href="https://www.entrepreneurshipreporter.com/article/671136855-decimal-point-analytics-wins-canadian-reg-tech-award-for-best-esg-solution-2023" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + TER + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
