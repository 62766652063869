import {
  Container,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Writemessage3 from '../../Component/Writemessage3'

import CAST1 from "../../asset/images/insight/podcast/shailesh_sir_podcast.png";
import CAST2 from "../../asset/images/insight/podcast/ramesh_sir_podcast.png";

import CAST_HR from "../../asset/images/insight/podcast/hr_insight.jpg";
import CAST_ESG from "../../asset/images/insight/podcast/esg_exploring.jpg";
import RedularityBurder from "../../asset/images/insight/podcast/regularity_burder.png";

import AnalyticsIndia from "../../asset/images/insight/podcast/analytics_india_magazine.png";
import FutureWithSuyash from "../../asset/images/insight/podcast/exploring_future_with_suyash.png";

import CanadasESGLandscape from "../../asset/images/insight/podcast/Exploring_Canadas_ESG_Landscape.png";
import CRTA from '../../asset/images/insight/podcast/esg_canada_podcast_for_crta.png';
import StrategicInsights from '../../asset/images/insight/podcast/strategic_Insights.png'
import AmitPodcast from '../../asset/images/insight/podcast/bi_amit_dhalia.png';
import EsgLandscpae from '../../asset/images/insight/podcast/navigating_esg_landscape.png';
import USApath from '../../asset/images/insight/podcast/usa_path_net_zero.png';
import EvolutionAPI from '../../asset/images/insight/podcast/evolution_of_api.png';
import UnlockingSuccess2024 from '../../asset/images/insight/podcast/unlocking_success_2024.png';
import EntrepreneurLounge from '../../asset/images/insight/podcast/entrepreneur_lounge_of_india.png';
import SmallBusiness24 from '../../asset/images/insight/podcast/navigating-challenges.png';
import TransformingPDFInteractions from '../../asset/images/insight/podcast/transforming_pdf_interactions.png';
import InsightsIntoSBA from '../../asset/images/insight/podcast/insights_into_sba.png';


export default function Podcast_New() {


  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>

      <Box
        className="section sec_bg"
        component="section"
        style={{ paddingTop: "3%" }}
      >

        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="al_center" mt={5}>
                <Typography variant="h2" className="black">
                  Podcast
                </Typography>
              </Box>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="al_center synopsic_bx" mb={3}>
                <Typography variant="h6" className="black jst" gutterBottom>
                  At Decimal Point Analytics, we believe in the power of knowledge and the impact it can have on decision-making. With our podcast, we aim to provide you with valuable insights and actionable information that can help you navigate the ever-evolving financial markets.
                </Typography>

                <Typography variant="h6" className="black jst">
                  From discussing emerging technologies like artificial intelligence and blockchain to examining the role of data analytics in investment strategies, our podcast covers a wide range of topics that are relevant to investors, financial professionals, entrepreneurs, or simply interested in understanding the dynamics of the global economy, our engaging conversations with industry experts provide valuable insights to keep you informed and empowered.
                </Typography>
              </Box>
            </Grid>


          </Grid>
        </Container>


        {/* <Container maxWidth="lg" className="bdr">
          <Box className="section synopsic_bx" style={{ paddingTop: "2%" }}>
            <Grid container spacing={1} alignItems="top" justifyContent="center">
              
            </Grid>
          </Box>
        </Container> */}


        <Writemessage3 pageVisited="Podcast" />



        <Box id="podcast" className="reportinfo" pt={4}>
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <a href="https://www.youtube.com/watch?v=X4DCAKyLuEY" target="_blank" rel="noopener noreferrer">

                  <Box className="reportm_bg mnhg2">
                    <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box
                          className="reportimg_bg"
                          style={{ backgroundImage: "url(" + InsightsIntoSBA + ")" }}
                        >
                        </Box>
                      </Grid>
                      <Grid item lg={8} md={8} sm={6} xs={12}>
                        <Box className="reporttitle_box">
                          <Typography className="reporttitle two">
                            Insights into SBA Loan Processing: Unlocking Efficiency through Streamlined Operations for Increased Productivity
                          </Typography>

                          <Box className="pod_info" mt={2}>
                            <Box >
                              <Typography className="black" variant="h6">
                                In this episode, we invited Shubhendu Verma, the API development and integration expert at Decimal Point Analytics, to discuss the fundamentals of APIs and their importance.
                              </Typography>
                            </Box>
                            <Box mt={2}>
                              <Typography className="reporttitle" variant="h6">
                                Shubhendu breaks down the essentials of APIs:
                              </Typography>
                              <Typography variant='h6' className='black jst' gutterBottom>
                                <ul>
                                  <li>
                                    <Typography variant='h6' className='black jst'>
                                      Understand how APIs facilitate communication between applications (client requests, server responds).
                                    </Typography>
                                  </li>
                                  <li>
                                    <Typography variant='h6' className='black jst'>
                                      Explore the evolution of APIs, from internal tools in the 70s to the internet-fueled explosion of the 2000s.
                                    </Typography>
                                  </li>
                                </ul>
                              </Typography>
                              <Typography variant="h6" className="blue">
                                <strong>APIs power the connected world,</strong><span className='black jst'> enabling smooth communication between apps and devices. Tune in to unlock the fascinating world of APIs!</span>
                              </Typography>
                            </Box>
                          </Box>

                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <a href="https://www.youtube.com/watch?v=eSfGBubb7gk&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjIB&index=3" target="_blank" rel="noopener noreferrer">

                  <Box className="reportm_bg mnhg2">
                    <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box
                          className="reportimg_bg"
                          style={{ backgroundImage: "url(" + TransformingPDFInteractions + ")" }}
                        >
                        </Box>
                      </Grid>
                      <Grid item lg={8} md={8} sm={6} xs={12}>
                        <Box className="reporttitle_box">
                          <Typography className="reporttitle two">
                            Insights into SBA Loan Processing: Unlocking Efficiency through Streamlined Operations for Increased Productivity
                          </Typography>

                          <Box className="pod_info" mt={2}>
                            <Box >
                              <Typography className="black" variant="h6">
                                In this episode, we invited Shubhendu Verma, the API development and integration expert at Decimal Point Analytics, to discuss the fundamentals of APIs and their importance.
                              </Typography>
                            </Box>
                            <Box mt={2}>
                              <Typography className="reporttitle" variant="h6">
                                Shubhendu breaks down the essentials of APIs:
                              </Typography>
                              <Typography variant='h6' className='black jst' gutterBottom>
                                <ul>
                                  <li>
                                    <Typography variant='h6' className='black jst'>
                                      Understand how APIs facilitate communication between applications (client requests, server responds).
                                    </Typography>
                                  </li>
                                  <li>
                                    <Typography variant='h6' className='black jst'>
                                      Explore the evolution of APIs, from internal tools in the 70s to the internet-fueled explosion of the 2000s.
                                    </Typography>
                                  </li>
                                </ul>
                              </Typography>
                              <Typography variant="h6" className="blue">
                                <strong>APIs power the connected world,</strong><span className='black jst'> enabling smooth communication between apps and devices. Tune in to unlock the fascinating world of APIs!</span>
                              </Typography>
                            </Box>
                          </Box>

                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <a href="https://open.spotify.com/episode/0YWQLh7HA7z8QvV5AbAvTm" target="_blank" rel="noopener noreferrer">

                  <Box className="reportm_bg mnhg2">
                    <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box
                          className="reportimg_bg"
                          style={{ backgroundImage: "url(" + EntrepreneurLounge + ")" }}
                        >
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="reporttitle_box">
                          <Typography className="reporttitle two">
                            Insights into SBA Loan Processing: Unlocking Efficiency through Streamlined Operations for Increased Productivity
                          </Typography>

                          <Box className="pod_info" mt={2}>
                            <Box >
                              <Typography className="black" variant="h6">
                                In this episode, we invited Shubhendu Verma, the API development and integration expert at Decimal Point Analytics, to discuss the fundamentals of APIs and their importance.
                              </Typography>
                            </Box>
                            <Box mt={2}>
                              <Typography className="reporttitle" variant="h6">
                                Shubhendu breaks down the essentials of APIs:
                              </Typography>
                              <Typography variant='h6' className='black jst' gutterBottom>
                                <ul>
                                  <li>
                                    <Typography variant='h6' className='black jst'>
                                      Understand how APIs facilitate communication between applications (client requests, server responds).
                                    </Typography>
                                  </li>
                                  <li>
                                    <Typography variant='h6' className='black jst'>
                                      Explore the evolution of APIs, from internal tools in the 70s to the internet-fueled explosion of the 2000s.
                                    </Typography>
                                  </li>
                                </ul>
                              </Typography>
                              <Typography variant="h6" className="blue">
                                <strong>APIs power the connected world,</strong><span className='black jst'> enabling smooth communication between apps and devices. Tune in to unlock the fascinating world of APIs!</span>
                              </Typography>
                            </Box>
                          </Box>

                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <a href="https://www.youtube.com/watch?v=CtZGDkqROCY&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjI" target="_blank" rel="noopener noreferrer">

                  <Box className="reportm_bg mnhg2">
                    <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box
                          className="reportimg_bg"
                          style={{ backgroundImage: "url(" + UnlockingSuccess2024 + ")" }}
                        >
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="reporttitle_box">
                          <Typography className="reporttitle two">
                            Insights into SBA Loan Processing: Unlocking Efficiency through Streamlined Operations for Increased Productivity
                          </Typography>

                          <Box className="pod_info" mt={2}>
                            <Box >
                              <Typography className="black" variant="h6">
                                In this episode, we invited Shubhendu Verma, the API development and integration expert at Decimal Point Analytics, to discuss the fundamentals of APIs and their importance.
                              </Typography>
                            </Box>
                            <Box mt={2}>
                              <Typography className="reporttitle" variant="h6">
                                Shubhendu breaks down the essentials of APIs:
                              </Typography>
                              <Typography variant='h6' className='black jst' gutterBottom>
                                <ul>
                                  <li>
                                    <Typography variant='h6' className='black jst'>
                                      Understand how APIs facilitate communication between applications (client requests, server responds).
                                    </Typography>
                                  </li>
                                  <li>
                                    <Typography variant='h6' className='black jst'>
                                      Explore the evolution of APIs, from internal tools in the 70s to the internet-fueled explosion of the 2000s.
                                    </Typography>
                                  </li>
                                </ul>
                              </Typography>
                              <Typography variant="h6" className="blue">
                                <strong>APIs power the connected world,</strong><span className='black jst'> enabling smooth communication between apps and devices. Tune in to unlock the fascinating world of APIs!</span>
                              </Typography>
                            </Box>
                          </Box>

                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={12} xs={12}>
                <a href="https://open.spotify.com/episode/0YWQLh7HA7z8QvV5AbAvTm" target="_blank" rel="noopener noreferrer">

                  <Box className="reportm_bg mnhg2">
                    <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box
                          className="reportimg_bg h211"
                          style={{ backgroundImage: "url(" + EntrepreneurLounge + ")" }}
                        >
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="reporttitle_box">
                          <Typography className="reporttitle two">
                            Insights into SBA Loan Processing: Unlocking Efficiency through Streamlined Operations for Increased Productivity
                          </Typography>

                          <Box className="pod_info" mt={2}>
                            <Box >
                              <Typography className="black" variant="h6">
                                In this episode, we invited Shubhendu Verma, the API development and integration expert at Decimal Point Analytics, to discuss the fundamentals of APIs and their importance.
                              </Typography>
                            </Box>
                            <Box mt={2}>
                              <Typography className="reporttitle" variant="h6">
                                Shubhendu breaks down the essentials of APIs:
                              </Typography>
                              <Typography variant='h6' className='black jst' gutterBottom>
                                <ul>
                                  <li>
                                    <Typography variant='h6' className='black jst'>
                                      Understand how APIs facilitate communication between applications (client requests, server responds).
                                    </Typography>
                                  </li>
                                  <li>
                                    <Typography variant='h6' className='black jst'>
                                      Explore the evolution of APIs, from internal tools in the 70s to the internet-fueled explosion of the 2000s.
                                    </Typography>
                                  </li>
                                </ul>
                              </Typography>
                              <Typography variant="h6" className="blue">
                                <strong>APIs power the connected world,</strong><span className='black jst'> enabling smooth communication between apps and devices. Tune in to unlock the fascinating world of APIs!</span>
                              </Typography>
                            </Box>
                          </Box>

                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={12} xs={12}>
                <a href="https://www.youtube.com/watch?v=CtZGDkqROCY&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjI" target="_blank" rel="noopener noreferrer">

                  <Box className="reportm_bg mnhg2">
                    <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box
                          className="reportimg_bg h211"
                          style={{ backgroundImage: "url(" + UnlockingSuccess2024 + ")" }}
                        >
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="reporttitle_box">
                          <Typography className="reporttitle two">
                            Insights into SBA Loan Processing: Unlocking Efficiency through Streamlined Operations for Increased Productivity
                          </Typography>

                          <Box className="pod_info" mt={2}>
                            <Box >
                              <Typography className="black" variant="h6">
                                In this episode, we invited Shubhendu Verma, the API development and integration expert at Decimal Point Analytics, to discuss the fundamentals of APIs and their importance.
                              </Typography>
                            </Box>
                            <Box mt={2}>
                              <Typography className="reporttitle" variant="h6">
                                Shubhendu breaks down the essentials of APIs:
                              </Typography>
                              <Typography variant='h6' className='black jst' gutterBottom>
                                <ul>
                                  <li>
                                    <Typography variant='h6' className='black jst'>
                                      Understand how APIs facilitate communication between applications (client requests, server responds).
                                    </Typography>
                                  </li>
                                  <li>
                                    <Typography variant='h6' className='black jst'>
                                      Explore the evolution of APIs, from internal tools in the 70s to the internet-fueled explosion of the 2000s.
                                    </Typography>
                                  </li>
                                </ul>
                              </Typography>
                              <Typography variant="h6" className="blue">
                                <strong>APIs power the connected world,</strong><span className='black jst'> enabling smooth communication between apps and devices. Tune in to unlock the fascinating world of APIs!</span>
                              </Typography>
                            </Box>
                          </Box>

                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={12} xs={12}>
                <a href="https://www.youtube.com/watch?v=L_W3oLcUQw8" target="_blank" rel="noopener noreferrer">

                  <Box className="reportm_bg mnhg2">
                    <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box
                          className="reportimg_bg h211"
                          style={{ backgroundImage: "url(" + SmallBusiness24 + ")" }}
                        >
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="reporttitle_box">
                          <Typography className="reporttitle two">
                            Navigating Challenges for Small Businesses in 2024: Rising Interest Rates in US
                          </Typography>

                          <Box className="pod_info" mt={2}>
                            <Box >
                              <Typography className="black" variant="h6">
                                In this episode, we invited Shubhendu Verma, the API development and integration expert at Decimal Point Analytics, to discuss the fundamentals of APIs and their importance.
                              </Typography>
                            </Box>
                            <Box mt={2}>
                              <Typography className="reporttitle" variant="h6">
                                Shubhendu breaks down the essentials of APIs:
                              </Typography>
                              <Typography variant='h6' className='black jst' gutterBottom>
                                <ul>
                                  <li>
                                    <Typography variant='h6' className='black jst'>
                                      Understand how APIs facilitate communication between applications (client requests, server responds).
                                    </Typography>
                                  </li>
                                  <li>
                                    <Typography variant='h6' className='black jst'>
                                      Explore the evolution of APIs, from internal tools in the 70s to the internet-fueled explosion of the 2000s.
                                    </Typography>
                                  </li>
                                </ul>
                              </Typography>
                              <Typography variant="h6" className="blue">
                                <strong>APIs power the connected world,</strong><span className='black jst'> enabling smooth communication between apps and devices. Tune in to unlock the fascinating world of APIs!</span>
                              </Typography>
                            </Box>
                          </Box>

                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a href="https://www.youtube.com/watch?v=CtZGDkqROCY&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjI" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + UnlockingSuccess2024 + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        Unlocking Success: 2024's Top Five ESG Trends for Asset Managers
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a href="https://www.youtube.com/watch?v=L_W3oLcUQw8" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + SmallBusiness24 + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        Navigating Challenges for Small Businesses in 2024: Rising Interest Rates in US
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a href="https://www.youtube.com/watch?v=3E-QBcE2GI0" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + EvolutionAPI + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        The Evolution of APIs: How They Work and Why Are They Important?
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a href="https://www.youtube.com/watch?v=3Pu2weDavYE" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + USApath + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        DPA USA ESG Review 2023: USA's Path to Net-Zero and Responsible Investing
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a href="https://www.youtube.com/watch?v=Dtuna7KnjUQ" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + EsgLandscpae + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        Navigating the ESG Landscape: Insights from Prasad Nawathe
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a href="https://youtu.be/GcI0eq-OQHs?si=yQjQx3W__VPI-Clz" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + AmitPodcast + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        How Business Intelligence is Changing Businesses in Today's Ever-Changing World?
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a href="https://www.youtube.com/watch?v=tg7y2BCDqdM" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + StrategicInsights + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        Strategic Insights into Finance & Innovation with Pravas Sahoo
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>


              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a href="https://open.spotify.com/episode/3hBMTuRrENqKYBeYz3pcnG?si=PPONZwUiSie6Futo1XsBIg&nd=1" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + CRTA + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        Ajay Jindal in Conversation with CRTA on Canada ESG Review 2023
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>



              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a href="https://www.youtube.com/watch?v=wsSgZ22V-Tw" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + CanadasESGLandscape + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        Exploring Canada’s ESG Landscape: Insights from DPA’s ESG Review
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>



              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a href="https://www.youtube.com/watch?v=WRyLIRguWMw&t=2s" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + FutureWithSuyash + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        Data warehousing Cloud and Beyond: Exploring the Future with Suyash Shrivastava
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>



              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a href="https://www.youtube.com/watch?v=FDO5ldmEfX4" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + AnalyticsIndia + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        How will GPTs change the Analytics Landscape?
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>




              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a href="https://podcasters.spotify.com/pod/show/canadianregtech/episodes/Is-Machine-Readable-Regulation-the-Answer-to-Regulatory-Burden-e25ej7v/a-a9vh8gh" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + RedularityBurder + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        Is Machine Readable Regulation the Answer to Regulatory Burden?
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>



              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a href="https://www.youtube.com/watch?v=iTmO6qbip9Q" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + CAST_ESG + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        Exploring the ESG landscape and Net Zero possibilities!
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>


              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a href="https://youtu.be/M_-gONwkGbY" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + CAST_HR + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        HR Insights: Expert Analysis & Trends
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>



              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a href="https://www.youtube.com/watch?v=49h8lRYKCis" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + CAST1 + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        CEO Shailesh Dhuri in an Exclusive interview with Start-up Story Media
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>


              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a href="https://www.youtube.com/watch?v=k2NVUWU6TAc" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + CAST2 + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        Ramesh Seshadri on building Process Efficiency for US Lenders
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>




            </Grid>
          </Container>
        </Box>



      </Box >
    </>
  );
}
