import React, { Component } from "react";
import { Button, Container, Grid, Typography, Box, } from '@material-ui/core';
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import web1 from "../../../asset/images/media/event/webinar1.jpg";


export class VideoModal extends Component {

  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }


  render() {
    return (
      <>

        <Box className='mainsection' component='section' id='top'>
          <Container maxWidth='lg'>
            <Grid container spacing={1} alignItems="center">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <Typography variant='h3' className='skblue al_center'>
                    Webinar : Vol 3
                  </Typography>
                  <Typography variant='h1' className='blue al_center'>
                    Role of Data Analytics and Digital Assets in the Post COVID World - Webinar
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>



        <Box className='section sec_bg' component='section'>
          <Container maxWidth='lg' className='bdr'>
            <Grid container spacing={3} alignItems="center">
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography variant='h6' className='black jst'>
                    The webinar was organized by EBG Federation in association with Decimal Point Analytics. Our CEO, Shailesh Dhuri was the speaker and he spoke about the "Role of Data Analytics and Digital Assets in the Post COVID World."
                  </Typography>

                  <Typography variant='h6' className='blue jst'>
                    <strong>The discussion answers these 3 important questions relevant in today's time: </strong>
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    <ul>
                      <li>Streamline their existing databases </li>
                      <li>Monitor their existing portfolio performance</li>
                      <li>Improve decision timeline on go-no-go decisions about new opportunities</li>
                    </ul>
                  </Typography>
                </Box>
                <Box className='al_left' mt={3}>
                  <Link to='/webinar'>
                    <Button variant="contained" className='dpabtn_gr' startIcon={<ArrowBackIcon />}>
                      Back to Webinar
                    </Button>
                  </Link>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box className='al_center' style={{ position: 'relative', }}>
                  <Box className='videobg_gr'></Box>
                  <Box className='videobga' style={{ backgroundImage: 'url(' + web1 + ')', }}>
                    <button onClick={this.openModal} class="pulse-button"></button>
                  </Box>
                </Box>

                <ModalVideo
                  channel="youtube"
                  isOpen={this.state.isOpen}
                  videoId="ukBxXvjEj-I"
                  onClose={() => this.setState({ isOpen: false })}
                />


              </Grid>
            </Grid>
          </Container>
        </Box>


      </>
    );
  }
}

export default VideoModal;
