import { Container, Grid, Typography, Box, Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import CarouselMulti from "react-multi-carousel";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import anonymous_database from "../../asset/images/oracle/anonymous_database.png";
import referance_arch from "../../asset/images/oracle/referance_arch.png";
import OracleExpertise from "../../asset/images/oracle/banner/oracle_cloud_expertise.jpg";
import OracleApex from "../../asset/images/oracle/banner/oracle_apex.jpg";
import OracleGlobal from "../../asset/images/oracle/banner/oracle_global_leader.jpg";


import processing1 from "../../asset/images/oracle/processing/processing1.png";
import processing2 from "../../asset/images/oracle/processing/processing2.png";
import processing3 from "../../asset/images/oracle/processing/processing3.png";
import processing4 from "../../asset/images/oracle/processing/processing4.png";
import processing5 from "../../asset/images/oracle/processing/processing5.png";

import app1 from "../../asset/images/oracle/applications/app1.png";
import app2 from "../../asset/images/oracle/applications/app2.png";
import app3 from "../../asset/images/oracle/applications/app3.png";
import app4 from "../../asset/images/oracle/applications/app4.png";
import app5 from "../../asset/images/oracle/applications/app5.png";

import OracleContactUs from '../../Component/forms/OracleContactUs';



export default function Oracle() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    // Scroll to form started
    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const stickyItemsHeight = 30;
            const offset = element.getBoundingClientRect().top - stickyItemsHeight;


            setTimeout(() => {
                window.scrollBy({ top: offset, behavior: 'smooth' });
            }, 50);
        }
    };

    useEffect(() => {
        const { hash } = window.location;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const offset = 50;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - offset;
                window.scrollBy({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }
        }
    }, []);
    // Scroll to form ended

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title> Decimal Point Analytics: Oracle ATP & APEX Experts | Data Management Solutions </title>
                <meta name="description" content="Decimal Point Analytics (DPA) specializes in Oracle ATP and APEX, offering top-tier data management, performance optimization, security, and rapid application development with seamless migrations tailored to your needs." />
                <meta name="keywords" content="Oracle ATP, Oracle APEX, Autonomous Database, Data Management Solutions, Cloud Services, Performance Optimization, Security, Scalability, Low-Code Development, Data Integration" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/oracle" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="new_landing_carousel">
                <CarouselMulti
                    responsive={responsive}
                    arrows={false}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    infinite={true}
                    autoPlay={true}
                    pauseOnHover={false}
                    keyBoardControl={true}
                    customTransition="opacity 200ms ease-in-out"
                    autoPlaySpeed={9500}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-0-px"
                >

                    <>
                        <Box className="mainsection1 oracle_section" component="section" style={{ backgroundImage: "url(" + OracleExpertise + ")" }}>
                            <Container maxWidth="lg">
                                <Grid container spacing={1} alignItems="center" justifyContent='center'>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className="text_animated">
                                            <Typography variant="h2" className="black3" gutterBottom>
                                                Optimize Your Business with
                                            </Typography>

                                            <Box>
                                                <Typography variant="h2" className="blue fw6" gutterBottom>
                                                    <span className='ora_red'>Oracle</span> Cloud Expertise
                                                </Typography>
                                            </Box>

                                            <Box mt={2}>
                                                <Link to="/contact-us#contact_form">
                                                    <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                                                        Let's Talk
                                                    </Button>
                                                </Link>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={5} md={6} sm={10} xs={10}>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </>

                    <>
                        <Box className="mainsection1 oracle_section" component="section" style={{ backgroundImage: "url(" + OracleGlobal + ")" }}>
                            <Container maxWidth="lg">
                                <Grid container spacing={1} alignItems="center" justifyContent='center'>
                                    <Grid item lg={12} md={12} sm={11} xs={10}>
                                        <Box className="text_animated">
                                            <Typography variant="h2" className="black3" gutterBottom>
                                                <span className='ora_red'>Oracle</span> Global Leaders Recognizes Decimal Point Analytics as
                                            </Typography>

                                            <Box>
                                                <Typography variant="h2" className="blue fw6" gutterBottom>
                                                    ISV of the Year 2023
                                                </Typography>
                                            </Box>

                                            <Box mt={2}>
                                                <Link to="/oracle-global-leaders-recognizes-decimal-point-analytics-as-isv-of-the-year-2023">
                                                    <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                                                        Learn More
                                                    </Button>
                                                </Link>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={5} md={6} sm={10} xs={10}>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </>

                    <>
                        <Box className="mainsection1 oracle_section" component="section" style={{ backgroundImage: "url(" + OracleApex + ")" }}>
                            <Container maxWidth="lg">
                                <Grid container spacing={1} alignItems="center" justifyContent='center'>
                                    <Grid item lg={12} md={12} sm={11} xs={10}>
                                        <Box className="text_animated">

                                            <Box>
                                                <Typography variant="h2" className="blue fw6" gutterBottom>
                                                    <span className='ora_red'>Oracle</span> Apex
                                                </Typography>
                                            </Box>

                                            <Typography variant="h2" className="black3" gutterBottom>
                                                Build enterprise apps 20x faster with 100x less code
                                            </Typography>

                                            <Box mt={2}>
                                                <Link to="/oracle#oraclecontactus" onClick={() => scrollToElement('oraclecontactus')}>
                                                    <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                                                        Contact Us
                                                    </Button>
                                                </Link>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={5} md={6} sm={10} xs={10}>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </>

                </CarouselMulti>
            </Box>

            <Box className='section2' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center">
                                <Typography variant='h6' className='black al_center' gutterBottom>
                                    At Decimal Point Analytics (DPA), we specialize in leveraging Oracle Autonomous Transaction Processing Database and Oracle APEX on Cloud to deliver efficient and cutting-edge data management solutions. Our team of Oracle cloud experts delivers high-performance, scalable, and rapidly developed applications tailored to your business goals.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id='sba_solutions' className='section mob_card sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={3} alignItems="center" justifyContent='center'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Oracle Autonomous Transaction Processing (ATP)
                                    </Typography>

                                    <Typography variant='h6' className='black al_center'>
                                        Our expertise in Oracle ATP provides a self-driving database that automates database management tasks, enabling you to focus on extracting valuable insights from your data.
                                    </Typography>
                                </Box>

                            </Grid>
                        </Grid>


                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className="ora_experties">
                                <Box className='ora_iconbx'>
                                    <img src={processing1} className='allic' alt='Autonomy and Self-Management' />
                                </Box>
                                <Box mt={1} mb={2}>
                                    <Typography variant="h4" className="black al_center" gutterBottom>Autonomy <span className='lower'>and</span> Self-Management: </Typography>
                                </Box>
                                <Typography variant="h6" className="black al_center">
                                    With Oracle ATP's autonomous capabilities, your team can focus on insights, not infrastructure. Benefit from automatic tuning, patching, and security updates.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className="ora_experties">
                                <Box className='ora_iconbx'>
                                    <img src={processing2} className='allic' alt='Performance Acceleration' />
                                </Box>
                                <Box mt={1} mb={2}>
                                    <Typography variant="h4" className="black al_center" gutterBottom>Performance Acceleration:</Typography>
                                </Box>
                                <Typography variant="h6" className="black al_center">
                                    Experience lightning-fast query responses and real-time analytics with ATP's in-memory column store and machine learning-driven optimizations.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className="ora_experties">
                                <Box className='ora_iconbx'>
                                    <img src={processing3} className='allic' alt='Security Fortification' />
                                </Box>
                                <Box mt={1} mb={2}>
                                    <Typography variant="h4" className="black al_center" gutterBottom>Security Fortification:</Typography>
                                </Box>
                                <Typography variant="h6" className="black al_center">
                                    Protect your sensitive data with ATP's built-in security features, including encryption, auditing, and threat detection.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className="ora_experties">
                                <Box className='ora_iconbx'>
                                    <img src={processing4} className='allic' alt='Scalability and Cost Efficiency' />
                                </Box>
                                <Box mt={1} mb={2}>
                                    <Typography variant="h4" className="black al_center" gutterBottom>Scalability <span className='lower'>and</span> Cost Efficiency:</Typography>
                                </Box>
                                <Typography variant="h6" className="black al_center">
                                    Adapt to changing business demands with ATP's elastic scalability, paying only for the resources you use.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className="ora_experties">
                                <Box className='ora_iconbx'>
                                    <img src={processing5} className='allic' alt='Migration and Onboarding' />
                                </Box>
                                <Box mt={1} mb={2}>
                                    <Typography variant="h4" className="black al_center" gutterBottom>Migration <span className='lower'>and</span> Onboarding:</Typography>
                                </Box>
                                <Typography variant="h6" className="black al_center">
                                    Seamlessly migrate your existing databases to ATP with our expert guidance, minimizing downtime and risk.
                                </Typography>
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

            <Box id='sba_solutions' className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={3} alignItems="center" justifyContent='left'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Oracle APEX (Application Express)
                                    </Typography>

                                    <Typography variant='h6' className='black al_center'>
                                        Our proficiency in Oracle APEX empowers businesses to build robust, data-driven web applications rapidly and efficiently.
                                    </Typography>
                                </Box>

                            </Grid>
                        </Grid>


                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box className="ora_app flx_fs align_c">
                                <Box className='ora_app_iconbx' mr={1}>
                                    <img src={app1} className='allic' alt='Data-Driven Applications' />
                                </Box>
                                <Box mt={1} mb={2}>
                                    <Typography variant="h4" className="black al_left" gutterBottom>Data-Driven Applications: </Typography>

                                    <Typography variant="h6" className="black al_left jst">
                                        Create robust applications that leverage the power of your data, providing actionable insights.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box className="ora_app flx_fs align_c">
                                <Box className='ora_app_iconbx' mr={1}>
                                    <img src={app2} className='allic' alt='Seamless Integration' />
                                </Box>
                                <Box mt={1} mb={2}>
                                    <Typography variant="h4" className="black al_left" gutterBottom>Seamless Integration:</Typography>

                                    <Typography variant="h6" className="black al_left jst">
                                        Seamlessly integrate data from various sources into your APEX applications, creating a unified view.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box className="ora_app flx_fs align_c">
                                <Box className='ora_app_iconbx' mr={1}>
                                    <img src={app4} className='allic' alt='Stunning User Experiences' />
                                </Box>
                                <Box mt={1} mb={2}>
                                    <Typography variant="h4" className="black al_left" gutterBottom>Stunning User Experiences:</Typography>

                                    <Typography variant="h6" className="black al_left jst">
                                        We design intuitive and visually appealing interfaces that enhance user engagement and productivity.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box className="ora_app flx_fs align_c">
                                <Box className='ora_app_iconbx' mr={1}>
                                    <img src={app3} className='allic' alt='Rock-Solid Security' />
                                </Box>
                                <Box mt={1} mb={2}>
                                    <Typography variant="h4" className="black al_left" gutterBottom>Rock-Solid Security:</Typography>

                                    <Typography variant="h6" className="black al_left jst">
                                        Your data is our priority. We implement stringent security measures to protect your applications and data.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box className="ora_app flx_fs align_c">
                                <Box className='ora_app_iconbx' mr={1}>
                                    <img src={app5} className='allic' alt='Optimized Performance' />
                                </Box>
                                <Box mt={1} mb={2}>
                                    <Typography variant="h4" className="black al_left" gutterBottom>Optimized Performance:</Typography>

                                    <Typography variant="h6" className="black al_left jst">
                                        We fine-tune your APEX applications for optimal performance, ensuring fast load times and responsiveness.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

            <Box id='sba_solutions' className="section sec_bg" component="section">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center">
                                <Typography className="black" variant="h2">
                                    Insights
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            {/* <Box className="al_center">
                                <Typography className="blue h2v2 fw5" variant="h2">
                                    Case Study
                                </Typography>
                            </Box> */}


                            <Grid container spacing={2} alignItems="center" justifyContent='center'>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='reportinfo'>
                                        <a href='https://www.oracle.com/customers/dpa-case-study/' target='_blank' rel="noopener noreferrer">
                                            <Box className="reportm_bg">
                                                <Box
                                                    className="reportimg_bg"
                                                    style={{
                                                        backgroundImage: 'url(' + anonymous_database + ')',
                                                    }}
                                                >
                                                    <Button variant="contained" className='ora_title_btn'>
                                                        Case Study
                                                    </Button>
                                                </Box>

                                                <Box my={2}>
                                                    <Typography className="reporttitle">
                                                        DPA overcomes project complexity, improves performance with Autonomous Database
                                                    </Typography>
                                                </Box>
                                                <Box className="line"></Box>
                                                <Box mt={2}>
                                                    <a href='https://www.oracle.com/customers/dpa-case-study/' target='_blank' rel="noopener noreferrer">
                                                        <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                                                            Read More
                                                        </Button>
                                                    </a>
                                                </Box>
                                            </Box>
                                        </a>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            {/* <Box className="al_center">
                                <Typography className="blue h2v2 fw5" variant="h2">
                                    Reference Architecture
                                </Typography>
                            </Box> */}


                            <Grid container spacing={2} alignItems="center" justifyContent='center'>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='reportinfo'>
                                        <a href='https://docs.oracle.com/en/solutions/migrate-aws-rds-to-adb/index.html#GUID-66B461FC-C64F-445E-A9B8-DBAF5B0AD3BD' target='_blank' rel="noopener noreferrer">
                                            <Box className="reportm_bg">
                                                <Box
                                                    className="reportimg_bg"
                                                    style={{
                                                        backgroundImage: 'url(' + referance_arch + ')',
                                                    }}
                                                >
                                                    <Button variant="contained" className='ora_title_btn'>
                                                        Reference Architecture
                                                    </Button>
                                                </Box>

                                                <Box my={2}>
                                                    <Typography className="reporttitle">
                                                        Modernize and consolidate AWS RDS for MySQL and MongoDB Atlas on Oracle Autonomous Database
                                                    </Typography>
                                                </Box>
                                                <Box className="line"></Box>
                                                <Box mt={2}>
                                                    <a href='https://docs.oracle.com/en/solutions/migrate-aws-rds-to-adb/index.html#GUID-66B461FC-C64F-445E-A9B8-DBAF5B0AD3BD' target='_blank' rel="noopener noreferrer">
                                                        <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                                                            Read More
                                                        </Button>
                                                    </a>
                                                </Box>
                                            </Box>
                                        </a>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Container>
            </Box >

            <Box>
                <OracleContactUs />
            </Box>

        </>
    );
}
