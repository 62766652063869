import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import CSR from '../../../asset/images/media/the_csr_universe.jpg';
import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export default function UnveilingNetZero() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title> Decimal Point Analytics: Green Energy Initiatives </title>
                <meta name="description" content="Discover how DPA transitions to green energy, eliminates plastic use, and leverages technology for sustainability. Learn about their Net Zero journey." />
                <meta name="keywords" content="Sustainability, green energy, Net Zero Emission, data analytics, Decimal Point Analytics, transparency, blockchain" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/unveiling-net-zero-decoding-decimal-point-analytics-purposeful-journey-towards-sustainability" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Unveiling Net Zero: Decoding Decimal Point Analytics' Purposeful Journey Towards Sustainability
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    {/* <Typography variant='h6' className='black jst' gutterBottom>
                                        <Link to="/" className="link">Decimal Point Analytics</Link>, a leading data analytics firm, has made a significant commitment to sustainability by transitioning all its offices in India to green energy and targeting depolymerization to eliminate plastic usage by 2026, aligning with the goal of Net Zero Emission. In an exclusive interview with TheCSRUniverse, Mr. <a href='https://www.linkedin.com/in/shaileshdhuri/' target='_blank' rel="noopener noreferrer">Shailesh Dhuri</a>, CEO, Decimal Point Analytics, highlighted the motivations, partnerships, and innovative approaches driving this transition.
                                    </Typography> */}

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        In a world grappling with environmental challenges, the pursuit of sustainable solutions extends beyond traditional sectors. Today, our focus shifts to <Link to="/" className="link">Decimal Point Analytics</Link>, a leading data analytics firm that is redefining corporate responsibility. Venturing beyond their core expertise, the company is making a noteworthy move by transitioning all their offices in India to green energy and targeting depolymerization to eliminate plastic usage by 2026, while also aligning their operations with the goal of Net Zero Emission. This move is more than just an environmental statement; it signals a strategic shift toward a future powered by renewable energy and guided by responsible business practices.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        In an exclusive interview with The <a href='https://thecsruniverse.com/articles/unveiling-net-zero-decoding-decimal-point-analytics-purposeful-journey-towards-sustainability' target='_blank' rel="noopener noreferrer">CSRUniverse</a>, Mr. <a href='https://www.linkedin.com/in/shaileshdhuri/' target='_blank' rel="noopener noreferrer">Shailesh Dhuri</a>, CEO, Decimal Point Analytics, provides insights into the company's journey towards green energy. We explore the motivations, strategic partnerships, and innovative approaches driving the company's transition to environmentally responsible operations. This in-depth conversation goes beyond immediate impact, revealing how the company utilizes its analytical capabilities to optimize sustainability efforts internally and across industries. Join us as we delve into Decimal Point Analytics' commitment to catalyzing positive change in the corporate landscape through this strategic move towards green energy.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Scroll down for detailed insights.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Q&A</strong>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Q. What inspired Decimal Point Analytics to commit all offices to operate on green energy and embark on the journey towards Net Zero Emission? </strong>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>A. We would like India to take the lead in the evolution of humanity.</strong> In terms of the famous Kardashev scale, humanity is still a Type 0 civilization that extracts most of its energy from crude organic-based sources. We need to progress to a Type 1 civilization that starts using environmentally friendly cosmic energy from its solar system.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Adam Smith</strong> in his book <strong><em>"The Theory of Moral Sentiments"</em></strong> says that morality is natural and built into us as social beings. By following our conscience and the rules of morality engineered by nature, we end up promoting the happiness of mankind. Our voluntary pursuit of net zero emission can be seen with this lens as well.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong><em>Dharma</em></strong> and <strong><em>Artha</em></strong> are the two key (if the four types) of purushartha in Indian philosophy. We are guided by these values as well. We believe that as responsible citizens it is our Dharma or moral duty to care for the environment. It is also aligned with Artha or prosperity in the long run.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Q. Could you share specific details on the innovative approaches or technologies Decimal Point Analytics has implemented to transition its Mumbai Corporate Office, Analytics Office in GIFT City, and Nashik facility to green energy?  </strong>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>A. </strong> We purchase renewable energy from the grid voluntarily. We have also implemented several energy saving measures which include the use of energy efficient lighting, cooling and other office equipment. Not only are these energy efficient, but also part of our conscious strategy to break away from the traditional energy systems that no longer serve the needs of a modern office setup.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Q. How did partnerships with Adani Power, Gift City Power Corporation Limited, and Maharashtra State Electricity Distribution Company Ltd influence the specific green energy solutions adopted for each location, and how was the company able to tailor its approach to address each site's unique needs and opportunities in the renewable energy initiative?</strong>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>A. </strong> As per prevailing guidelines, distribution companies (DISCOMS) are allowed to sell 100% green energy to retail, industrial and commercial users. Green power costs an extra Rs 1 per unit when supplied by these DISCOMS.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        DPA has voluntarily opted for this regime and is paying extra for our electricity consumption. This is entirely in keeping with our desire and commitment to contribute in protecting the environment and achieving the Net Zero goalpost.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        In our office in Gujarat International Finance Tec-City (GIFT City), Gandhinagar, we are blessed with energy-efficient cooling solutions as well. GIFT City has employed a centralized cooling system called District cooling system (DCS), which is a technology that distributes thermal energy in the form of chilled water from a central source to multiple buildings through a network of underground insulated pipes. This state-of-the-art technology has been implemented in India for the first time at the GIFT city.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Q. Considering the commitment to ISO/IEC and ISO certifications, how do these standards complement Decimal Point Analytics' environmental, social, and governance (ESG) initiatives, and what synergies exist between them? </strong>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>A. </strong> In our business, information security is vital as our clients entrust us with a great amount of their sensitive data.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Information security governance and client satisfaction are integral to the Governance and Social pillars of ESG. With this in mind, we operate in the firm belief that delivering strong performance in these key areas will go a long way towards long-term success of an organization.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        We have worked towards and achieved ISO/IEC 27001 certification, which is the world's best-known standard for information security management systems (ISMS) and a key certification that benchmarks information security worldwide. We have also been assessed for ISO 9001, which is a globally recognised standard for quality management.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        We have implemented key practices in our organization by taking on board suggestions from these certifying bodies and are assured that these will play an important role in enabling world class delivery of our analytics solutions to our clients.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Q. In what ways does Decimal Point Analytics envision replicating its sustainable practices across global facilities in the coming years, and what is the expected impact on the broader global movement towards Net Zero Emission? </strong>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>A. </strong> As we expand, we will replicate our model of keeping our scope 1 and scope 2 emissions aligned with Net Zero. We are also working on a planned approach for mitigating scope 3 emissions, which we incur largely due to travel.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Besides decarbonization, we want to focus on depolymerization. We are targeting eliminating the use of plastic in our offices by 2026.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Q. Decarbonization can feel like a vast, abstract goal. How did you translate that into a tangible, actionable initiative like the Green Power Tariff, and what were the key decision points in that process?</strong>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>A. </strong> On the contrary, we believe that decarbonisation lends itself to various types of tangible actions including initiatives such as the <strong>Green Power Tariff</strong>. For example, concerning to scope 2 emissions related to electricity, the user has the choice to switch to renewable energy. We mentioned Kardashev’s model of the advancement of civilizations. A civilization that depends on fossil fuel is a Type 0 civilization. Humankind is on its way to a Type 1 civilization as it seeks to eliminate the use of fossil fuels.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        In our nature of business, we do not encounter scope 1 issues. However, where cooling solutions are a necessity, users should opt for more energy-efficient products with environmentally friendly cooling agents. GIFT city has implemented one such solution.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Q. Many companies talk about greenwashing. What concrete steps have you taken to ensure transparency and accountability in Decimal Point Analytics' sustainability journey, beyond just switching to green energy?</strong>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>A. </strong> Our business is among the low-impact emission businesses. We do not have any direct fuel consumption (scope 1). Scope 2 emissions related to purchased electricity are our main environmental impact presently. This has prompted our transition to green energy.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        We have also estimated our scope 3 emissions, which is due to travel. We are working on a company-wide initiative to keep our travel-related emissions under control and offset any emissions that cannot be avoided.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        By the end of 2025, we aim to integrate data and power consumption information into a blockchain, providing transparent access to the public. Currently, this information is accessible on our website.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Q. Decimal Point Analytics has a strong reputation in data analytics. How do you leverage your analytical capabilities to inform and optimize your sustainability efforts, both internally and across industries?</strong>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>A. </strong> ESG related solutions is a service area for DPA. We provide ESG consulting to funds in India and outside. We help funds in formulating their ESG strategies, policies and processes, empower them with data and analytics, and in reporting. In recent times, we have started extending our services to help companies with their ESG measurement and reporting.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Within our own organization, we have an ESG team to plan and implement our main ESG themes in a focused manner. Apart from emissions, we also track social parameters like gender diversity and employee attrition, and how they compare to our desired goals. We keep certain budgets for employee activities and see that they are utilised well. We have internal dashboards where we track some key parameters to make sure we are performing well on them.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Q. Beyond environmental impact, how does Decimal Point Analytics perceive its responsibility as a catalyst for positive change within the industry, and what specific outcomes or changes is the company aiming to influence?</strong>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>A. </strong> Since our inception, DPA has always strived towards cultivating and promoting a culture which works towards upliftment of society as we grow as an organization. Over the years, we have undertaken several initiatives which reflect our commitment to the overall development of society. Some of these are highlighted below:
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Gyandapi-Dhanodapi: </strong> This is an initiative undertaken to develop a strong and well-rounded workforce. The five-year program offers employment and post-graduation learning opportunities to academically bright graduates with financially weak background. Under this program, candidates undergo an internship program for the first six months. Post satisfactory completion of the internship, candidates undergo a series of trainings across all departments at DPA, along with job responsibilities for the next two years. Candidates who excel during these two years are sponsored for post-graduation by DPA and on successful completion of post-graduation are eligible for higher roles in the organization.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Support to NGOs:  </strong> We undertake projects that support NGOs, which is dedicated to improving the lives of the underprivileged by assisting them in developing competencies that enhance their ability to deliver better results. Additionally, I serve as one of the mentors for the <strong>Smile Foundation</strong>.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Q. In fostering a corporate culture aligned with green energy, how does Decimal Point Analytics communicate its commitment internally to employees, and what initiatives are in place to engage and involve the workforce in sustainability efforts?</strong>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>A. </strong> We conduct periodic sessions with employees to communicate and articulate our standpoint and focussed efforts towards energy efficiency. We also encourage them, through regular communications, to reinforce our messages on other aspects of ESG such as gender diversity, equal opportunity, and ensuring a healthy and safe working environment for all employees.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Q. While achieving Net Zero Emission is a crucial goal, it can seem distant. How are you setting and measuring specific, achievable milestones along the way to keep the momentum going and demonstrate progress? </strong>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>A. </strong>  As mentioned earlier, our approach to manage scope 1 and scope 2 emissions are well underway.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Our next goal is to focus on scope 3 emissions in the medium term. We hope to start this once the first lot of companies under SEBI’s BRSR Core guidelines start working on value chain emissions. We are also anticipating India’s announcement regarding its <strong>carbon tax mechanism</strong>, which will inform our strategy and roadmap as well.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Q.  In the future, what inventive strategies and initiatives is Decimal Point Analytics considering to enhance its ESG practices and uphold its leadership in environmentally conscious business approaches</strong>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>A. </strong>  We are initiating the application of AI-ML-based techniques across various solutions, including ESG. In basic applications, we automate the extraction of ESG data and qualitative information from unstructured documents, such as corporate filings. This approach is being applied to our ESG Reporting solution, ESG Green Pulse. Additionally, we have created an AI-based ESG news analytics solution that enables banks and funds to obtain a more comprehensive assessment of the ESG performance of companies.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The effectiveness of this approach has been acknowledged by some of our corporate clients, who have expressed interest in having us implement it within their unique organizational settings.
                                    </Typography>


                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>

                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <a href="https://thecsruniverse.com/articles/unveiling-net-zero-decoding-decimal-point-analytics-purposeful-journey-towards-sustainability" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + CSR + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
