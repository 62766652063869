import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function Nashik_table() {

    const data = [

        { monthYear: 'Jun-24', dpa: 9242, aim: 8814, total: 18056, employees: 303, ue: 60 },
        { monthYear: 'May-24', dpa: 12335, aim: 10263, total: 22598, employees: 304, ue: 74 },
        { monthYear: 'Apr-24', dpa: 11376, aim: 9077, total: 20453, employees: 293, ue: 70 },
        { monthYear: 'Mar-24', dpa: 6578, aim: 6618, total: 13196, employees: 281, ue: 47 },
        { monthYear: 'Feb-24', dpa: 4723, aim: 5806, total: 10529, employees: 283, ue: 37 },
        { monthYear: 'Jan-24', dpa: 4669, aim: 4854, total: 9523, employees: 278, ue: 34 },
        { monthYear: 'Dec-23', dpa: 4985, aim: 4934, total: 9919, employees: 278, ue: 36 },
        { monthYear: 'Nov-23', dpa: 6719, aim: 5956, total: 12675, employees: 289, ue: 44 },
        { monthYear: 'Oct-23', dpa: 7530, aim: 7002, total: 14532, employees: 297, ue: 49 },
        { monthYear: 'Sep-23', dpa: 5582, aim: 6306, total: 11888, employees: 297, ue: 40 },
        { monthYear: 'Aug-23', dpa: 5732, aim: 6155, total: 11887, employees: 302, ue: 39 },
        { monthYear: 'Jul-23', dpa: 5749, aim: 6701, total: 12450, employees: 292, ue: 43 },
        { monthYear: 'Jun-23', dpa: 8069, aim: 8788, total: 16857, employees: 293, ue: 58 },
        { monthYear: 'May-23', dpa: 8623, aim: 9016, total: 17639, employees: 316, ue: 56 },
        { monthYear: 'Apr-23', dpa: 6791, aim: 7736, total: 14527, employees: 317, ue: 46 },
        { monthYear: 'Mar-23', dpa: 5340, aim: 5529, total: 10869, employees: 330, ue: 33 },
        { monthYear: 'Feb-23', dpa: 3869, aim: 4004, total: 7873, employees: 312, ue: 25 },
        { monthYear: 'Jan-23', dpa: 3345, aim: 3477, total: 6822, employees: 320, ue: 21 },
        { monthYear: 'Dec-22', dpa: 2451, aim: 2447, total: 4898, employees: 314, ue: 16 },
        { monthYear: 'Nov-22', dpa: 2923, aim: 2810, total: 5733, employees: 309, ue: 19 },
        { monthYear: 'Oct-22', dpa: 3211, aim: 2709, total: 5920, employees: 300, ue: 20 },
        { monthYear: 'Sep-22', dpa: 3362, aim: 2845, total: 6207, employees: 284, ue: 22 },
        { monthYear: 'Aug-22', dpa: 3164, aim: 2928, total: 6092, employees: 272, ue: 22 },
        { monthYear: 'Jul-22', dpa: 2724, aim: 4033, total: 6757, employees: 249, ue: 27 },
        { monthYear: 'Jun-22', dpa: 3921, aim: 3224, total: 7145, employees: 261, ue: 27 },
        { monthYear: 'May-22', dpa: 5031, aim: 1296, total: 6327, employees: 244, ue: 26 },
        { monthYear: 'Apr-22', dpa: 5226, aim: 1296, total: 6522, employees: 235, ue: 28 },
        { monthYear: 'Mar-22', dpa: 3919, aim: 1296, total: 5215, employees: 208, ue: 25 },
        { monthYear: 'Feb-22', dpa: 2764, aim: 1296, total: 4060, employees: 204, ue: 20 },
        { monthYear: 'Jan-22', dpa: 2290, aim: 1634, total: 3924, employees: 190, ue: 21 },
        { monthYear: 'Dec-21', dpa: 2346, aim: 1347, total: 3693, employees: 163, ue: 23 },
        { monthYear: 'Nov-21', dpa: 2857, aim: 907, total: 3764, employees: 160, ue: 24 },
        { monthYear: 'Oct-21', dpa: 3345, aim: 689, total: 4034, employees: 161, ue: 25 },
        { monthYear: 'Sep-21', dpa: 3028, aim: 365, total: 3393, employees: 149, ue: 23 },
        { monthYear: 'Aug-21', dpa: 3110, aim: 74, total: 3184, employees: 143, ue: 22 },
        { monthYear: 'Jul-21', dpa: 2968, aim: 248, total: 3216, employees: 128, ue: 25 },
        { monthYear: 'Jun-21', dpa: 3394, aim: 130, total: 3524, employees: 129, ue: 27 },
        { monthYear: 'May-21', dpa: 4747, aim: 260, total: 5007, employees: 127, ue: 39 },
        { monthYear: 'Apr-21', dpa: 5005, aim: 267, total: 5272, employees: 114, ue: 46 },
        { monthYear: 'Mar-21', dpa: 4481, aim: 288, total: 4769, employees: 114, ue: 42 },
        { monthYear: 'Feb-21', dpa: 3109, aim: 79, total: 3188, employees: 110, ue: 29 },
        { monthYear: 'Jan-21', dpa: 2361, aim: 81, total: 2442, employees: 103, ue: 24 },
        { monthYear: 'Dec-20', dpa: 30798, aim: 49, total: 30847, employees: 105, ue: 294 },
        { monthYear: 'Nov-20', dpa: 2987, aim: 50, total: 3037, employees: 102, ue: 30 },
        { monthYear: 'Oct-20', dpa: 2211, aim: 48, total: 2259, employees: 102, ue: 22 },
        { monthYear: 'Sep-20', dpa: 0, aim: 73, total: 73, employees: 104, ue: 1 },
        { monthYear: 'Aug-20', dpa: 0, aim: 41, total: 41, employees: 102, ue: 0 },
        { monthYear: 'Jul-20', dpa: 0, aim: 43, total: 43, employees: 95, ue: 0 },
        { monthYear: 'Jun-20', dpa: 0, aim: 46, total: 46, employees: 92, ue: 1 },
        { monthYear: 'May-20', dpa: 273, aim: 99, total: 372, employees: 81, ue: 5 },
        { monthYear: 'Apr-20', dpa: 273, aim: 1, total: 274, employees: 67, ue: 4 },
        { monthYear: 'Mar-20', dpa: 1992, aim: 7, total: 1999, employees: 67, ue: 30 },
        { monthYear: 'Feb-20', dpa: 2845, aim: 0, total: 2845, employees: 64, ue: 44 },
        { monthYear: 'Jan-20', dpa: 2608, aim: 0, total: 2608, employees: 62, ue: 42 },
    ];

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', borderRadius: '0px', padding: '5px' }}>
            <TableContainer sx={{ maxHeight: '260px' }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ minWidth: '50px' }}>MM/YY</TableCell>
                            <TableCell align="right" className='skblue' sx={{ minWidth: '20px' }}>DPA</TableCell>
                            <TableCell align="right" className='skblue' sx={{ minWidth: '20px' }}>AIM</TableCell>
                            <TableCell align="right" className='skblue' sx={{ minWidth: '20px' }}>Total</TableCell>
                            <TableCell align="right" sx={{ minWidth: '30px' }}>EMPLOYEES</TableCell>
                            <TableCell align="right" sx={{ minWidth: '50px' }}>U/E</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.monthYear}</TableCell>
                                <TableCell align="right">{item.dpa}</TableCell>
                                <TableCell align="right">{item.aim}</TableCell>
                                <TableCell align="right">{item.total}</TableCell>
                                <TableCell align="right">{item.employees}</TableCell>
                                <TableCell align="right">{item.ue}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}