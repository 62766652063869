import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Blog_Data from '../../Pages/Insight/Blog_Data';
import Case_studies_Data from '../../Pages/Insight/Case_studies_Data';
import Articles_Data from '../../Pages/Insight/Articles_Data';

import TransformingPDFInteractions from '../../asset/images/insight/podcast/transforming_pdf_interactions.png';
import InsightsIntoSBA from '../../asset/images/insight/podcast/insights_into_sba.png';
import LeveragingSalesforce from '../../asset/images/insight/podcast/leveraging_salesforce_automation.png';



export default function VerticalCarousel({ onClick }) {


    return (

        <>
            <Box className='vertical-carosol'>
                <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
                    <Grid item lg={3} md={3} sm={3} xs={12}>

                        <Link to="/blog" onClick={onClick}>
                            <Grid container spacing={0} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Box>
                                        <Typography variant='h4' className="blue fw5">Blog </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant='h6' className="black fw5 two">Embark on an enlightening journey into the dynamic realms of Generative AI, Data Analytics, Machine Learning, Research, and ESG with Decimal Point Analytics, your trusted guide to transformative technologies.</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Box className="vertical-scroll">
                                        <Box className="scroll-content">
                                            <Grid container spacing={0} alignItems="top" justifyContent="flex-start">
                                                {Blog_Data.slice(0, 3).map((item, index) => (
                                                    <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                                                        <Box className="inner-card">
                                                            <Box className='bg-img' style={{
                                                                backgroundImage: `url(${item.imgsrc})`,
                                                            }}>
                                                            </Box>
                                                            <Typography variant='h6' className='fs11 black two' title={item.heading}>{item.heading}</Typography>
                                                        </Box>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Link>

                    </Grid>

                    <Grid item lg={3} md={3} sm={3} xs={12}>

                        <Link to="/case-studies" onClick={onClick}>
                            <Grid container spacing={0} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Box>
                                        <Typography variant='h4' className="blue fw5">Stories </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant='h6' className="black fw5 two">At the heart of our success lie the triumphs of our clients, where strategic brilliance meets and harmonizes with forward-thinking progress.</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Box className="vertical-scroll">
                                        <Box className="scroll-content">
                                            <Grid container spacing={0} alignItems="top" justifyContent="flex-start">
                                                {Case_studies_Data.slice(0, 3).map((item, index) => (
                                                    <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                                                        <Box className="inner-card">
                                                            <Box className='bg-img' style={{
                                                                backgroundImage: `url(${item.imgsrc})`,
                                                            }}>
                                                            </Box>
                                                            <Typography variant='h6' className='fs11 black two' title={item.subheading} > {item.heading}</Typography>
                                                        </Box>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Link>

                    </Grid>

                    <Grid item lg={3} md={3} sm={3} xs={12}>

                        <Link to="/articles" onClick={onClick}>
                            <Grid container spacing={0} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Box>
                                        <Typography variant='h4' className="blue fw5"> Podcast</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant='h6' className="black fw5 two">At Decimal Point Analytics, we believe in the power of knowledge and the impact it can have on decision-making. With our podcast, we aim to provide you with valuable insights and actionable information that can help you navigate the ever-evolving financial markets.</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Box className="vertical-scroll">
                                        <Box className="scroll-content">
                                            <Box className="inner-card">
                                                <Box className='bg-img ' style={{ backgroundImage: "url(" + LeveragingSalesforce + ")" }}>
                                                </Box>
                                                <Typography variant='h6' className='fs11 black two'>Leveraging Salesforce Automation for Financial Reporting & Analysis</Typography>
                                            </Box>
                                            <Box className="inner-card">
                                                <Box className='bg-img ' style={{ backgroundImage: "url(" + InsightsIntoSBA + ")" }}>
                                                </Box>
                                                <Typography variant='h6' className='fs11 black two'>Insights into SBA Loan Processing: Unlocking Efficiency twoough Streamlined Operations for Increased Productivity</Typography>
                                            </Box>
                                            <Box className="inner-card">
                                                <Box className='bg-img ' style={{ backgroundImage: "url(" + TransformingPDFInteractions + ")" }}>
                                                </Box>
                                                <Typography variant='h6' className='fs11 black two'>Transforming PDF Interactions with Sanika Pandit</Typography>
                                            </Box>


                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Link>

                    </Grid>


                    <Grid item lg={3} md={3} sm={3} xs={12}>

                        <Link to="/podcast" onClick={onClick}>
                            <Grid container spacing={0} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Box>
                                        <Typography variant='h4' className="blue fw5">Article </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant='h6' className="black fw5 two">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Box className="vertical-scroll">
                                        <Box className="scroll-content">
                                            <Grid container spacing={0} alignItems="top" justifyContent="flex-start">
                                                {Articles_Data.slice(0, 3).map((item, index) => (
                                                    <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                                                        <Box className="inner-card">
                                                            <Box className='bg-img' style={{
                                                                backgroundImage: `url(${item.imgsrc})`,
                                                            }}>
                                                            </Box>
                                                            <Typography variant='h6' className='fs11 black two' title={item.heading} > {item.heading}</Typography>
                                                        </Box>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Link>

                    </Grid>

                </Grid>
            </Box>

        </>

    );
}