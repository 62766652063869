import { Container, Grid, Typography, Box } from "@material-ui/core";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import ic1 from "../../asset/images/smartpipe/pricing/free.svg";
import ic3 from "../../asset/images/smartpipe/pricing/business.svg";

import "react-modal-video/scss/modal-video.scss";

import ecopulse from "../../asset/images/new_banner/eco_pulse_banner.jpg";
import { Link } from "react-router-dom";
import PdfQueryTable from "./SmartCloud/PdfQueryTable";

export default function PdfQuery() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

 

  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <title>PdfQuery - Upload Your PDFs & Get Instant Insights</title>
        <meta
          name="description"
          content="Our AI-powered search feature empowers users to interact directly with their documents, enabling them to pose questions and receive accurate answers. Whether you're dealing with a single document or a vast library of files, our platform supports multiple uploads and offers comprehensive insights across all documents."
        />
        <meta name="author" content="Decimal Point Analytics" />
        <link
          rel="canonical"
          href="https://www.decimalpointanalytics.com/pdfquery"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <script type="application/ld+json">
          {`
              {
                "@context": "https://schema.org/",
                "@type": "Product",
                "name": "PdfQuery",
                "image": "https://www.decimalpointanalytics.com/static/media/ecopulsebanner.10a7a396c742d59ca936.gif",
                "description": "Our AI-powered search feature empowers users to interact directly with their documents, enabling them to pose questions and receive accurate answers. Whether you're dealing with a single document or a vast library of files, our platform supports multiple uploads and offers comprehensive insights across all documents.",
                "brand": {
                  "@type": "Brand",
                  "name": "Decimal Point Analytics"
                }
              }
            `}
        </script>
      </Helmet>

      <Box id="product-page">
        {/* <Box id='ecopulse' className="mainsection" component="section">
          <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography variant="h1" className="blue">
                    EcoPulse
                  </Typography>
  
                  <Typography variant="h4" className="black">
                    Stay Ahead of the Curve with EcoPulse's  Comprehensive Macro-Economic Insights
                  </Typography>
                </Box>
  
  
                <Box className='bt_button' mt={2}>
                  <a target="_blank" rel="noopener noreferrer" href="
  https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/EcoPulse%2FLatest_Report%2FEcoPulse_Latest_Report.pdf">
                    <Button variant="contained" className='dpabtn_sm1'>
                      Download The Latest Report
                    </Button>
                  </a>
                </Box>
  
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box className="creditvdo" style={{ backgroundImage: 'url(' + indexbanner + ')', }}>
                  <IconButton className="pulse-button pulse_button_2" onClick={openModal} >
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box> */}

        <Box className="banner_bx">
          <Box
            style={{ backgroundImage: "url(" + ecopulse + ")" }}
            className="banner_img"
          >
            <Box className="img_over bl_overlay">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item lg={4} md={5} sm={7} xs={10}>
                  <Box className="al_center">
                    <Typography className="white" variant="h1">
                      PDFQuery
                    </Typography>
                    <Box mt={"10px"}>
                      <Typography className="white" variant="h4">
                        Upload Your PDFs & Get Instant Insights
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        <Box id="ecopulse" className="section2 sec_bg" component="section">
          <Container maxWidth="lg" className="bdr">
            {/* <Grid container spacing={1} alignItems="top">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className='al_center'>
                    <Typography variant='h2' className='black'>
                      What is EcoPulse?
                    </Typography>
                  </Box>
                </Grid>
              </Grid> */}

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_center synopsic_bx">
                  <Typography variant="h6" className="black jst">
                    Our AI-powered search feature empowers users to interact
                    directly with their documents, enabling them to pose
                    questions and receive accurate answers. Whether you're
                    dealing with a single document or a vast library of files,
                    our platform supports multiple uploads and offers
                    comprehensive insights across all documents.
                  </Typography>
                  <Typography variant="h6" className="black jst">
                    Using advanced tools, our system conducts conclusive
                    analyses, including sentiment analysis, providing users with
                    clear explanations for the results obtained. Furthermore,
                    we're continuously improving our capabilities, with upcoming
                    features such as document comparison, which will streamline
                    the process of tracking changes and identifying trends over
                    time.
                  </Typography>
                  <Typography variant="h6" className="black jst">
                    In addition to its analytical prowess, our AI excels in
                    maintaining context during extended conversations, ensuring
                    a seamless flow of dialogue. Users can trust that the
                    information provided by our AI is reliable and accurate,
                    free from any misleading or false content. With a commitment
                    to delivering only the truth, our platform offers a
                    trustworthy solution for document analysis and insight
                    extraction.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box id="ecopulse" className="section" component="section">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_center">
                  <Typography className="black" variant="h2">
                    {" "}
                    Pricing
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Box mt={3}>
              <Grid container spacing={2} alignItems="top">
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={ic1} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two">
                          Free
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant="h6" className="black al_center">
                          Free Forever
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={ic3} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two">
                          Pro
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant="h6" className="black al_center">
                          <strong>₹1499/month</strong>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>

        <Box id="ecopulse" className="section" component="section">
          <Container maxWidth="lg" className="bdr">
            <Box mt={3}>
              <PdfQueryTable />
            </Box>
          </Container>
        </Box>

        <Box id="eazydata" className="maintabbedsection stiky2">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={1}>
              <Grid item lg={6} md={6} sm={6} xs={7}>
                <Typography variant="h6">
                  <Link to="/pdfquery/privacy" className="black">
                    Privacy Policy
                  </Link>{" "}
                  |{"  "}
                  <Link to="/pdfquery/terms" className="black">
                    Terms and Condition
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}
