import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from '../../Component/Writemessage3'
import { Helmet } from 'react-helmet';
import eco3 from "../../asset/images/product/eco3.svg";
import eco4 from "../../asset/images/product/eco4.svg";
import coffee1 from "../../asset/images/product/coffee1.svg";
import banenr2 from "../../asset/images/animation/coffeepulsemid.svg";

import CoffeePulse from "../../asset/images/new_banner/coffee_pulse_banner.jpg"

export default function Coffeepulse() {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);
    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Coffee Pulse - Real-Time Coffee Commodity Market Updates and Analysis</h1>
                <title>Real Time Coffee Commodity Market Updates and Analysis</title>
                <meta name="description" content="Stay ahead in the coffee market with Coffee Pulse. Get real-time updates and in-depth analysis on price fluctuations. Make informed decisions today." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/coffeepulse" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                <script type="application/ld+json">
                    {`
           {
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name": "Coffee Pulse",
            "image": "https://www.decimalpointanalytics.com/static/media/coffeepulsebanner.fd27043e0e429c705f2d.gif",
            "description": "CoffeePulse is designed to deliver the most relevant and in-depth coverage on coffee commodity market; through a web-based portal. You can make informed decisions with easy to consume and analyze market updates delivered real-time at your doorstep. With more impact factor mapping at nascent stages, you stay ahead of your competition and never miss an important update across the globe, in your busy schedule. With CoffeePulse, you get the news before it actually impacts prices, rather than some post-mortem report on price fluctuations.",
            "brand": {
              "@type": "Brand",
              "name": "Decimal Point Analytics"
            }
          }           
          `}
                </script>
            </Helmet>


            {/* <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Coffee Pulse
                                </Typography>

                                <Typography variant='h4' className='black'>
                                    The real-time actionable intelligence portal on coffee
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12}>
                            <Box className='al_right'>
                                <img src={indexbanner} className='topbanner' alt='Index Banner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}

            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + CoffeePulse + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={3} md={4} sm={6} xs={8}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Coffee Pulse</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">The real-time actionable intelligence portal on coffee</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    {/* <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    What is CoffeePulse ?
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid> */}


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center synopsic_bx">
                                <Typography variant='h6' className='black jst'>
                                    CoffeePulse is designed to deliver the most relevant and in-depth coverage on coffee commodity market; through a web-based portal. You can make informed decisions with easy to consume and analyze market updates delivered real-time at your doorstep. With more impact factor mapping at nascent stages, you stay ahead of your competition and never miss an important update across the globe, in your busy schedule. With CoffeePulse, you get the news before it actually impacts prices, rather than some post-mortem report on price fluctuations.
                                </Typography>
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Writemessage3 pageVisited="Coffee Pulse" />


            <Box className='section' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={0} alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <img src={banenr2} className='topbanner2' alt='Banner2' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Box className='section sec_bg ourstrength' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography className='black' variant='h2'>
                                    Delivery Model
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className='mob_card' mt={3}>
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='str_bg mnhg1'>
                                    <Box mb={2}>
                                        <img src={eco3} className='bxicon' alt='ECO3' />
                                    </Box>
                                    <Box>
                                        <Box>
                                            <Typography className='blue' variant='h4'>Real-time ‘Sentiment’ monitoring</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography className='black jst' variant='h6'>Based on defined parameters and happenings in the market, we classify market news as positive or negative - presenting a cumulative perspective on price trends.</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>

                                <Box className='str_bg mnhg1'>
                                    <Box mb={2}>
                                        <img src={eco4} className='bxicon' alt='ECO4' />
                                    </Box>
                                    <Box>
                                        <Box>
                                            <Typography className='blue' variant='h4'>Processing news at a nascent stage’</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography className='black jst' variant='h6'>Catching the news early to help in capturing ‘trigger events’, continuously tracking developments before it actually becomes big enough to impact prices! </Typography>
                                        </Box>
                                    </Box>
                                </Box>

                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>

                                <Box className='str_bg mnhg1'>
                                    <Box mb={2}>
                                        <img src={coffee1} className='bxicon' alt='ECO5' />
                                    </Box>
                                    <Box>
                                        <Box>
                                            <Typography className='blue' variant='h4'>500+ sources, 11 countries,</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography className='black jst' variant='h6'>Coverage of local news magnifies insights into more market factors; supplementing detailed coverage across all the major coffee producing countries </Typography>
                                        </Box>
                                    </Box>
                                </Box>

                            </Grid>


                        </Grid>
                    </Box>


                </Container>
            </Box>


        </>
    );
}
